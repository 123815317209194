import React, { useMemo } from "react";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { CellDoubleClickedEvent, GridOptions, ICellRendererParams, RowClassRules } from "ag-grid-community";
import { ITableDocument } from "@dms/types";
import { GQL } from "@binale-tech/shared";
import { useIntl } from "react-intl";
import {
    AmountType,
    DragComponent,
    HeaderActions,
    HeaderAttached,
    HeaderWithTooltip,
} from "@dms/modules/DocumentTableModule/components";
import { AttachedFilter, ColorFilter } from "@dms/modules/DocumentTableModule/filters";
import { TableActionList } from "@dms/components/ActionList";

import styles from "@dms/modules/DocumentTableModule/DocumentTable.module.scss";
import { message } from "antd";

interface ICellProps extends ICellRendererParams<ITableDocument> {
    style?: React.CSSProperties;
}

export const useDocumentTableOptions = () => {
    const intl = useIntl();

    const components = useMemo(
        () => ({
            headerAttached: HeaderAttached,
            headerActions: HeaderActions,
            filterAttached: AttachedFilter,
            filterColor: ColorFilter,
            amountTypeTag: AmountType,
            headerTooltip: HeaderWithTooltip,
            actionList: TableActionList,
            dragComponent: DragComponent,
        }),
        []
    );

    const rowClassRules = useMemo<RowClassRules<ITableDocument>>(() => {
        return {
            [styles.agDraft]: params => {
                if (!params.data) {
                    return false;
                }
                return DmsUtils.getDocumentStatus(params.data) === GQL.IDocumentStatus.Draft;
            },
            [styles.agHighlightGreen]: params => {
                if (!params.data) {
                    return false;
                }

                return params.data.color === GQL.IDocumentColors.Green;
            },
            [styles.agHighlightRed]: params => {
                if (!params.data) {
                    return false;
                }

                return params.data.color === GQL.IDocumentColors.Red;
            },
            [styles.agHighlightBlue]: params => {
                if (!params.data) {
                    return false;
                }

                return params.data.color === GQL.IDocumentColors.Blue;
            },
            [styles.agHighlightPurple]: params => {
                if (!params.data) {
                    return false;
                }

                return params.data.color === GQL.IDocumentColors.Purple;
            },
            [styles.agHighlightYellow]: params => {
                if (!params.data) {
                    return false;
                }

                return params.data.color === GQL.IDocumentColors.Yellow;
            },
            // [styles.agAiResult]: params => {
            //     if (!params.data) {
            //         return false;
            //     }
            //
            //     return Boolean(params.data.aiBackgroundProcessingResult);
            // },
            [styles.agHighlightFocus]: params => {
                if (!params.data) {
                    return false;
                }

                return Boolean(params.data.isFocused);
            },
        };
    }, []);

    return useMemo(
        () => ({
            defaultColDef: {
                sortingOrder: ["asc", "desc", null],
                filter: "agTextColumnFilter",
                resizable: true,
                sortable: true,
                minWidth: 50,
                suppressHeaderFilterButton: true,
                cellClass: styles.agBinaleCell,
                cellRenderer: ({ value, style }: ICellProps) => <div style={style ?? null}>{value ?? ""}</div>,
            },
            animateRows: false,
            pagination: true,
            paginationPageSize: 50,
            paginationPageSizeSelector: [50, 100, 1000],
            headerHeight: 50,
            autoSizePadding: 20,
            rowHeight: 40,
            scrollbarWidth: 8,
            rowSelection: {
                mode: "multiRow",
                headerCheckbox: true,
                selectAll: "currentPage",
            },
            selectionColumnDef: {
                width: 50,
                suppressHeaderMenuButton: false,
                pinned: "left",
            },
            suppressAggFuncInHeader: true,
            suppressDragLeaveHidesColumns: true,
            suppressHeaderFocus: true,
            suppressScrollOnNewData: true,
            rowClassRules,
            components,
            alwaysShowHorizontalScroll: true,
            alwaysShowVerticalScroll: true,
            debounceVerticalScrollbar: true,
            suppressMultiSort: true,
            rowBuffer: 50,
            onCellDoubleClicked: async ({ value }: CellDoubleClickedEvent) => {
                await navigator.clipboard.writeText(value);
                await message.info(`${intl.formatMessage({ id: "app.notification.copiedToClipboard" })}: ${value} `, 3);
            },
        }),
        [components, intl, rowClassRules]
    ) as GridOptions<ITableDocument>;
};

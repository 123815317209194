import React, { memo, FC, useEffect, useState } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { Button, Flex } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { retrieveCurrencyRate } from "@ui-components/AmountInput/retrieveCurrencyRate";
import { TInvoiceTableItem } from "@inv/types";
import { ICurrencyCode } from "@binale-tech/shared/lib/graphql";
import { useApolloClient } from "@apollo/client";
import { decimalFormatter } from "@dms/scripts/helpers";
import { InvoicesApi } from "@inv/scripts/api";

const RateCellRenderer: FC = ({ value, data, node, setValue }: ICellRendererParams<TInvoiceTableItem>) => {
    const client = useApolloClient();
    const [rateValue, setRateValue] = useState<number>(null);
    const [isRateLoading, setIsRateLoading] = useState(false);

    useEffect(() => {
        setRateValue(value);
    }, [value]);

    if (!rateValue) {
        return null;
    }

    const documentDate = data.invoiceDate;

    const handleCurrencyClick = async () => {
        setIsRateLoading(true);
        try {
            const currencyRate = await retrieveCurrencyRate({
                client,
                documentDate,
                currencyCode: data.currency as ICurrencyCode,
            });
            await InvoicesApi.invoiceApplyCurrencyRate({ id: data.id, currencyRate });
            node.setData({ ...data, currencyRate });
            setValue(currencyRate);
        } catch (error) {
            console.error(error);
        } finally {
            setIsRateLoading(false);
        }
    };

    if (data.isChildRow) {
        return null;
    }

    return (
        <Flex justify="space-between" align="center">
            <Button
                tabIndex={-1}
                size="small"
                type="text"
                icon={<DownloadOutlined />}
                style={{ borderRadius: "50%" }}
                onClick={handleCurrencyClick}
                disabled={!documentDate}
                loading={isRateLoading}
            />
            {decimalFormatter(rateValue, 4)}
        </Flex>
    );
};

export default memo(RateCellRenderer);

import { useIntl } from "react-intl";
import React, { useMemo } from "react";
import { InvoiceFormInputTranslate } from "@inv/types";

const taxOptions = [
    { label: "19 %", value: 19 },
    { label: "7 %", value: 7 },
    { label: "0 %", value: 0 },
];

export const useLineItemOptions = () => {
    const intl = useIntl();
    const unitOptions = useMemo(
        () => [
            {
                label: <>&nbsp;</>,
                value: "EMPTY",
            },
            {
                label: intl.formatMessage({ id: InvoiceFormInputTranslate.PIECE }),
                value: "PIECE",
            },
            {
                label: intl.formatMessage({ id: InvoiceFormInputTranslate.HOUR }),
                value: "HOUR",
            },
            {
                label: intl?.formatMessage({ id: "app.fields.date.month" }),
                value: "MONTH",
            },
        ],
        [intl]
    );
    return useMemo(() => ({ unitOptions, taxOptions }), [unitOptions]);
};

import React, { FC, useContext, useEffect, useState } from "react";
import { DocumentPdfViewer } from "@app/components/shared/PdfViewer/DocumentPdfViewer";
import { useGenerateInvoicePdf } from "@inv/hooks/useGenerateInvoicePdf";
import { useApolloClient } from "@apollo/client";
import { CompanyContext } from "../../../scripts/context/CompanyContext";
import { useInvoiceGoBL } from "@inv/hooks/useInvoiceGoBL";
import { Button, Flex, Space, Spin, Tooltip } from "antd";
import { ExportOutlined, FileExcelOutlined, FilePdfOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { saveAs } from "file-saver";
import { createXRechnungPdfData } from "@inv/scripts/utils/createXRechnungPdfData";
import { InvoicesDataContext } from "@inv/context/InvoicesDataProvider";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { DocumentsApi } from "@dms/scripts/DocumentsApi";
import { DmsType } from "@dms/types";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";

interface IProps {
    invoiceId: string;
}

export const InvoiceViewer: FC<IProps> = ({ invoiceId }) => {
    const client = useApolloClient();
    const [isExportingDms, setIsExportingDms] = useState(false);
    const mutator = useGqlMutator();
    const { companyGQL } = useContext(CompanyContext);
    const { invoicesMap } = useContext(InvoicesDataContext);
    const { documentsKV } = useContext(DmsDataContext);
    const invoice = invoicesMap.get(invoiceId);
    const [documentData, setDocumentData] = useState<Awaited<ReturnType<typeof createXRechnungPdfData>>>(null);
    const hashExists = documentData && documentData.hash in documentsKV;
    const createInvoicePdf = useGenerateInvoicePdf(invoiceId);
    const data = useInvoiceGoBL(invoiceId);

    const createPdf = async () => {
        console.log("createPdf", JSON.stringify(data));
        if (documentData) {
            console.log("documentData", documentData);
            return;
        }
        const pdfBuffer = await createInvoicePdf();
        const docData = await createXRechnungPdfData(client, { companyId: companyGQL.id, invoiceId }, pdfBuffer, data);
        setDocumentData(docData);
    };

    useEffect(() => {
        if (!data) {
            return;
        }
        createPdf();
    }, [data, invoiceId]);

    const handleDmsExport = () => {
        const { file, hash } = documentData;
        setIsExportingDms(true);
        DocumentsApi.createDocument({
            fileUploadData: {
                fileData: { file, isUploaded: false, hash },
                companyData: companyGQL,
                documentType: { type: DmsType.new_documents },
            },
            mutator,
            setFileStatus: () => {},
        }).finally(() => setIsExportingDms(false));
    };

    return (
        <Flex justify={"center"} style={{ height: "100%", width: "100%" }} vertical>
            {documentData ? (
                <>
                    <Flex style={{ marginBottom: 5 }} justify={"space-between"}>
                        <Space>
                            <Button onClick={() => saveAs(documentData.file)} icon={<FilePdfOutlined />}>
                                <FormattedMessage id={"app.button.download"} />
                                PDF
                            </Button>
                            <Tooltip title={"PDF mit E-Rechnung (ZUGFeRD 2.2 / Factur-X)"}>
                                <Button icon={<InfoCircleOutlined />} type={"text"} shape={"circle"} />
                            </Tooltip>
                        </Space>

                        <Button
                            loading={isExportingDms}
                            icon={<ExportOutlined />}
                            type="primary"
                            disabled={!invoice.isConfirmed || hashExists}
                            onClick={handleDmsExport}
                        >
                            <FormattedMessage id="app.button.send_to_dms" />
                        </Button>

                        <Button
                            onClick={() => saveAs(documentData.xmlBlob, documentData.filename.replace(".pdf", ".xml"))}
                            icon={<FileExcelOutlined />}
                        >
                            <FormattedMessage id={"app.button.download"} />
                            ZUGFeRD XML
                        </Button>
                    </Flex>
                    <DocumentPdfViewer
                        style={{ height: "100%", width: "100%" }}
                        url={documentData.url}
                        type={documentData.type}
                        filename={documentData.filename}
                    />
                </>
            ) : (
                <Spin />
            )}
        </Flex>
    );
};

import React, { useEffect, useRef } from "react";
import cn from "classnames";

import "./ContextMenu.css";

export interface ContextMenuProps {
    children?: React.ReactElement<ContextMenuItemProps>[];
    acquireHeight: (h: number) => void;
}

export const ContextMenu: React.FC<ContextMenuProps> = React.memo(function ContextMenu({ acquireHeight, children }) {
    const menuRef = useRef<HTMLUListElement>(null);
    useEffect(() => {
        acquireHeight(menuRef.current.clientHeight);
    }, [acquireHeight]);
    return (
        <ul className="ContextMenu" ref={menuRef}>
            {React.Children.map(children, (child, index) => (
                <li key={"ContextMenuChild-" + index}>{child}</li>
            ))}
        </ul>
    );
});

export interface ContextMenuItemProps extends ReactTesting {
    className?: string;
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
    enabled?: boolean;
    glyphicon?: React.ReactNode;
    children?: React.ReactNode;
}

export const ContextMenuItem = (props: ContextMenuItemProps) => {
    const isEnabled = props.enabled == null || props.enabled;
    return (
        <div
            className={cn("ContextMenuItem", props.className, {
                disabled: !isEnabled,
            })}
            onClick={isEnabled ? props.onClick : null}
            data-testid={props["data-testid"]}
        >
            <div className="glyphicon">{props.glyphicon || null}</div>
            {props.children}
        </div>
    );
};

export const ContextMenuSeparator = () => (
    <div className="ContextMenuSeparator">
        <hr />
    </div>
);

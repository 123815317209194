import React, { FC } from "react";
import { AutoComplete, Col, Form, Input, Row } from "antd";
import { InvoiceFormBlocksTranslate, InvoiceFormInputTranslate, InvoiceInputs } from "@inv/types";
import { InvoiceFormItemWrapper } from "@inv/components/InvoiceForm/components/InvoiceFormItemWrapper";
import { FormattedMessage, useIntl } from "react-intl";
import { useInvoicePaymentTemplates } from "@inv/hooks/useInvoicePaymentTemplates";

export const FooterBlock: FC = () => {
    const intl = useIntl();
    const { templatesList } = useInvoicePaymentTemplates();

    return (
        <InvoiceFormItemWrapper title={intl.formatMessage({ id: InvoiceFormBlocksTranslate.FOOTER_SECTION })}>
            <Row>
                <Col span={24}>
                    <Form.Item
                        name={InvoiceInputs.PAYMENT_TERM}
                        label={<FormattedMessage id={InvoiceFormInputTranslate.PAYMENT_TERM} />}
                    >
                        <AutoComplete options={templatesList.map(({ terms }) => ({ value: terms }))} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={InvoiceInputs.DESCRIPTION}
                        label={<FormattedMessage id={InvoiceFormInputTranslate.DESCRIPTION} />}
                    >
                        <Input.TextArea />
                    </Form.Item>
                </Col>
            </Row>
        </InvoiceFormItemWrapper>
    );
};

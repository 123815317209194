import React, { ChangeEvent, useState } from "react";
import { Input } from "antd";
import { CustomCellEditorProps } from "ag-grid-react";
import { InvoicesListColumn, NavigationKeys } from "@inv/types";

import styles from "./editors.module.scss";
import { InvoicesApi } from "@inv/scripts/api";

const RateCellEditor: React.FC<CustomCellEditorProps> = ({ value, node, onValueChange, stopEditing }) => {
    const [inputValue, setInputValue] = useState<string>(value);

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => setInputValue(target.value);
    const handleFocus = ({ target }: React.FocusEvent<HTMLInputElement>) => target.select();

    const handleConfirm = async (newValue: string) => {
        const newRateValue = +parseFloat(newValue.replace(",", ".")).toFixed(4);

        if (isNaN(newRateValue) || newRateValue < 0) {
            return stopEditing();
        }
        await InvoicesApi.invoiceApplyCurrencyRate({ id: node.data.id, currencyRate: newRateValue });
        node.setDataValue(InvoicesListColumn.RATE, newRateValue);
        onValueChange(newRateValue);
        stopEditing();
    };

    return (
        <Input
            value={inputValue}
            onChange={handleChange}
            className={styles.agEditor}
            variant="borderless"
            onKeyDown={e => {
                if (e.key === NavigationKeys.ENTER) {
                    handleConfirm(inputValue);
                }
            }}
            onFocus={handleFocus}
            onBlur={() => handleConfirm(inputValue)}
            autoFocus
        />
    );
};

export default React.memo(RateCellEditor);

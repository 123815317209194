// in a case of strong desire to remove react-datepicker read the task: https://binale.atlassian.net/browse/FIBU-398
import "react-datepicker/dist/react-datepicker.css";
// order is important here
import "./DatePickerBlock.css";

import React, { ComponentProps, useEffect, useImperativeHandle, useState } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import cx from "classnames";
import dayjs from "dayjs";
import { CalendarOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { Input, Popover, Typography } from "antd";

import { BrowserDetection } from "scripts/infrastructure/helpers/browser";
import { FieldLabel } from "@ui-components/FieldLabel";
import { Periods } from "@binale-tech/shared";

import { de, enGB, ru } from "date-fns/locale";
// eslint-disable-next-line import/no-duplicates
import { endOfMonth, format, parse } from "date-fns";

registerLocale("en", enGB);
registerLocale("de", de);
registerLocale("ru", ru);

export interface Props {
    value: Date;
    yearBound: number;
    periodBound?: number;
    disabled?: boolean;
    onChange?: (date: Date) => void;
    hint?: React.ReactNode;
}

const isDate = (v: Date) => v instanceof Date && !isNaN(v.getTime());

const FormatDay = "dd";
const FormatDayMonth = "dd.MM";

export const DatePickerBlock = React.forwardRef<ReactDatePicker, Props>(function DatePickerBlock(props, ref) {
    const hasPeriodBound = Number.isFinite(props.periodBound);
    const dateFormat = hasPeriodBound ? FormatDay : FormatDayMonth;

    const [showPopover, setShowPopover] = useState(false);
    const [innerValue, setInnerValue] = useState(isDate(props.value) ? format(props.value, dateFormat) : "");

    const pickerRef = React.useRef<ReactDatePicker>(null);
    useImperativeHandle(ref, () => pickerRef?.current, [pickerRef]);

    const max = React.useMemo(() => {
        const month = hasPeriodBound ? Periods.getMonthAndDay(props.periodBound).month : 11;
        return endOfMonth(new Date(props.yearBound, month));
    }, [hasPeriodBound, props.periodBound, props.yearBound]);

    const min = React.useMemo(() => {
        const month = hasPeriodBound ? Periods.getMonthAndDay(props.periodBound).month : 0;
        return new Date(props.yearBound, month, 1);
    }, [hasPeriodBound, props.periodBound, props.yearBound]);

    const onChangeRaw: ComponentProps<typeof ReactDatePicker>["onChangeRaw"] = e => {
        const value = (e?.target as HTMLInputElement)?.value ?? "";
        if (!/^[0-9.]*$/.test(value) || value.length > dateFormat.length) {
            e.preventDefault();
            return;
        }
        setInnerValue(value);
    };

    const handleChange = (date: Date) => {
        if (isDate(date) && !dayjs(date).isSame(props.value, "day")) {
            props.onChange(date);
        }
    };

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const parsed = parse(e.target.value, dateFormat, props.value);
        if (isDate(parsed)) {
            handleChange(parsed);
        } else {
            setShowPopover(true);
            setTimeout(() => pickerRef.current?.setFocus(), 100);
        }
    };

    const onCalendarClick = () => {
        pickerRef.current.setOpen(true);
    };

    const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        const input = e.target;
        e.stopPropagation();
        setTimeout(() => {
            input.selectionStart = 0;
            input.selectionEnd = 2;
        }, 100);
    };

    useEffect(() => {
        if (showPopover) {
            setTimeout(() => {
                setShowPopover(false);
            }, 5000);
        }
    }, [showPopover]);

    useEffect(() => {
        setInnerValue(format(props.value, dateFormat));
        setShowPopover(false);
    }, [props.value, dateFormat]);

    const innerDate = parse(innerValue, dateFormat, props.value);

    // that hack needs to focus next form element instead of buttons in calendar
    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement;
        if (target.tagName.toUpperCase() === "INPUT" && e.key === "Enter") {
            e.preventDefault();
        }
    };

    return (
        <div onKeyDown={handleKeyDown}>
            <FieldLabel
                label={<FormattedMessage id="app.fields.date" />}
                labelAddon={<Typography.Text type="secondary">{props.hint}</Typography.Text>}
                style={{ minWidth: 90, width: 90 }}
                className={cx("DatumPickerBlock", {
                    "DatumPickerBlock--safari": BrowserDetection.isSafari,
                })}
            >
                <Popover
                    open={showPopover}
                    placement="topLeft"
                    onOpenChange={() => setShowPopover(false)}
                    title={<FormattedMessage id="app.components.date.range_error" />}
                    content={
                        <strong>
                            {format(min, "dd.MM.yyyy")}
                            {" - "}
                            {format(max, "dd.MM.yyyy")}
                        </strong>
                    }
                >
                    <ReactDatePicker
                        ref={pickerRef}
                        locale={dayjs.locale()}
                        calendarStartDay={1}
                        onFocus={handleOnFocus}
                        onBlur={onBlur}
                        preventOpenOnFocus
                        disabledKeyboardNavigation
                        fixedHeight
                        disabled={
                            props.disabled ||
                            props.periodBound === Periods.Period.FirstDayOfYear ||
                            props.periodBound === Periods.Period.LastDayOfYear
                        }
                        dateFormat={dateFormat}
                        onChange={() => {}}
                        onChangeRaw={onChangeRaw}
                        selected={isDate(innerDate) ? innerDate : null}
                        onSelect={date => {
                            if (!dayjs(date).isSame(props.value, "day")) {
                                handleChange(date);
                                setTimeout(() => pickerRef.current?.setFocus(), 100);
                            }
                        }}
                        minDate={min}
                        maxDate={max}
                        value={innerValue}
                        popperPlacement="top-start"
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                // it needs somewhere inside datePicker itself to close calendar correctly.
                                Object.assign(e, { key: "Tab", keyCode: 9 });
                                // pickerRef.current.setOpen(false, true);
                                if (pickerRef.current.isCalendarOpen()) {
                                    setTimeout(() => pickerRef.current?.setFocus(), 0);
                                }
                            }
                            if (e.key === "Tab" && pickerRef.current.isCalendarOpen()) {
                                pickerRef.current.setOpen(false, true);
                            }
                        }}
                        customInput={
                            <Input
                                suffix={
                                    <>
                                        {
                                            <span
                                                className={cx("DatumPickerBlock__suffixMonth")}
                                                data-testid="date-picker-suffix-month"
                                            >
                                                {hasPeriodBound ? format(props.value, ".MM.") : "."}
                                            </span>
                                        }

                                        <CalendarOutlined onClick={onCalendarClick} />
                                    </>
                                }
                            />
                        }
                    />
                </Popover>
            </FieldLabel>
        </div>
    );
});

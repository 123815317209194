import { findNode, getChild, htmlDecode } from "./CIITools";
import { AbstractDecoder } from "./AbstractDecoder";

export class DecoderModern extends AbstractDecoder {
    getVersion() {
        const context = getChild(this.CII, "rsm:ExchangedDocumentContext");
        const parameter = getChild(context, "ram:GuidelineSpecifiedDocumentContextParameter");
        const version = findNode(parameter, "ram:ID")?.content;
        const getVersion = () => {
            if (!version) {
                return "unknown";
            }
            if (version === "urn:factur-x.eu:1p0:minimum") {
                return "ZUGFeRD 2.3 Minimum";
            }
            if (version === "urn:factur-x.eu:1p0:basicwl") {
                return "ZUGFeRD 2.3 Basic WL";
            }
            if (version === "urn:cen.eu:en16931:2017") {
                return "ZUGFeRD 2.3 Comfort (EN 16931)";
            }
            if (version === "urn:cen.eu:en16931:2017#conformant#urn:factur-x.eu:1p0:extended") {
                return "ZUGFeRD 2.3 Extended (EN 16931)";
            }
            if (version === "urn:cen.eu:en16931:2017#compliant#urn:xeinkauf.de:kosit:xrechnung_3.0") {
                return "XRechnung 3.0 / ZUGFeRD 2.3 Extended (EN 16931)";
            }
            return `Unknown version: ${version}`;
        };

        return { version: getVersion(), isDeprecated: false };
    }
    protected getHeader(): Record<string, any>[] {
        return getChild(this.CII, "rsm:ExchangedDocument");
    }
    protected getTransaction(): Record<string, any>[] {
        return getChild(this.CII, "rsm:SupplyChainTradeTransaction");
    }

    protected getTransactionTradeAgreement(): Record<string, any>[] {
        return getChild(this.getTransaction(), "ram:ApplicableHeaderTradeAgreement");
    }

    protected getTransactionTradeSettlement(): Record<string, any>[] {
        return getChild(this.getTransaction(), "ram:ApplicableHeaderTradeSettlement");
    }

    protected getTransactionTradeDelivery(): Record<string, any>[] {
        return getChild(this.getTransaction(), "ram:ApplicableHeaderTradeDelivery");
    }

    protected tagTaxApplicablePercent(nodes: Record<string, any>[]): number {
        return Number(findNode(nodes, "ram:RateApplicablePercent")?.content ?? "0");
    }

    protected getLineItem(v: Record<string, any>[]): {
        name: string;
        description: string;
        netto: number;
        tax: number;
    } {
        const settlement = getChild(v, "ram:SpecifiedLineTradeSettlement");

        const tradeTax = getChild(settlement, "ram:ApplicableTradeTax");
        const tax = Number(findNode(tradeTax, "ram:RateApplicablePercent")?.content ?? "0"); // BT-152

        const summation = getChild(settlement, "ram:SpecifiedTradeSettlementLineMonetarySummation");
        const netto = Number(findNode(summation, "ram:LineTotalAmount")?.content ?? "0") * 100; // BT-131

        const product = getChild(v, "ram:SpecifiedTradeProduct");
        const name = htmlDecode(findNode(product, "ram:Name")?.content); // BT-153
        const description = htmlDecode(findNode(product, "ram:Description")?.content); // BT-154
        return {
            name,
            description,
            netto,
            tax,
        };
    }
}

import React, { useContext, useEffect, useState } from "react";
import { Space, Spin } from "antd";
import { DATEV_AUTH_LS_KEY, DatevAuthLSData } from "../../../scripts/infrastructure/oauth/oauth";
import { gql, useApolloClient } from "@apollo/client";
import { GQL } from "@binale-tech/shared";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../scripts/routing/routeConstants";
import { DatevControlContext } from "../../../scripts/context/DatevContext";

const datevTokenGrant = gql`
    mutation datevTokenGrant($input: DatevTokenGrantInput!) {
        datevTokenGrant(input: $input) {
            id
            issuedAt
            accessTokenExpiresIn
            refreshTokenExpiresIn
        }
    }
`;
export const OAuthCallback: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState<string>("initialising");
    const [error, setError] = useState<string>();
    const { triggerRefetch } = useContext(DatevControlContext);
    const client = useApolloClient();
    const navigate = useNavigate();

    useEffect(() => {
        const doWork = async () => {
            try {
                const lsData = localStorage.getItem(DATEV_AUTH_LS_KEY);
                if (!lsData) {
                    throw new Error("OAuth initialisation data has gone, please start auth from the beginning");
                }
                const datevOAuth: DatevAuthLSData = JSON.parse(lsData);
                console.log(datevOAuth);
                const search = new URLSearchParams(window.location.search);
                console.log(search);
                if (search.get("state") !== datevOAuth.state) {
                    throw new Error("state mismatch");
                }
                if (!search.get("code")) {
                    throw new Error("code is missing");
                }
                const code = search.get("code");
                if (!search.get("id_token")) {
                    throw new Error("id token is missing");
                }
                const token = search.get("id_token");
                const jwt = JSON.parse(atob(token.split(".")[1]));
                if (jwt.nonce !== datevOAuth.nonce) {
                    throw new Error("nonce mismatch");
                }

                setProgress("fetching token");
                const input: GQL.IDatevTokenGrantInput = {
                    code,
                    redirectUri: window.location.href.split("?")[0],
                    companyId: datevOAuth.companyId,
                    currentUrl: window.location.href,
                };
                const mutation = client.mutate<
                    Pick<GQL.IMutation, "datevTokenGrant">,
                    GQL.IMutationDatevTokenGrantArgs
                >({
                    mutation: datevTokenGrant,
                    variables: {
                        input,
                    },
                });

                const res = await mutation;
                console.log("datev res", res);
                setProgress("Authenticated, redirecting...");
                await new Promise(resolve => setTimeout(resolve, 50));
                if (datevOAuth.companyId) {
                    navigate(AppRoutes.manageCompanies + "?datev=1");
                } else {
                    await triggerRefetch();
                    navigate(AppRoutes.profile + "?tab=datev");
                }
            } catch (e) {
                setError(e.message);
                setProgress("");
            } finally {
                setLoading(false);
                localStorage.removeItem(DATEV_AUTH_LS_KEY);
            }
        };
        doWork();
    }, [client, navigate, triggerRefetch]);

    return (
        <div style={{ margin: "12px" }} data-testid="popup-loading">
            <Space>
                <Spin spinning={loading} />
                <div>
                    {progress && <div>{progress}</div>}
                    {error && <div>Error: {error}</div>}
                </div>
            </Space>
        </div>
    );
};

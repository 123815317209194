import React, { FC, useContext } from "react";
import { PageHeader } from "@inv/components/PageHeader";
import { FormattedMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { AppRoutes } from "../../scripts/routing/routeConstants";
import { Button, Flex } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { InvoicesDataContext } from "@inv/context/InvoicesDataProvider";
import { InvoiceForm } from "@inv/components/InvoiceForm";

const Module: FC = () => {
    const { id } = useParams();
    const { invoicesMap } = useContext(InvoicesDataContext);

    const initialValue = invoicesMap.get(id);

    if (!initialValue) {
        return null;
    }

    return <InvoiceForm initialValues={initialValue} />;
};

export const EditInvoicePage: FC = () => {
    return (
        <Flex
            vertical
            style={{
                margin: "0 auto",
                maxWidth: 1366,
                height: "100%",
            }}
        >
            <PageHeader
                title={<FormattedMessage id={"app.invoice.createInvoice"} />}
                button={
                    <Link to={AppRoutes.invoicing}>
                        <Button type={"text"} icon={<ArrowLeftOutlined />}>
                            <FormattedMessage id={"app.invoice.invoicesList"} />
                        </Button>
                    </Link>
                }
            />
            <Module />
        </Flex>
    );
};

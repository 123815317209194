import React from "react";
import { ICellRendererParams, IHeaderParams } from "ag-grid-community";
import { FormattedMessage } from "react-intl";
import { Button, Flex, Popconfirm } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { GQL } from "@binale-tech/shared";

export type CellActionsProps = { onEdit: () => void; onDelete: () => void; disabled: boolean };

export const HeaderActions: React.FC<IHeaderParams<GQL.IInvoiceNumberFormat>> = () => {
    return (
        <div>
            <FormattedMessage id={"app.fields.actions"} />
        </div>
    );
};

export const CellActions: React.FC<ICellRendererParams<GQL.IInvoiceNumberFormat> & CellActionsProps> = ({
    onEdit,
    onDelete,
    disabled,
}) => {
    return (
        <Flex gap={5} align={"center"} style={{ height: "100%" }}>
            <Button icon={<EditOutlined />} shape={"circle"} onClick={onEdit} />
            <Popconfirm
                disabled={disabled}
                onConfirm={onDelete}
                title={<FormattedMessage id="app.confirmation.header" />}
                okText={<FormattedMessage id="app.button.confirm" />}
                cancelText={<FormattedMessage id="app.button.cancel" />}
            >
                <Button icon={<DeleteOutlined />} shape={"circle"} disabled={disabled} />
            </Popconfirm>
        </Flex>
    );
};

import React, { FC, memo, useCallback, useContext, useMemo, useState } from "react";
import { GenericRecord } from "../../../../scripts/models";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";
import { FormExternalRef } from "@app/components/recordform/types/ref";
import { PageFullScreen } from "@app/components/shared/appearance/page";
import { GlobalYearSelect } from "@app/components/shared/Toolbar/YearSelect";
import { GenericRecordForm } from "@app/components/recordform/GenericRecordForm";
import { TemplatesTable } from "./TemplatesTable";
import { FlexFillBlock } from "@app/components/shared/appearance/page/Scaffold";
import { Button, Space } from "antd";
import { UserContext } from "../../../../scripts/context/UserProvider";
import { CompanyContext, YearPeriodContext } from "../../../../scripts/context/CompanyContext";
import { useGqlMutator } from "../../../../scripts/graphql/useGqlMutator";
import { GQL } from "@binale-tech/shared";
import { gql } from "@apollo/client";

const companyCopyAccountingYearTemplates = gql`
    mutation companyCopyAccountingYearTemplates($input: CompanyCreateAccountingYearInput!) {
        companyCopyAccountingYearTemplates(input: $input) {
            id
        }
    }
`;

export const TemplatesViewContent: FC = memo(function TemplatesViewContent() {
    const { isAdmin } = useContext(UserContext);
    const { moduleActions, moduleTemplates } = useContext(TableViewContext);
    const { year } = useContext(YearPeriodContext);
    const { companyGQL, yearConfig } = useContext(CompanyContext);
    const inlineFormRef = React.useRef<FormExternalRef>(null);
    const [activeRecord, setActiveRecord] = useState<GenericRecord>();
    const mutator = useGqlMutator();

    const onClear = useCallback(() => setActiveRecord(null), []);
    const onSave = useCallback(
        (r: GenericRecord) => {
            moduleActions.saveTemplate(r);
            onClear();
        },
        [moduleActions, onClear]
    );
    const showButton = isAdmin && !moduleTemplates.length && companyGQL.accountingYears.includes(year - 1);

    const actions = useMemo(
        () => ({
            onDelete: (r: GenericRecord) => {
                moduleActions.deleteTemplates([r.key]);
                onClear();
            },
            onEdit: (r: GenericRecord) => setActiveRecord(r),
        }),
        [moduleActions, onClear]
    );
    const onCopyTemplates = () => {
        mutator.mutate<"companyCopyAccountingYearTemplates", GQL.ICompanyCreateAccountingYearInput>({
            throwOnError: true,
            mutation: companyCopyAccountingYearTemplates,
            input: {
                companyId: companyGQL.id,
                fiscalYearStart: "",
                kontoExt: yearConfig.kontoExt,
                skr: yearConfig.skr,
                taxation: yearConfig.taxation as any,
                year: yearConfig.year,
            },
        });
    };

    return (
        <PageFullScreen>
            <div className="GenericToolbar">
                <Space>
                    <GlobalYearSelect />
                    {showButton && <Button onClick={onCopyTemplates}>Copy templates from the previous year</Button>}
                </Space>
            </div>
            <FlexFillBlock>
                <TemplatesTable templates={moduleTemplates} actions={actions} />
            </FlexFillBlock>
            <GenericRecordForm record={activeRecord} ref={inlineFormRef} onSave={onSave} onClear={onClear} isTemplate />
        </PageFullScreen>
    );
});

import { gql, useApolloClient } from "@apollo/client";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GQL } from "@binale-tech/shared";
import { CompanyContext } from "../../scripts/context/CompanyContext";

export const invoicePaymentTemplates = gql`
    query invoicePaymentTemplates($companyId: ID!) {
        invoicePaymentTemplates(companyId: $companyId) {
            id
            companyId

            terms

            createdAt
            updatedAt
            createdBy {
                uid
            }
        }
    }
`;

type FormatApi = {
    templatesList: GQL.IInvoicePaymentTemplate[];
    refetch: () => void;
};
export const useInvoicePaymentTemplates = (): FormatApi => {
    const client = useApolloClient();
    const { companyGQL } = useContext(CompanyContext);
    const [data, setData] = useState<GQL.IInvoicePaymentTemplate[]>([]);
    const refetch = useCallback(() => {
        if (!companyGQL?.id) {
            return;
        }
        client
            .query<Pick<GQL.IQuery, "invoicePaymentTemplates">>({
                fetchPolicy: "network-only",
                query: invoicePaymentTemplates,
                variables: {
                    companyId: companyGQL?.id,
                },
            })
            .then(res => {
                setData(res.data.invoicePaymentTemplates);
            });
    }, [client, companyGQL?.id]);
    useEffect(() => {
        refetch();
    }, [refetch]);
    return useMemo(() => ({ templatesList: data, refetch }), [data, refetch]);
};

import React, { FC, useCallback, useState } from "react";
import { Button, Flex, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import InvoicePaymentTemplateForm from "./InvoicePaymentTemplateForm";

export const InvoicePaymentTemplateListHeader: FC<{
    refetch: () => void;
}> = ({ refetch }) => {
    const [isOpen, setIsOpen] = useState(false);
    const onClose = useCallback(() => {
        setIsOpen(false);
        setTimeout(() => refetch(), 500);
    }, [refetch]);

    return (
        <Flex align="center" justify="space-between" style={{ width: "100%", marginBottom: "10px" }}>
            <Typography.Title level={3}>Formats</Typography.Title>
            <Flex align="center" justify="flex-end" gap={10}>
                <Button type={"primary"} icon={<PlusOutlined />} onClick={() => setIsOpen(true)}>
                    <FormattedMessage id={"app.invoice.createInvoicePaymentTemplate"} />
                </Button>
            </Flex>
            <InvoicePaymentTemplateForm open={isOpen} onClose={onClose} />
        </Flex>
    );
};

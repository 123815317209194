import React, { useEffect, useRef, useState } from "react";
import { Bu } from "@binale-tech/shared";
import { Button, Divider, Modal, Radio, Space } from "antd";
import { FormattedMessage } from "react-intl";

import { focusFirst } from "scripts/infrastructure/helpers/focus";
import "./style.css";

interface Ust13bModalProps {
    onChange13b?: (value: Bu.USt13bOption) => void;
    value13b?: Bu.USt13bOption;
    onCancel?: () => void;
    bu: Bu.Bu;
}

export const Ust13bModal: React.FC<Ust13bModalProps> = ({ onChange13b, value13b, onCancel, bu }) => {
    const [innerValue13b, setInnerValue13b] = useState(value13b);
    const okBtnRef = useRef<HTMLButtonElement>(null);
    const radioGroupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        focusFirst(radioGroupRef.current);
    }, [radioGroupRef]);

    return (
        <Modal
            open
            width={1200}
            destroyOnClose
            className="UST13bFormModal"
            data-testid="ust13b-modal"
            title={Bu.getUst13bData(bu).hint}
            onCancel={onCancel}
            footer={null}
        >
            <Radio.Group onChange={e => setInnerValue13b(e.target.value)} value={innerValue13b} ref={radioGroupRef}>
                <Space
                    direction="vertical"
                    onKeyUp={e => {
                        if (e.key === "Enter") {
                            okBtnRef?.current?.focus();
                        }
                    }}
                >
                    {(Bu.getUst13bData(bu).options ?? []).map(option => (
                        <Radio
                            value={option.value}
                            key={option.value}
                            className="UST13bFormModal__radioLabel"
                            tabIndex={-1}
                        >
                            <Space>
                                <div className={"UST13bFormModal__radioNumericValue"}>({option.value})</div>
                                <div>{option.title}</div>
                            </Space>
                        </Radio>
                    ))}
                </Space>
            </Radio.Group>
            <Divider />
            <Space style={{ display: "flex", justifyContent: "end", width: "100%" }}>
                <Button
                    type="primary"
                    disabled={!innerValue13b}
                    ref={okBtnRef}
                    onClick={() => onChange13b(innerValue13b)}
                    onKeyUp={e => {
                        if (e.key === "Enter") {
                            onChange13b(innerValue13b);
                        }
                    }}
                >
                    <FormattedMessage id="app.button.save" />
                </Button>

                <Button onClick={onCancel}>
                    <FormattedMessage id="app.button.cancel" />
                </Button>
            </Space>
        </Modal>
    );
};

import { Container } from "@app/components/shared/appearance/page";
import { AgGridTable } from "@app/components/shared/AgGridTable";
import React, { FC, useContext, useMemo } from "react";
import { GenericRecord } from "../../../../scripts/models";
import { ColDef, ColGroupDef, GridOptions, ICellRendererParams, IHeaderParams, RowClassRules } from "ag-grid-community";
import { GenericRecordColumns } from "@app/columns/ColumnConfig";
import { GenericRecordProperties, TableProperties } from "../../../../scripts/core/Product";
import { ExportPreprocessor } from "../../../../scripts/exporters/ExportPreprocessor";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";
import { CompanyContext } from "../../../../scripts/context/CompanyContext";
import { FormattedMessage } from "react-intl";
import { Button, Flex, Popconfirm } from "antd";
import { CaretDownOutlined, CaretRightOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { baseOptions } from "@app/components/shared/AgGridTable/options/baseOptions";
import TableUtils from "../../../../scripts/core/TableUtils";
import styles from "./TemplatesTable.module.scss";

type CellActionsProps = { onEdit: () => void; onDelete: () => void };

type Props = {
    templates: GenericRecord[];
    actions?: {
        onEdit: (v: GenericRecord) => void;
        onDelete: (v: GenericRecord) => void;
    };
    onSelect?: (v: GenericRecord) => void;
};

type GenericRecordExpandable = GenericRecord & { isChildRow?: boolean; isExpanded?: boolean };
const HeaderActions: React.FC<IHeaderParams<GenericRecordExpandable>> = () => {
    return (
        <div>
            <FormattedMessage id={"app.fields.actions"} />
        </div>
    );
};

const CellActions: React.FC<ICellRendererParams<GenericRecordExpandable> & CellActionsProps> = ({
    data,
    onEdit,
    onDelete,
}) => {
    if (data.isChildRow) {
        return null;
    }
    return (
        <Flex gap={5} align={"center"} style={{ marginTop: 5 }}>
            <Button icon={<EditOutlined />} type={"text"} shape={"circle"} onClick={onEdit} />
            <Popconfirm
                onConfirm={onDelete}
                title={<FormattedMessage id="app.confirmation.header" />}
                okText={<FormattedMessage id="app.button.confirm" />}
                cancelText={<FormattedMessage id="app.button.cancel" />}
            >
                <Button icon={<DeleteOutlined />} type={"text"} shape={"circle"} />
            </Popconfirm>
        </Flex>
    );
};

export const TemplatesTable: FC<Props> = ({ templates, actions, onSelect }) => {
    const { yearConfig } = useContext(CompanyContext);
    const { product } = useContext(TableViewContext);
    // const [openedKeys, setOpenedKeys] = useState(new Set());
    const gridOptions = useMemo(
        () => ({
            ...baseOptions,
            defaultColDef: {
                ...baseOptions.defaultColDef,
                filter: "agTextColumnFilter",
                suppressHeaderFilterButton: true,
                cellClass: styles.binaleCell,
                cellRenderer: ({ value }: ICellRendererParams<GenericRecord>) => {
                    return <div>{value ?? <>&nbsp;</>}</div>;
                },
                autoHeight: true,
            },
            rowSelection: onSelect ? { mode: "singleRow" } : undefined,
            onSelectionChanged: event => {
                const [record] = event.api.getSelectedRows();
                if (onSelect) {
                    onSelect(record);
                }
            },
        }),
        [onSelect]
    ) as GridOptions<GenericRecord>;

    const columns: (ColDef<GenericRecordExpandable> | ColGroupDef<GenericRecordExpandable>)[] = useMemo(() => {
        const defs: (ColDef<GenericRecordExpandable> | ColGroupDef<GenericRecordExpandable>)[] = [
            {
                field: "key",
                headerName: "",
                width: 50,
                filter: false,
                sortable: false,
                resizable: false,
                suppressMovable: true,
                suppressNavigable: true,
                cellRenderer: ({ api, data, node }: ICellRendererParams<GenericRecordExpandable>) => {
                    if (node.data.items?.length <= 1) {
                        return null;
                    }
                    const toggleLineItems = () => {
                        const children = TableUtils.getChildren(data);
                        if (!data.isChildRow) {
                            const newRowData = children.map((item, idx) => {
                                const enrichedRecord = item.clone();
                                Object.assign(enrichedRecord, {
                                    isChildRow: true,
                                    key: `${data.key}-${idx + 1}`,
                                });
                                return enrichedRecord satisfies GenericRecordExpandable;
                            });

                            if (data.isExpanded) {
                                const nodeData = data.clone();
                                Object.assign(nodeData, { isExpanded: false });
                                node.setData(nodeData);
                                api.applyTransaction({ remove: newRowData });
                            } else {
                                const nodeData = data.clone();
                                Object.assign(nodeData, { isExpanded: true });
                                node.setData(nodeData);
                                api.applyTransaction({ add: newRowData, addIndex: node.rowIndex + 2 });
                            }
                        }
                    };
                    return (
                        <Button
                            shape={"circle"}
                            icon={data.isExpanded ? <CaretDownOutlined /> : <CaretRightOutlined />}
                            onClick={toggleLineItems}
                        />
                    );
                },
                cellStyle: () => ({ paddingLeft: 5, paddingRight: 5 }),
            },
        ];
        if (!product) {
            return defs;
        }
        const cols = new GenericRecordColumns(product, yearConfig);
        const excludeColumns = new Set([
            TableProperties.ComputedNr,
            TableProperties.ControlAction,
            TableProperties.ControlLog,
            TableProperties.ComputedModul,
            GenericRecordProperties.RecordDatum,
            GenericRecordProperties.RecordPeriod,
            GenericRecordProperties.ComputedOffen,
            GenericRecordProperties.ComputedZA,
            GenericRecordProperties.ComputedStatus,
            GenericRecordProperties.ControlBinding,
            GenericRecordProperties.RecordReview,
            GenericRecordProperties.ComputedSaldo,
            GenericRecordProperties.RecordFalligkeit,
            GenericRecordProperties.ComputedSHInfo,
            GenericRecordProperties.RecordDocuments,
        ]);

        const sortColumns = new Set([
            GenericRecordProperties.RecordContact,
            GenericRecordProperties.RecordCategoryCreditorNum,
            GenericRecordProperties.RecordCategoryCreditorName,
        ]);

        cols.getColumnConfig()
            .filter(v => !excludeColumns.has(v.key))
            .forEach(col => {
                const getData = (r: GenericRecord) => col.getter({ item: r, key: r.key, children: [], extra: {} });
                return defs.push({
                    headerValueGetter: () => ExportPreprocessor.nodeToString(col.header),
                    valueGetter: v => {
                        return <div>{getData(v.data) || <>&nbsp;</>}</div>;
                    },
                    cellStyle: node => {
                        return [
                            GenericRecordProperties.RecordBrutto,
                            GenericRecordProperties.RecordCurrencyOriginalAmount,
                            GenericRecordProperties.ComputedNetto,
                            GenericRecordProperties.ComputedUSt,
                        ].includes(col.key)
                            ? { textAlign: "right" }
                            : undefined;
                    },
                    width: col.width + 20,
                    filter: false,
                    autoHeight: true,
                    sort: col.key === GenericRecordProperties.RecordContact ? "asc" : undefined,
                    sortable: sortColumns.has(col.key),
                    comparator: sortColumns.has(col.key)
                        ? (a, b) => ExportPreprocessor.nodeToString(a).localeCompare(ExportPreprocessor.nodeToString(b))
                        : undefined,
                });
            });
        if (actions) {
            defs.push({
                headerComponent: HeaderActions,
                cellRenderer: CellActions,
                cellRendererParams: (params: ICellRendererParams<GenericRecord>): CellActionsProps => ({
                    onEdit: () => actions.onEdit(params.data),
                    onDelete: () => actions.onDelete(params.data),
                }),
                field: "actions" as any,
                floatingFilter: false,
                resizable: false,
                suppressMovable: true,
                suppressNavigable: true,
                width: 100,
                pinned: "right",
                sortable: false,
                lockPosition: true,
            });
        }
        return defs;
    }, [product, yearConfig, actions]);

    const rowClassRules = useMemo<RowClassRules<GenericRecordExpandable>>(
        () => ({
            [styles.agTotalRow]: params => !!params.node.rowPinned,
            [styles.agChildRow]: params => params.data.isChildRow,
        }),
        []
    );

    return (
        <Container absolute>
            {(w, h) => (
                <AgGridTable
                    rowData={templates}
                    wrapperStyles={{ height: h, width: w }}
                    columnDefs={columns}
                    gridOptions={gridOptions}
                    rowClassRules={rowClassRules}
                    getRowId={({ data }) => {
                        return data.key;
                    }}
                />
            )}
        </Container>
    );
};

import React, { FC } from "react";
import { Flex } from "antd";
import { InvoicesList, InvoicesListHeader } from "@inv/components/InvoicesList";

export const InvoicesListPage: FC = () => {
    return (
        <Flex
            vertical
            style={{
                height: "100%",
            }}
        >
            <InvoicesListHeader />
            <InvoicesList />
        </Flex>
    );
};

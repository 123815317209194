import React, { useContext } from "react";
import cn from "classnames";
import { ColumnGroupType, ColumnType } from "antd/es/table";

import tableStyles from "./VatTable.module.css";
import { titleColumns, useColumns } from "./VatColumns";
import { VATContext } from "./VatContext";
import { IVatRowData } from "./types";

interface CellProps {
    column: ColumnGroupType<IVatRowData>;
    item: IVatRowData;
}

const VatTableCell: React.FC<CellProps> = ({ column, item }) => {
    return (column.children as ColumnType<IVatRowData>[]).map((col, index) => (
        <div
            key={index}
            style={{ width: col.width ? Number(col.width) + 0.5 : "auto", justifyContent: "flex-end" }}
            className={cn(tableStyles.cell, {
                [col.className]: Boolean(col.className),
                [tableStyles.cellX]: item.titleSt?.startsWith("X") && index === 1,
            })}
        >
            <span>{col.render(undefined, item, index) as React.ReactNode}</span>
        </div>
    ));
};

interface RowProps {
    item: IVatRowData;
    columns: ColumnGroupType<IVatRowData>[];
}

const VatTableRow: React.FC<RowProps> = ({ item, columns }) => {
    if (item.isGroupTitle) {
        return <h3>{item.title}</h3>;
    }

    return (
        <div className={tableStyles.row}>
            {titleColumns.map((v, index) => (
                <div key={`title:${index}`} className={cn(tableStyles.cell)} style={{ width: v.width }}>
                    {v.render(item)}
                </div>
            ))}
            {columns.map((column, index) => (
                <VatTableCell key={`data:${index}`} column={column} item={item} />
            ))}
        </div>
    );
};

export const VatTable: React.FC = () => {
    const { dataSource, period } = useContext(VATContext);
    const columns = useColumns(period);
    const headerRef = React.useRef<HTMLHeadingElement>(null);

    return (
        <div
            className={tableStyles.container}
            onScroll={e => {
                headerRef.current.style.left = 24 - (e.target as any).scrollLeft + "px";
            }}
        >
            <div className={tableStyles.body}>
                {dataSource.map(item => (
                    <VatTableRow key={item.key} item={item} columns={columns} />
                ))}
            </div>
            <header ref={headerRef}>
                <>
                    {titleColumns.map((column, index) => (
                        <div key={index} style={{ width: column.width || "auto" }} className={tableStyles.headerItem}>
                            <div style={{ padding: 8 }}>{column.title as React.ReactNode}</div>
                        </div>
                    ))}
                    {columns.map((column, index) => (
                        <div key={index} style={{ width: column.width || "auto" }} className={tableStyles.headerItem}>
                            <div style={{ padding: 8 }}>{column.title}</div>
                            <div className={tableStyles.headerSubitem}>
                                {column.children.map((child, idxChild) => (
                                    <div key={idxChild} style={{ width: child.width || "auto" }}>
                                        {child.title as React.ReactNode}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </>
            </header>
        </div>
    );
};

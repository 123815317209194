import React, { FC } from "react";
import { PaperClipOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { GenericRecord } from "../../../scripts/models";

type Props = {
    record: GenericRecord;
    isEditDisabledSoft?: boolean;
    onClick?: (record: GenericRecord) => void;
};
export const ColDocuments: FC<Props> = ({ record, onClick, isEditDisabledSoft }) => {
    const { documents } = record;
    const len = Number(documents?.length || 0);
    const isImportMode = (documents ?? []).some(v => !v.url);
    return (
        <Button
            type="text"
            size="small"
            shape="circle"
            style={{ padding: 0, display: "flex", alignItems: "center", position: "relative" }}
            disabled={isImportMode || (isEditDisabledSoft && len === 0)}
            onClick={() => {
                onClick && onClick(record);
            }}
        >
            {len > 0 ? (
                <>
                    <PaperClipOutlined />
                    <sup style={{ position: "absolute", top: 3, right: 0 }}>{len}</sup>
                </>
            ) : (
                <PlusOutlined />
            )}
        </Button>
    );
};

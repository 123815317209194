import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { AgGridTable } from "@app/components/shared/AgGridTable";
import { gridOptions } from "./config/gridOptions";
import Container from "@app/components/shared/appearance/page/Container";
import { GQL } from "@binale-tech/shared";
import { ColDef, ColGroupDef, ICellRendererParams } from "ag-grid-community";
import { gql } from "@apollo/client";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import { AgGridReact } from "ag-grid-react";
import { CellActions, CellActionsProps, HeaderActions } from "@inv/components/TableActions/TableActions";
import InvoiceLineItemTemplateForm from "@inv/components/InvoiceLineItemTemplates/InvoiceLineItemTemplateForm";
import { useIntl } from "react-intl";
import { InvoiceFormInputTranslate } from "@inv/types";
import { formatDefault } from "@dms/scripts/helpers";

const invoiceLineItemTemplateDelete = gql`
    mutation invoiceLineItemTemplateDelete($input: InvoiceIDDeleteInput!) {
        invoiceLineItemTemplateDelete(input: $input)
    }
`;

export const InvoiceLineItemTemplateList: FC<{
    templatesList: GQL.IInvoiceLineItemTemplate[];
    refetch: () => void;
}> = ({ refetch, templatesList }) => {
    const intl = useIntl();
    const mutator = useGqlMutator();
    const apiRef = useRef<AgGridReact>(null);
    const [formData, setFormData] = useState<GQL.IInvoiceLineItemTemplate>();
    const onClose = useCallback(() => {
        setFormData(undefined);
        setTimeout(() => refetch(), 500);
    }, [refetch]);

    const onEdit = useCallback((data: GQL.IInvoiceLineItemTemplate) => {
        setFormData(data);
    }, []);
    const onDelete = useCallback(
        (data: GQL.IInvoiceLineItemTemplate) => {
            const { id, companyId } = data;
            mutator
                .mutate<"invoiceLineItemTemplateDelete", GQL.IInvoiceIdDeleteInput>({
                    mutation: invoiceLineItemTemplateDelete,
                    input: { id, companyId },
                })
                .then(() => setTimeout(() => refetch(), 500));
        },
        [mutator, refetch]
    );

    const columns: (ColDef<GQL.IInvoiceLineItemTemplate> | ColGroupDef<GQL.IInvoiceLineItemTemplate>)[] =
        useMemo(() => {
            return [
                {
                    headerName: intl.formatMessage({ id: InvoiceFormInputTranslate.NAME }),
                    field: "name",
                    width: 80,
                    sortable: false,
                },
                {
                    headerName: intl.formatMessage({ id: InvoiceFormInputTranslate.UNIT }),
                    field: "unit",
                    width: 150,
                    sortable: false,
                },
                {
                    headerName: intl.formatMessage({ id: InvoiceFormInputTranslate.PRICE }),
                    field: "price",
                    width: 150,
                    sortable: false,
                    cellRenderer: ({ value }: ICellRendererParams<GQL.IInvoiceLineItemTemplate>) => {
                        return <span>{formatDefault(value)}</span>;
                    },
                },
                {
                    headerName: intl.formatMessage({ id: InvoiceFormInputTranslate.TAX }),
                    field: "tax",
                    width: 150,
                    sortable: false,
                    cellRenderer: ({ value }: ICellRendererParams<GQL.IInvoiceLineItemTemplate>) => {
                        return <span>{value ?? 0}%</span>;
                    },
                },
                {
                    headerName: intl.formatMessage({ id: "app.fields.description" }),
                    field: "description",
                    width: 350,
                    sortable: false,
                },
                {
                    headerName: "CreatedAt",
                    field: "createdAt",
                    width: 200,
                    sortable: true,
                    comparator: (a, b) => new Date(a).getTime() - new Date(b).getTime(),
                },
                {
                    headerComponent: HeaderActions,
                    cellRenderer: CellActions,
                    cellRendererParams: (
                        params: ICellRendererParams<GQL.IInvoiceLineItemTemplate>
                    ): CellActionsProps => ({
                        disabled: false,
                        onEdit: () => onEdit(params.data),
                        onDelete: () => onDelete(params.data),
                    }),
                    field: "actions" as any,
                    floatingFilter: false,
                    resizable: false,
                    suppressMovable: true,
                    suppressNavigable: true,
                    width: 100,
                    pinned: "right",
                    sortable: false,
                    lockPosition: true,
                },
            ] satisfies ColDef<GQL.IInvoiceLineItemTemplate>[];
        }, [onDelete, onEdit]);

    return (
        <>
            <AgGridTable ref={apiRef} columnDefs={columns} gridOptions={gridOptions} rowData={templatesList} />
            <InvoiceLineItemTemplateForm onClose={onClose} open={Boolean(formData)} data={formData} />
        </>
    );
};

import RecordFormState from "../../../appearance/components/recordform/types/RecordFormState";
import { GenericRecord } from "../index";
import RecordFormUtils from "../../../appearance/components/recordform/utils/RecordFormUtils";

type RecordForm = {
    editableRecord: Partial<RecordFormState["editableRecord"]>;
    editableRecordItem: Partial<RecordFormState["editableRecordItem"]>;
};

export class ContactAccountingConverter {
    static applyContactTemplateToRecord(template: GenericRecord, currentState: RecordForm): RecordForm {
        const result: RecordForm = {
            editableRecord: {},
            editableRecordItem: {},
        };
        if (!template) {
            return result;
        }

        if (!currentState.editableRecord.recordNum && template.num) {
            result.editableRecord.recordNum = template.num;
        }

        if (!currentState.editableRecord.recordCategoryCreditor) {
            const { category, creditor, debetor } = template;
            result.editableRecord.recordCategoryCreditor = category ?? creditor ?? debetor;
        }

        // todo: consider applying items maybe
        if (!currentState.editableRecordItem) {
            return result;
        }

        const [templateItem] = template.items;
        if (!currentState.editableRecordItem.itemTag && templateItem.tag) {
            result.editableRecordItem.itemTag = templateItem.tag;
        }
        if (!currentState.editableRecordItem.itemCategoryCreditor) {
            const { category, creditor, debetor } = templateItem;
            result.editableRecordItem.itemCategoryCreditor = category ?? creditor ?? debetor;
            if (category) {
                if (!category.isAutoBu()) {
                    if (!currentState.editableRecordItem.itemBu && templateItem.bu) {
                        result.editableRecordItem.itemBu = templateItem.bu;
                        result.editableRecordItem.itemUSt13b = templateItem.USt13b || undefined;
                    }
                } else {
                    const ustData = RecordFormUtils.getUstFromAccountChange(category);
                    if (ustData) {
                        result.editableRecordItem.itemBu = ustData.itemBu;
                        result.editableRecordItem.itemUSt13b = ustData.itemUSt13b;
                    }
                }
            }
        }
        // if (!currentState.editableRecordItem.itemText && templateItem.text) {
        if (templateItem.text) {
            result.editableRecordItem.itemText = templateItem.text;
        }
        if (!currentState.editableRecordItem.itemText2 && templateItem.text2) {
            result.editableRecordItem.itemText2 = templateItem.text2;
        }
        if (!currentState.editableRecordItem.itemBelegfeld1 && templateItem.belegfeld1) {
            result.editableRecordItem.itemBelegfeld1 = templateItem.belegfeld1;
        }
        if (!currentState.editableRecordItem.itemBelegfeld2 && templateItem.belegfeld2) {
            result.editableRecordItem.itemBelegfeld2 = templateItem.belegfeld2;
        }
        return result;
    }
}

import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { Form, Input, InputProps, InputRef, Typography } from "antd";
import { useIntl } from "react-intl";
import { getName } from "i18n-iso-countries";
import { countriesList } from "./countriesList";
import { useApolloClient } from "@apollo/client";

// TODO temporary solution
import { electronicFormatIBAN, extractIBAN, ExtractIBANResult } from "@banks/scripts/ibantools";
import { bankDataByIBAN } from "bankdata-germany";
import { validation } from "../../../../../../scripts/infrastructure/helpers/validation";

interface Props extends Omit<InputProps, "onChange"> {
    onChange?: (value: string) => void;
}

export const getIBANcountryCode = (value: string) =>
    value && countriesList[value.substring(0, 2)] ? value.substring(0, 2) : "";
export const getMaxLengthIBAN = (value: string): number => countriesList[getIBANcountryCode(value)] || 34;

const transformValue = (value: string) => {
    let newValue = value || "";
    const maxLength = getMaxLengthIBAN(value);
    newValue = newValue.replaceAll(/[^A-Za-z0-9]/g, "");

    if (newValue.length > maxLength) {
        newValue = newValue.substring(0, maxLength);
    }
    return newValue.toUpperCase();
};

export const useIBANValidation = () => {
    const intl = useIntl();
    const client = useApolloClient();
    return useMemo(() => {
        return {
            countryCode: (value: string) => {
                const countryCode = getIBANcountryCode(value);
                if (value && !countryCode) {
                    return "IBAN country code is wrong";
                }
                return true;
            },
            maxLen: (value: string) => {
                const maxLength = getMaxLengthIBAN(value);
                if (value && value.length !== maxLength) {
                    return intl.formatMessage({ id: "app.validation.error.strictLength" }, { length: maxLength });
                }
                return true;
            },
            validateIban: async (value: string) => {
                const countryCode = getIBANcountryCode(value);
                const maxLength = getMaxLengthIBAN(value);
                if (value.length === maxLength && countryCode) {
                    const { message, validator } = validation.iban(client);
                    return (validator(null, value, null) as Promise<void>)
                        .then(() => true)
                        .catch(() => message as string);
                }
                return true;
            },
        };
    }, [intl, client]);
};

interface LabelProps {
    value: string;
}

export const InputIBANLabelAddon: React.FC<LabelProps> = ({ value }) => {
    const intl = useIntl();
    const countryCode = getIBANcountryCode(value);
    const maxLength = getMaxLengthIBAN(countryCode);
    const valueLength = value?.length || 0;

    return (
        <Typography.Text type="secondary" data-testid="country-addon">
            {countryCode && <span style={{ maxWidth: 100 }}>{getName(countryCode, intl.locale)} | </span>}
            {`${valueLength} / ${maxLength}`}
        </Typography.Text>
    );
};

export const InputIBANLabel = ({ countryCode }: { countryCode: string }) => {
    const intl = useIntl();

    return (
        <span>
            <Typography.Text strong ellipsis>
                IBAN
            </Typography.Text>
            <Typography.Text type="secondary" data-testid="country-addon">
                {countryCode && (
                    <span style={{ marginLeft: 8, maxWidth: 100 }}>{getName(countryCode, intl.locale)}</span>
                )}
            </Typography.Text>
        </span>
    );
};

export const InputIBAN = forwardRef<InputRef, Props>(function InputIBAN({ value, onChange, ...props }, ref) {
    const [innerValue, setInnerValue] = useState(transformValue(value ? value.toString() : ""));

    useEffect(() => {
        const newValue = transformValue(value ? value.toString() : "");
        if (newValue !== value) {
            onChange && onChange(newValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = transformValue(e.target.value);
        if (innerValue !== newValue) {
            setInnerValue(newValue);
            onChange && onChange(newValue);
        }
    };

    return <Input {...props} value={innerValue} onChange={handleChange} ref={ref} />;
});

interface IProps extends Omit<InputProps, "onChange" | "onBlur"> {
    value?: string;
    onChange?: (value: string) => void;
    onBlur?: (bankData: ExtractIBANResult) => void;
}

export const InputIbanToBanks = forwardRef<InputRef, IProps>(function InputIbanToBanks(
    { value, onChange, ...props },
    ref
) {
    const [innerValue, setInnerValue] = useState(value ? electronicFormatIBAN(value) : "");
    const [isChange, setIsChange] = useState<boolean>(false);

    const form = Form.useFormInstance();

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setIsChange(true);

            if (!onChange) {
                return;
            }

            const newValue = electronicFormatIBAN(e.target.value);
            setInnerValue(e.target.value ? electronicFormatIBAN(e.target.value) : "");
            onChange(newValue);
        },
        [onChange]
    );

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(() => {
        if (innerValue && isChange) {
            form.validateFields(["IBAN"]);
            const bankData = extractIBAN(innerValue);
            if (!bankData.valid) {
                return;
            }
            if (bankData.countryCode === "DE") {
                const dataByDeIban = bankDataByIBAN(innerValue);
                form.setFieldValue("bankName", dataByDeIban?.bankName);
                form.setFieldValue("BIC", dataByDeIban?.bic);
            }
            if (props.onBlur) {
                props.onBlur(bankData);
            }
        }
    }, [form, innerValue, isChange, props.onBlur]);

    return <Input {...props} value={innerValue} onChange={handleChange} onBlur={handleBlur} ref={ref} />;
});

import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Divider, List, message, Modal, Progress, Space, Tag } from "antd";
import { FormProvider, useForm } from "react-hook-form";

import RecordFormUtils from "../../../recordform/utils/RecordFormUtils";
import pLimit from "p-limit";
import { Bu, GQL, Utils } from "@binale-tech/shared";
import { BulkFormItem } from "./BulkFormItem";
import { Category, Creditor, Debitor, GenericRecord } from "scripts/models";
import { CategoryCreditorModes, GenericRecordProperties } from "scripts/core/Product";
import { CheckCircleOutlined } from "@ant-design/icons";
import { CompanyContext } from "scripts/context/CompanyContext";
import { PaymentsContext } from "scripts/context/PaymentsProvider";
import { RecordsContext } from "scripts/context/recordsContext/RecordsCtx";
import { FormattedMessage } from "react-intl";
import { GenericRecordColumns } from "appearance/columns/ColumnConfig";
import { IBulkForm } from "./helpers";
import { PaymentBindingUtils } from "../../../../../scripts/models/utils/PaymentBindingUtils";
import { TableViewContext } from "scripts/context/tableViewContext/tableViewContext";
import { useModuleActions, useModuleRecords } from "scripts/infrastructure/hooks/useModuleRecords";
import "./style.css";
import { PaymentPrototype } from "../../../../../scripts/models/Payment";

interface BulkRecordProps {
    itemsToEdit: GenericRecord[];
    onCancel: () => void;
}

// form fields available for the partial edit:
// this.REF_iTEXT, this.REF_iTEXT2, this.REF_cASSET, this.REF_iBELEGFELD2, this.REF_iTAG
const partiallyEditableRepresentationFields = [
    GenericRecordProperties.ItemBuchungstext,
    GenericRecordProperties.ItemBuchungstext2,
    GenericRecordProperties.ItemBelegfeld2,
    GenericRecordProperties.ItemKS,
];

export const BulkRecordEditModal: React.FC<BulkRecordProps> = ({ itemsToEdit, onCancel }) => {
    const [selectedFieldKeys, setSelectedFieldKeys] = useState<GenericRecordProperties[]>([]);
    const [processingIdx, setProcessingIdx] = React.useState<number>();
    const methods = useForm<IBulkForm>();
    const { yearConfig, programSettingsProvider } = useContext(CompanyContext);
    const { product, productKey } = useContext(TableViewContext);
    const { allRecords } = useContext(RecordsContext);
    const { recordRelation } = useContext(PaymentsContext);

    const hasSplitItems = itemsToEdit.some(item => item.items.length > 1);

    const settings = React.useMemo(() => programSettingsProvider(productKey), [productKey, programSettingsProvider]);

    const moduleActions = useModuleActions();
    const moduleRecords = useModuleRecords();

    const handleToggle = (isEnabled: boolean, fieldName: string) => {
        if (isEnabled) {
            setSelectedFieldKeys(prev => [...prev, fieldName]);
        } else {
            setSelectedFieldKeys(prev => prev.filter(key => key !== fieldName));
        }
    };
    const resetUst = () => {
        handleToggle(false, GenericRecordProperties.ItemUStPerc);
        handleToggle(false, GenericRecordProperties.ItemUSt13b);
        methods.setValue("itemUSt", null);
        methods.setValue("itemUSt13b", null);
    };
    const resetUst13b = () => {
        handleToggle(false, GenericRecordProperties.ItemUSt13b);
        methods.setValue("itemUSt13b", null);
    };

    const itemAccount = methods.watch("itemAccount") as Category;
    const itemUSt = methods.watch("itemUSt");

    useEffect(() => {
        if (Bu.getUst13bData(itemUSt).options) {
            handleToggle(true, GenericRecordProperties.ItemUSt13b);
        } else {
            resetUst13b();
        }
    }, [itemUSt]);
    useEffect(() => {
        if (itemAccount instanceof Category && itemAccount.isAutoBu()) {
            methods.setValue("itemUSt", itemAccount.getAutoBu());
            methods.setValue("itemUSt13b", itemAccount.sv13b || null);
            handleToggle(true, GenericRecordProperties.ItemUStPerc);
        } else {
            resetUst();
        }
    }, [itemAccount]);

    const { recordsToEdit, isRepresentationPartialEditMode } = React.useMemo(() => {
        const recordKeys = new Set(moduleRecords.map(item => item.key));
        const selectedRecords = itemsToEdit.filter(item => recordKeys.has(item.key));

        const isProductPaymentRepresentation = Utils.PaymentUtils.isProductPaymentRepresentation(
            product.productKey() as GQL.IProductKey
        );
        if (!isProductPaymentRepresentation) {
            return {
                recordsToEdit: selectedRecords.map(record => ({ record, paymentProto: null as PaymentPrototype })),
                isRepresentationPartialEditMode: false,
            };
        }
        // we don't allow edit some fields for the payment representation records with payment connection
        // using the same method as in form (to set selectedPayment): PaymentBindingUtils.getConnectedPaymentSourceRecord
        const filteredUnconnectedRecords = selectedRecords.map(record => {
            const paymentProto = PaymentBindingUtils.getRepresentationRecordPaymentProto({
                allRecords,
                paymentsRecordRelation: recordRelation,
                representationRecord: record,
            });
            return {
                record,
                paymentProto,
            };
        });

        return {
            recordsToEdit: filteredUnconnectedRecords,
            isRepresentationPartialEditMode: filteredUnconnectedRecords.some(v => Boolean(v.paymentProto)),
        };
    }, [moduleRecords, itemsToEdit, product, allRecords, recordRelation]);

    const totalCount = recordsToEdit.length;
    const step = 100 / totalCount;
    const progress = processingIdx === totalCount ? 100 : Math.floor((processingIdx ?? 0) * step);

    const onSubmit = async (values: IBulkForm) => {
        setProcessingIdx(0);
        const limit = pLimit(5);
        const input = recordsToEdit.map(({ record, paymentProto }) =>
            limit(() => {
                if (selectedFieldKeys.includes(GenericRecordProperties.RecordBelegfeld1)) {
                    record.num = values.recordNum;
                }
                if (selectedFieldKeys.includes(GenericRecordProperties.RecordCategoryCreditorNum)) {
                    if (product.getConfig().recordAccountMode === CategoryCreditorModes.CRED) {
                        record.creditor = Creditor.unserialize(values.konto);
                        record.category = null;
                        record.debetor = null;
                    }
                    if (product.getConfig().recordAccountMode === CategoryCreditorModes.DEB) {
                        record.debetor = Debitor.unserialize(values.konto);
                        record.category = null;
                        record.creditor = null;
                    }
                }
                if (selectedFieldKeys.includes(GenericRecordProperties.ItemBelegfeld2)) {
                    record.items.forEach(item => (item.belegfeld2 = values.belegfeld2));
                }
                if (selectedFieldKeys.includes(GenericRecordProperties.RecordContact)) {
                    record.partner = values.contact;
                }
                if (selectedFieldKeys.includes(GenericRecordProperties.ItemBuchungstext)) {
                    record.items.forEach(item => (item.text = values.itemText));
                }
                if (selectedFieldKeys.includes(GenericRecordProperties.ItemBuchungstext2)) {
                    record.items.forEach(item => (item.text2 = values.itemText2));
                }
                if (selectedFieldKeys.includes(GenericRecordProperties.ItemKS)) {
                    record.items.forEach(item => (item.tag = values.tag));
                }
                if (selectedFieldKeys.includes(GenericRecordProperties.ItemCategoryCreditorNum)) {
                    const { category, creditor, debitor } = RecordFormUtils.resolveCCD(values.itemAccount);
                    record.items.forEach(item => {
                        if (category) {
                            item.creditor = null;
                            item.debetor = null;
                            item.category = Category.unserialize(category).fixNum(yearConfig.kontoExt) as Category;
                            if (item.category.isAutoBu()) {
                                item.bu = item.category.getAutoBu();
                                item.USt13b = item.category.sv13b || values.itemUSt13b;
                            } else {
                                if (selectedFieldKeys.includes(GenericRecordProperties.ItemUStPerc)) {
                                    item.bu = values.itemUSt || 0;
                                    item.USt13b = undefined;
                                }
                                if (selectedFieldKeys.includes(GenericRecordProperties.ItemUSt13b)) {
                                    item.USt13b = values.itemUSt13b;
                                }
                            }
                        } else if (creditor) {
                            item.debetor = null;
                            item.category = null;
                            item.creditor = Creditor.unserialize(creditor).fixNum(yearConfig.kontoExt) as Creditor;
                            item.bu = Bu.Bu.KU;
                        } else if (debitor) {
                            item.creditor = null;
                            item.category = null;
                            item.debetor = Creditor.unserialize(debitor).fixNum(yearConfig.kontoExt) as Debitor;
                            item.bu = Bu.Bu.KU;
                        }
                    });
                }
                return moduleActions.save(record, paymentProto);
            })
                .catch(e => message.error(e.message))
                .finally(() => setProcessingIdx(i => i++))
        );
        await Promise.all(input).finally(() => setProcessingIdx(totalCount));
        await new Promise(r => setTimeout(r, 1000));

        setProcessingIdx(undefined);
        setSelectedFieldKeys([]);
    };

    const filteredColumns = useMemo(() => {
        const columnsConfigMap = new GenericRecordColumns(product, yearConfig).getMappedColumns();
        const fieldKeys = new Set(
            product.getConfig().bulkEditFields.filter(v => {
                if (!hasSplitItems) {
                    return true;
                }
                // in case of split items we are allowing only contact for editing
                return hasSplitItems && v === GenericRecordProperties.RecordContact;
            })
        );
        if (settings.hideBuchText) {
            fieldKeys.delete(GenericRecordProperties.ItemBuchungstext);
        }
        if (!settings.useBuchText2) {
            fieldKeys.delete(GenericRecordProperties.ItemBuchungstext2);
        }
        if (!settings.useBelegfeld2) {
            fieldKeys.delete(GenericRecordProperties.ItemBelegfeld2);
        }
        if (!settings.useKs) {
            fieldKeys.delete(GenericRecordProperties.ItemKS);
        }
        if (settings.hideContacts) {
            fieldKeys.delete(GenericRecordProperties.RecordContact);
        }
        const filteredBulkEditFields = product.getConfig().bulkEditFields.filter(key => fieldKeys.has(key));
        return filteredBulkEditFields.map(key => columnsConfigMap.get(key));
    }, [product, yearConfig, settings]);

    return (
        <Modal
            open
            width={1200}
            destroyOnClose
            data-testid="bulk-record-edit-modal"
            title={<FormattedMessage id="app.titles.bulkEdit" />}
            footer={null}
            onCancel={onCancel}
            closable={!methods.formState.isSubmitting}
        >
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(onSubmit)}
                    className="ant-form ant-form-vertical ant-form-vertical--condensed"
                    style={{ marginLeft: 12 }}
                >
                    <List
                        className="FormNameItemsList"
                        dataSource={filteredColumns}
                        bordered
                        renderItem={item => (
                            <List.Item className="FormNameItemsList__Item" style={{ justifyContent: "flex-start" }}>
                                <BulkFormItem
                                    disabled={
                                        isRepresentationPartialEditMode &&
                                        !partiallyEditableRepresentationFields.includes(item.key)
                                    }
                                    itemKey={item.key}
                                    checked={selectedFieldKeys.includes(item.key)}
                                    label={item.header}
                                    onToggle={isEnabled => {
                                        const fieldName = item.key;
                                        handleToggle(isEnabled, fieldName);
                                        if (fieldName === GenericRecordProperties.ItemCategoryCreditorNum) {
                                            resetUst();
                                        }
                                        if (fieldName === GenericRecordProperties.ItemUStPerc) {
                                            resetUst13b();
                                        }
                                    }}
                                />
                            </List.Item>
                        )}
                    />

                    <Divider />

                    <Space style={{ width: "100%", justifyContent: "end" }}>
                        {methods.formState.isSubmitSuccessful && (
                            <Tag icon={<CheckCircleOutlined />} color="success">
                                <FormattedMessage id={"app.button.done"} />
                            </Tag>
                        )}
                        {methods.formState.isSubmitting ? (
                            <Progress
                                percent={progress}
                                status={progress < 100 ? "active" : undefined}
                                style={{ minWidth: 250 }}
                            />
                        ) : (
                            <Button onClick={onCancel}>
                                {methods.formState.isSubmitSuccessful ? (
                                    <FormattedMessage id="app.button.close" />
                                ) : (
                                    <FormattedMessage id="app.button.cancel" />
                                )}
                            </Button>
                        )}

                        {!methods.formState.isSubmitSuccessful && (
                            <Button
                                htmlType="submit"
                                type="primary"
                                loading={methods.formState.isSubmitting}
                                disabled={!selectedFieldKeys.length || totalCount === 0}
                            >
                                <FormattedMessage id="app.button.save" />
                            </Button>
                        )}
                    </Space>
                </form>
            </FormProvider>
        </Modal>
    );
};

import React, { useContext, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ValidateResult } from "react-hook-form";

import { Contacts } from "@binale-tech/shared";
import { ContactsContext } from "scripts/context/ContactsContext";
import { useValidationReactHookForm, validation } from "scripts/infrastructure/helpers/validation";
import { useApolloClient } from "@apollo/client";

export const useNothingSelectedMessage = () => {
    const intl = useIntl();
    const relations = Contacts.ContactConstants.relationTypeKontoOrder.map(key =>
        intl.formatMessage({ id: `app.titles.contacts.${key}` })
    );
    return <FormattedMessage id="app.message.nothingSelected" values={{ relations: relations.join(", ") }} />;
};

export const useCustomValidation = (initialContact: Contacts.Contact) => {
    const { contacts } = useContext(ContactsContext);
    const validators = useValidationReactHookForm();
    const client = useApolloClient();

    return useMemo(() => {
        const uniqueData = contacts.reduce(
            (acc, contact) => {
                return {
                    IDNr: [...(acc.IDNr || []), contact.legalInfo?.IDNr],
                    EORI: [...(acc.EORI || []), contact.legalInfo?.EORI],
                    taxNumber: [...(acc.taxNumber || []), contact.legalInfo?.tax?.stnr],
                    internalName: [...(acc.internalName || []), contact.internalName],
                };
            },
            {} as Record<string, string[]>
        );

        return {
            internalName: validators.unique(uniqueData.internalName, initialContact?.internalName),
            IDNr: validators.unique(uniqueData.IDNr, initialContact?.legalInfo?.IDNr),
            EORI: validators.unique(uniqueData.EORI, initialContact?.legalInfo?.EORI),
            taxNumberUnique: validators.unique(uniqueData.taxNumber, initialContact?.legalInfo?.tax?.stnr),
            taxNumberValid: async (v: string, formData: Contacts.Contact): Promise<ValidateResult> => {
                const bundesland = formData?.legalInfo?.tax?.bundesland;
                const { message, validator } = validation.stnr(client, bundesland);
                return (validator(null, v, null) as Promise<void>).then(() => true).catch(() => message as string);
            },
        };
    }, [contacts, initialContact, validators, client]);
};

export const getContactRegisterPrint = (legal: Contacts.Contact["legalInfo"] = {}) => {
    if (!legal?.register) {
        return "";
    }
    const { register } = legal;
    return [register.type, register.court, register.number].filter(Boolean).join(" ");
};

export const getContactVatPrint = (legal: Contacts.Contact["legalInfo"] = {}) => {
    if (!legal?.vat) {
        return "";
    }
    const { vat } = legal;
    return `${vat.countryCode}${vat.vatNumber}`;
};

export const useLegalInfoValidation = (initialContact: Contacts.Contact) => {
    const { contacts } = useContext(ContactsContext);
    const client = useApolloClient();

    return useMemo(() => {
        const uniqueData = contacts.reduce(
            (acc, contact) => {
                if (initialContact?.uuid && contact.uuid === initialContact?.uuid) {
                    return acc;
                }
                return {
                    vat: [...(acc.vat || []), getContactVatPrint(contact.legalInfo)],
                    register: [...(acc.register || []), getContactRegisterPrint(contact.legalInfo)],
                };
            },
            { vat: [], register: [] } as Record<"vat" | "register", string[]>
        );

        return {
            vatUnique: async (v: string, formData: Contacts.Contact) => {
                if (
                    v &&
                    formData?.legalInfo?.vat?.countryCode &&
                    uniqueData.vat.includes(getContactVatPrint(formData?.legalInfo))
                ) {
                    return "not unique";
                }
                return true;
            },
            gatVatValidator:
                (companyId: string) =>
                async (v: string, formData: Contacts.Contact): Promise<ValidateResult> => {
                    const countryCode = formData?.legalInfo?.vat?.countryCode;
                    const { message, validator } = validation.vat(client, countryCode, companyId);
                    return (validator(null, v, null) as Promise<void>)
                        .then(() => true)
                        .catch(e => `${message}: ${e.message}`);
                },
            register: (v: string, formData: Contacts.Contact) => {
                const formRegister = getContactRegisterPrint(formData?.legalInfo);
                if (formRegister && uniqueData.register.includes(formRegister)) {
                    return "not unique";
                }
                return true;
            },
        };
    }, [client, contacts, initialContact]);
};

import { useMemo } from "react";
import { useIntl } from "react-intl";
import { ICountriesOption } from "../../../appearance/views/productContacts/components/CountriesCombobox";
import { Contacts } from "@binale-tech/shared";
import { getName } from "i18n-iso-countries";
import { Lang } from "../../intl";

const othersCountries: Record<Lang, Record<string, string>> = {
    [Lang.De]: {
        EL: "Griechenland",
        XI: "Nordirland",
    },
    [Lang.En]: {
        EL: "Greece",
        XI: "Northen Ireland",
    },
    [Lang.Ru]: {
        EL: "Греция",
        XI: "Северная Ирландия",
    },
};
export const useEuroCountryArray = () => {
    const intl = useIntl();
    const euroCountriesArray: ICountriesOption[] = useMemo(
        () =>
            Object.keys(Contacts.ContactConstants.CountriesEuVatRules).map(code => ({
                value: code,
                name: getName(code, intl.locale) || othersCountries[intl.locale as Lang]?.[code],
            })),
        [intl]
    );

    return euroCountriesArray;
};

import React, { FC } from "react";
import { PageHeader } from "@inv/components/PageHeader";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../scripts/routing/routeConstants";
import { Button, Flex } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { InvoiceForm } from "@inv/components/InvoiceForm";
import { initialValues } from "@inv/components/InvoiceForm/consts/consts";

export const CreateInvoicePage: FC = () => {
    return (
        <Flex
            vertical
            style={{
                margin: "0 auto",
                maxWidth: 1366,
                height: "100%",
            }}
        >
            <PageHeader
                title={<FormattedMessage id={"app.invoice.createInvoice"} />}
                button={
                    <Link to={AppRoutes.invoicing}>
                        <Button type={"text"} icon={<ArrowLeftOutlined />}>
                            <FormattedMessage id={"app.invoice.invoicesList"} />
                        </Button>
                    </Link>
                }
            />
            <InvoiceForm initialValues={initialValues} />
        </Flex>
    );
};

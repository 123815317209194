import { IDecoder } from "./IDecoder";
import { convertDateToStructured } from "./CIITools";
import { Bu, GQL } from "@binale-tech/shared";

export class DecoderVoid implements IDecoder {
    getVersion() {
        return { version: "Unbekannt", isDeprecated: false };
    }
    getInvoiceID(): string {
        return undefined;
    }
    getInvoiceDate() {
        return convertDateToStructured(undefined);
    }
    getCurrency(): GQL.ICurrencyCode {
        return GQL.ICurrencyCode.Eur;
    }
    getDeliveryDate(): string {
        return undefined;
    }
    getPaymentReference(): string {
        return undefined;
    }
    getGeneralPaymentDetails(): { dueDate: string; description: string } {
        return { dueDate: undefined, description: undefined };
    }
    getSupplierPaymentType(): { type: string; IBAN: string; BIC: string; accountName: string } {
        return { type: undefined, IBAN: undefined, BIC: undefined, accountName: undefined };
    }
    getTaxes(): { tax: number; nettoAmount: number; taxAmount: number; brutto: number; bu: Bu.Bu }[] {
        return [];
    }
    getSupplier(): { name: string; vatId: string; taxId: string; addressLines: string[]; communications: string[] } {
        return { name: undefined, vatId: undefined, taxId: undefined, addressLines: [], communications: [] };
    }
    getBuyer(): { name: string; vatId: string; taxId: string; addressLines: string[]; email: string } {
        return { name: undefined, vatId: undefined, taxId: undefined, addressLines: [], email: undefined };
    }
    getLineItems() {
        return [] as any[];
    }
}

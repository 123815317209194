import React, { useRef } from "react";
import { Button, Divider, Form, Row, Space, Tooltip } from "antd";
import { Contacts } from "@binale-tech/shared";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { FieldLabel } from "@ui-components/FieldLabel";
import { FormattedMessage } from "react-intl";
import { PlusOutlined } from "@ant-design/icons";

import { AppealCombobox, BaseInput } from "appearance/components/shared/form/baseComponents";
import { CommunicationButton, CommunicationItem } from "../components/communication";
import { RemoveButton } from "../components/RemoveButton";
import { removeEmpty } from "scripts/infrastructure/helpers";
import { Checkbox } from "@nextui-org/checkbox";

interface CommunicationProps {
    personIndex: number;
    removePerson: () => void;
}

const ContactPersonCommunications: React.FC<CommunicationProps> = ({ personIndex, removePerson }) => {
    const { setFocus, getValues } = useFormContext<Contacts.Contact>();
    const { append, remove, fields } = useFieldArray<Contacts.Contact>({
        name: `contactPersons.${personIndex}.communications`,
    });
    const addBtnRef = useRef<HTMLButtonElement>(null);

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {fields.map((communication, cIndex) => (
                <CommunicationItem
                    key={communication.id}
                    communicationIndex={cIndex}
                    contactPersonIndex={personIndex}
                    onRemove={async () => {
                        remove(cIndex);
                        await new Promise(r => setTimeout(r, 10));
                        if (fields.length > 1 && fields.length - 1 > cIndex) {
                            setFocus(`contactPersons.${personIndex}.communications.${cIndex}.value`);
                        } else {
                            addBtnRef.current?.focus();
                        }
                    }}
                />
            ))}
            <Row justify="space-between">
                <CommunicationButton
                    ref={addBtnRef}
                    onSelect={type =>
                        append(
                            { type, comment: "", value: "" },
                            { focusName: `contactPersons.${personIndex}.communications.${fields.length}.value` }
                        )
                    }
                />
                <Form.Item label=" ">
                    <RemoveButton
                        isItemEmpty={() => {
                            const person = getValues(`contactPersons.${personIndex}`) || {};
                            person.communications =
                                person?.communications?.map(communication => {
                                    const slimmedCommun = removeEmpty(communication);
                                    if (!slimmedCommun || Object.keys(slimmedCommun).length === 1) {
                                        return undefined;
                                    }
                                    return communication;
                                }) || undefined;

                            const contactPerson = removeEmpty(person);
                            return !contactPerson;
                        }}
                        onRemove={removePerson}
                    />
                </Form.Item>
            </Row>
            <Divider style={{ borderTopColor: "#1890ff", margin: "8px 0" }} />
        </Space>
    );
};

export const ContactPerson: React.FC = React.memo(function ContactPerson() {
    const { setFocus, setValue } = useFormContext<Contacts.Contact>();
    const { append, remove, fields } = useFieldArray<Contacts.Contact>({ name: "contactPersons" });
    const addBtnRef = useRef<HTMLButtonElement>(null);

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {fields.map((contactPerson, index) => (
                <div key={contactPerson.id}>
                    <Divider orientation="left" plain style={{ borderTopColor: "#1890ff" }}>
                        <Space align={"center"} size={30}>
                            <b>
                                <FormattedMessage id="app.titles.contactPerson" /> {index + 1}
                            </b>
                            <Tooltip title="als Rechnungsadresse markieren" placement="top">
                                <Controller
                                    name={`contactPersons.${index}.isPrimary`}
                                    shouldUnregister
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            isSelected={field.value}
                                            onValueChange={() => {
                                                for (let i = 0; i < fields.length; i++) {
                                                    setValue(`contactPersons.${i}.isPrimary`, i === index);
                                                }
                                            }}
                                        >
                                            <FormattedMessage id="app.fields.invoice" />
                                        </Checkbox>
                                    )}
                                />
                            </Tooltip>
                        </Space>
                    </Divider>
                    <Row>
                        <Space>
                            <Controller
                                name={`contactPersons.${index}.appeal`}
                                shouldUnregister
                                render={({ field }) => (
                                    <Form.Item
                                        label={<FormattedMessage id="app.fields.salutation" />}
                                        style={{ minWidth: 120 }}
                                    >
                                        <AppealCombobox {...field} />
                                    </Form.Item>
                                )}
                            />
                            <Controller
                                name={`contactPersons.${index}.firstName`}
                                shouldUnregister
                                render={({ field }) => (
                                    <FieldLabel
                                        label={<FormattedMessage id="app.fields.firstName" />}
                                        style={{ minWidth: 200 }}
                                    >
                                        <BaseInput {...field} maxLength={20} showCount />
                                    </FieldLabel>
                                )}
                            />
                            <Controller
                                name={`contactPersons.${index}.lastName`}
                                shouldUnregister
                                render={({ field }) => (
                                    <FieldLabel
                                        label={<FormattedMessage id="app.fields.lastName" />}
                                        style={{ minWidth: 200 }}
                                    >
                                        <BaseInput {...field} maxLength={20} showCount />
                                    </FieldLabel>
                                )}
                            />
                        </Space>
                    </Row>
                    <Row>
                        <Space>
                            <Controller
                                name={`contactPersons.${index}.position`}
                                shouldUnregister
                                render={({ field }) => (
                                    <FieldLabel
                                        label={<FormattedMessage id="app.fields.position" />}
                                        style={{ minWidth: 250 }}
                                    >
                                        <BaseInput {...field} maxLength={30} showCount />
                                    </FieldLabel>
                                )}
                            />
                            <Controller
                                name={`contactPersons.${index}.department`}
                                shouldUnregister
                                render={({ field }) => (
                                    <FieldLabel
                                        label={<FormattedMessage id="app.fields.department" />}
                                        style={{ minWidth: 250 }}
                                    >
                                        <BaseInput {...field} maxLength={30} showCount />
                                    </FieldLabel>
                                )}
                            />
                        </Space>
                    </Row>
                    <ContactPersonCommunications
                        personIndex={index}
                        removePerson={async () => {
                            remove(index);
                            await new Promise(r => setTimeout(r, 10));
                            if (fields.length > 1 && fields.length - 1 > index) {
                                setFocus(`contactPersons.${index}.appeal`);
                            } else {
                                addBtnRef.current?.focus();
                            }
                        }}
                    />
                </div>
            ))}
            <Row justify={fields.length ? "end" : "start"}>
                <Button
                    type="primary"
                    ref={addBtnRef}
                    onClick={() => {
                        append(
                            {
                                firstName: "",
                                lastName: "",
                                position: "",
                                department: "",
                                appeal: null,
                                communications: [],
                            },
                            { focusName: `contactPersons.${fields.length}` }
                        );
                    }}
                    icon={<PlusOutlined />}
                >
                    <FormattedMessage id="app.titles.contactPerson" tagName="span" />
                </Button>
            </Row>
        </Space>
    );
});

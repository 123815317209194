import React, { Children, cloneElement, CSSProperties, FC, ReactElement, useContext, useEffect, useState } from "react";
import { getOpacity, GetStyles, getTransform, getZIndex } from "./getStyles.js";
import { Button } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { PdfToolsControlContext } from "../../context";
import styles from "./PagesCarousel.module.scss";
import { POSITION } from "./constants";

type TProps = {
    children: ReactElement[];
    initialIndex?: number;
    draggable?: boolean;
};

export const PagesCarousel: FC<TProps> = ({ children, draggable, initialIndex }) => {
    const [current, setCurrent] = useState<{ currentIndex: number | null; currentLength: number }>({
        currentIndex: initialIndex ?? null,
        currentLength: Children.count(children),
    });

    const [isArrowClicked, setIsArrowClicked] = useState(false);

    const { setActivePageId } = useContext(PdfToolsControlContext);

    const childrenLength = Children.count(children);

    useEffect(() => {
        setIsArrowClicked(false);
    }, [draggable]);

    const goTo = (idx: number) => {
        setCurrent({ currentIndex: idx, currentLength: childrenLength });
    };

    const goToPosition = (position: POSITION) => {
        const { currentIndex } = current;

        if (position === POSITION.NEXT) {
            if (currentIndex === Children.count(children) - 1) {
                setCurrent({ currentIndex: 0, currentLength: childrenLength });
            } else {
                setCurrent({ currentIndex: currentIndex + 1, currentLength: childrenLength });
            }
        } else if (position === POSITION.PREV) {
            if (currentIndex === 0) {
                setCurrent({ currentIndex: Children.count(children) - 1, currentLength: childrenLength });
            } else {
                setCurrent({ currentIndex: currentIndex - 1, currentLength: childrenLength });
            }
        }
    };

    const handleAfterArrowClickChange = (id: string) => {
        if (isArrowClicked) {
            setActivePageId(id);
            setIsArrowClicked(false);
        }
    };

    const handleClick = (position: POSITION, id: string) => {
        if (position === POSITION.CURRENT) {
            setActivePageId(id);
        }
    };

    const handleArrowClick = (position: POSITION) => {
        setIsArrowClicked(true);
        goToPosition(position);
    };

    const next = () => goToPosition(POSITION.NEXT);
    const prev = () => goToPosition(POSITION.PREV);

    const keydownEventListener = (e: any) => {
        if (e.which === 39) {
            return next();
        }
        if (e.which === 37) {
            return prev();
        }
    };

    const getCardClass = (index: number): POSITION => {
        const { currentIndex } = current;

        if (currentIndex === null) {
            return POSITION.HIDDEN;
        }

        if (index === currentIndex) {
            return POSITION.CURRENT;
        }

        if (childrenLength === 1) {
            return POSITION.CURRENT;
        }

        if (index === currentIndex + 1 || (index === 0 && currentIndex === Children.count(children) - 1)) {
            return POSITION.NEXT;
        }

        if (index === currentIndex - 1 || (index === Children.count(children) - 1 && currentIndex === 0)) {
            return POSITION.PREV;
        }

        return POSITION.HIDDEN;
    };

    useEffect(() => {
        if (current.currentLength > childrenLength) {
            return prev();
        } else if (current.currentLength < childrenLength) {
            goTo(childrenLength - 1);
        }
    }, [current.currentLength, childrenLength]);

    const getChildren = () => {
        return Children.map(children, (child, index) => {
            const position = getCardClass(index);

            const childCopy = cloneElement(child as ReactElement<any>, {
                style: {
                    ...GetStyles.CARD,
                    opacity: getOpacity(position),
                    zIndex: getZIndex(position),
                    transform: getTransform(position),
                    transition: "box-shadow 0.5s ease",
                } as CSSProperties,
                onClick: (id: string) => handleClick(position, id),
                afterChange: (id: string) => handleAfterArrowClickChange(id),
                position,
            });
            return <div key={index}>{childCopy}</div>;
        });
    };

    return (
        <>
            <div
                style={{
                    opacity: !draggable ? 1 : 0,
                }}
                className={styles.pageCarouselBtnWrapper}
            >
                <Button
                    shape={"circle"}
                    icon={<ArrowLeftOutlined />}
                    onClick={() => handleArrowClick(POSITION.PREV)}
                    disabled={childrenLength <= 1}
                ></Button>
                <Button
                    shape={"circle"}
                    style={{ marginLeft: "10px" }}
                    icon={<ArrowRightOutlined />}
                    onClick={() => handleArrowClick(POSITION.NEXT)}
                    disabled={childrenLength <= 1}
                ></Button>
            </div>
            <div className={styles.pageCarouselChildWrapper}>
                <div>{getChildren()}</div>
            </div>
        </>
    );
};

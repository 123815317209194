import React from "react";
import {
    CheckSquareOutlined,
    CopyOutlined,
    CreditCardOutlined,
    DeleteOutlined,
    EditOutlined,
    StrikethroughOutlined,
    TagOutlined,
} from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { GQL, Utils } from "@binale-tech/shared";

import { CompanyContext, YearPeriodContext } from "../../../../scripts/context/CompanyContext";
import { UserContext } from "../../../../scripts/context/UserProvider";
import { ContextMenu, ContextMenuItem, ContextMenuSeparator } from "./ContextMenu";
import { FlexRow } from "../../../components/shared/appearance/page/Scaffold";
import { GenericRecordTableItem } from "../../../components/shared/Table/Table";
import { GenericRecordUtils } from "scripts/models/utils/GenericRecordUtils";
import { PaymentsContext } from "scripts/context/PaymentsProvider";
import { Product } from "scripts/core/Product";
import { ProductKeys } from "scripts/models/Product";
import { GenericRecord } from "../../../../scripts/models";
import { ProductAccessUtils } from "../../../../scripts/models/utils/ProductAccessUtils";
import { ReviewDropdown, ReviewTableDropdown } from "@app/views/productSharedComponents/actions/Review/ReviewDropdown";

interface Props {
    targetItems?: GenericRecordTableItem[];
    selectedPeriodEditBound: number;

    canWrite: boolean;
    product: Product;

    onEditItem: (v: GenericRecordTableItem, e?: React.MouseEvent<HTMLDivElement>) => void;
    onCopyItem: (v: GenericRecordTableItem, e?: React.MouseEvent<HTMLDivElement>) => void;
    onDeleteItems: (v: GenericRecordTableItem[], e?: React.MouseEvent<HTMLDivElement>) => void;
    onCancelItems: (v: GenericRecordTableItem[], e?: React.MouseEvent<HTMLDivElement>) => void;
    onAvis: (v: GenericRecordTableItem[], avis: boolean, e?: React.MouseEvent<HTMLDivElement>) => void;
    onPayments: (v: GenericRecordTableItem) => void;
    onColor: (v: GenericRecordTableItem[], color: string) => void;
    onBulkEdit: (v: GenericRecordTableItem[], e?: React.MouseEvent<HTMLDivElement>) => void;
    onReview: (v: GenericRecordTableItem[], review: GenericRecord["review"]) => void;

    acquireHeight?: (h: number) => void;
}

const colors = [
    {
        color: "rgba(255, 203, 49, 1)",
        colorName: "yellow",
    },
    {
        color: "rgba(0, 120, 191, 1)",
        colorName: "blue",
    },
    {
        color: "rgba(237, 27, 53, 1)",
        colorName: "red",
    },
    {
        color: "rgba(142, 71, 156, 1)",
        colorName: "purple",
    },
    {
        color: "rgba(0, 128, 0, 1)",
        colorName: "green",
    },
];

const TableContextMenu: React.FC<Props> = props => {
    const { recordRelation } = React.useContext(PaymentsContext);
    const { year: globalYear } = React.useContext(YearPeriodContext);
    const { yearConfig, companyGQL } = React.useContext(CompanyContext);
    const userCtx = React.useContext(UserContext);

    const { product, targetItems = [], canWrite = false } = props;

    const targetItemsData = React.useMemo(() => {
        let avisAllTrue = true;
        let avisAllFalse = true;
        let avisAllOpen = true;
        let lastschriftAllFalse = true;
        targetItems.forEach(i => {
            if (avisAllOpen && i.item.getOpenBrutto(recordRelation.get(i.item.key)) === 0) {
                avisAllOpen = false;
            }
            if (avisAllFalse && i.item.avis) {
                avisAllFalse = false;
            }
            if (avisAllTrue && !i.item.avis) {
                avisAllTrue = false;
            }
            if (lastschriftAllFalse && i.item.lastschrift) {
                lastschriftAllFalse = false;
            }
        });
        return { avisAllTrue, avisAllFalse, avisAllOpen, lastschriftAllFalse };
    }, [targetItems, recordRelation]);

    const { avisAllTrue, avisAllFalse, lastschriftAllFalse, avisAllOpen } = targetItemsData;
    const avisText = React.useMemo(() => {
        if (avisAllTrue) {
            return "Zalungsavis entfernen";
        }
        if (avisAllFalse) {
            return "Zalungsavis";
        }
        return "Zalungsavis veränderen";
    }, [avisAllTrue, avisAllFalse]);

    const getRecordPermissions = (r: GenericRecord) => {
        return GenericRecordUtils.getManipulationPermissions(
            r,
            canWrite,
            product,
            recordRelation,
            {
                selectedPeriod: props.selectedPeriodEditBound,
                globalYear,
            },
            yearConfig,
            companyGQL,
            userCtx
        );
    };
    const canEditRecords = targetItems.every(t => {
        if (t.extra.isVirtualRecord) {
            return false;
        }
        const res = getRecordPermissions(t.item);

        return !res.edit.disabled;
    });
    const canCopyRecords = targetItems.every(t => {
        if (t.extra.isVirtualRecord) {
            return false;
        }
        const res = getRecordPermissions(t.item);

        return !res.copy.disabled;
    });
    const canDeleteRecords = targetItems.every(t => {
        if (t.extra.isVirtualRecord) {
            return false;
        }
        const res = getRecordPermissions(t.item);
        return !res.remove.disabled;
    });
    const canCancelRecords = targetItems.every(t => {
        if (t.extra.isVirtualRecord) {
            return false;
        }
        const res = getRecordPermissions(t.item);
        return !res.cancel.disabled;
    });

    const getItemsText = (num: number, enabled = true) => (targetItems.length > 1 && enabled ? ` (${num})` : "");

    const copyEnabled = targetItems.length === 1 && canCopyRecords;
    const editEnabled = React.useMemo(() => {
        if (!canEditRecords) {
            return false;
        }
        if (targetItems.length === 1) {
            return true;
        }
        const isBulkEditProductEnabled = product.getConfig().bulkEditFields?.length;
        if (!isBulkEditProductEnabled) {
            return false;
        }
        return true;
    }, [canEditRecords, targetItems, product]);

    const areRecordsPaymentSource = React.useMemo(() => {
        const globalProductName = product.productKey();
        if (globalProductName === ProductKeys.AccountingCommon) {
            return targetItems.every(item => Utils.PaymentUtils.isRecordPaymentSource(item.item.getProductKey()));
        }
        return Utils.PaymentUtils.isRecordPaymentSource(globalProductName as GQL.IProductKey);
    }, [product, targetItems]);

    const areRecordsReviewable = React.useMemo(() => {
        const globalProductName = product.productKey();
        if (globalProductName === ProductKeys.AccountingCommon) {
            return targetItems.every(item =>
                ProductAccessUtils.canReviewRecord(item.item.getProductKey(), companyGQL, userCtx)
            );
        }
        return ProductAccessUtils.canReviewRecord(globalProductName as GQL.IProductKey, companyGQL, userCtx);
    }, [product, targetItems]);

    const isEditColorsEnabled =
        canWrite && targetItems.every(tableItem => !GenericRecordUtils.isAssetsAndColorDisabled(tableItem));

    return (
        <ContextMenu acquireHeight={props.acquireHeight}>
            <ContextMenuItem
                onClick={e => {
                    if (targetItems.length > 1) {
                        props.onBulkEdit(targetItems, e);
                    } else {
                        props.onEditItem(targetItems[0], e);
                    }
                }}
                enabled={editEnabled}
                glyphicon={<EditOutlined />}
                data-testid="context-menu-edit"
            >
                <FormattedMessage id="app.button.edit" />
                {getItemsText(targetItems.length, editEnabled)}
            </ContextMenuItem>

            <ContextMenuItem
                onClick={e => props.onCopyItem(targetItems[0], e)}
                enabled={copyEnabled}
                glyphicon={<CopyOutlined />}
                data-testid="context-menu-copy"
            >
                <FlexRow style={{ width: "100%", justifyContent: "space-between" }}>
                    <div>
                        <FormattedMessage id="app.button.copy" />
                        {getItemsText(targetItems.length, copyEnabled)}
                    </div>
                    <div style={{ color: "#c3c3c3" }}>F8</div>
                </FlexRow>
            </ContextMenuItem>

            <ContextMenuItem
                onClick={e => props.onDeleteItems(targetItems, e)}
                enabled={canDeleteRecords}
                glyphicon={<DeleteOutlined />}
                data-testid="context-menu-delete"
            >
                <FormattedMessage id="app.button.delete" />
                {getItemsText(targetItems.length, canDeleteRecords)}
            </ContextMenuItem>

            <ContextMenuItem
                onClick={e => props.onCancelItems(targetItems, e)}
                enabled={canCancelRecords}
                glyphicon={<StrikethroughOutlined />}
                data-testid="context-menu-cancel"
            >
                <FormattedMessage id="app.button.storno" />
                {getItemsText(targetItems.length, canCancelRecords)}
            </ContextMenuItem>

            <ContextMenuSeparator />

            {areRecordsPaymentSource && (
                <>
                    <ContextMenuItem
                        onClick={() => {
                            if (avisAllTrue) {
                                props.onAvis(targetItems, false);
                            } else if (avisAllFalse) {
                                props.onAvis(targetItems, true);
                            }
                        }}
                        glyphicon={<TagOutlined style={{ color: "#a94442" }} />}
                        enabled={canEditRecords && lastschriftAllFalse && avisAllOpen && (avisAllTrue || avisAllFalse)}
                        data-testid="context-menu-avis"
                    >
                        {avisText}
                        {getItemsText(targetItems.length, canEditRecords && lastschriftAllFalse && avisAllOpen)}
                    </ContextMenuItem>

                    <ContextMenuItem
                        onClick={() => props.onPayments(targetItems[0])}
                        enabled={targetItems.length === 1}
                        glyphicon={<CreditCardOutlined style={{ color: "#3c763d" }} />}
                        data-testid="context-menu-payments"
                    >
                        <FormattedMessage id="app.titles.payments" />
                    </ContextMenuItem>

                    <ContextMenuSeparator />
                </>
            )}

            <ContextMenuItem
                onClick={() => props.onColor(targetItems, null)}
                enabled={isEditColorsEnabled}
                glyphicon={<i className="st-icon-circle-thin" />}
                data-testid="context-menu-remove-color"
            >
                <FormattedMessage id="app.button.color.remove_color" />
                {getItemsText(targetItems.length, canEditRecords)}
            </ContextMenuItem>

            <>
                {colors.map((item, index) => (
                    <ContextMenuItem
                        key={String(index)}
                        onClick={() => props.onColor(targetItems, item.colorName)}
                        enabled={isEditColorsEnabled}
                        glyphicon={<i className="st-icon-circle" style={{ color: item.color }} />}
                        data-testid={`context-menu-color-${item.colorName}`}
                    >
                        <FormattedMessage id={`app.button.color.${item.colorName}`} />
                        {getItemsText(targetItems.length, canEditRecords)}
                    </ContextMenuItem>
                ))}
            </>

            {areRecordsReviewable && (
                <>
                    <ContextMenuSeparator />
                    <ContextMenuItem
                        onClick={e => e.stopPropagation()}
                        enabled
                        glyphicon={<CheckSquareOutlined />}
                        data-testid="context-menu-review"
                    >
                        <ReviewDropdown
                            onClick={review => props.onReview(targetItems, review)}
                            canReviewRecord
                            placement={"top"}
                            trigger={["hover"]}
                        >
                            <div>
                                <FormattedMessage id="app.fields.recordReview" />
                                {getItemsText(targetItems.length, true)}
                            </div>
                        </ReviewDropdown>
                    </ContextMenuItem>
                </>
            )}
        </ContextMenu>
    );
};
export default React.memo(TableContextMenu);

import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { AgGridTable } from "@app/components/shared/AgGridTable";
import { gridOptions } from "./config/gridOptions";
import Container from "@app/components/shared/appearance/page/Container";
import { GQL } from "@binale-tech/shared";
import { ColDef, ColGroupDef, ICellRendererParams } from "ag-grid-community";
import { gql } from "@apollo/client";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import { AgGridReact } from "ag-grid-react";
import { CellActions, CellActionsProps, HeaderActions } from "@inv/components/TableActions/TableActions";
import InvoicePaymentTemplateForm from "./InvoicePaymentTemplateForm";
import { useIntl } from "react-intl";
import { InvoiceFormInputTranslate } from "@inv/types";

const invoicePaymentTemplateDelete = gql`
    mutation invoicePaymentTemplateDelete($input: InvoiceIDDeleteInput!) {
        invoicePaymentTemplateDelete(input: $input)
    }
`;

export const InvoicePaymentTemplateList: FC<{
    templatesList: GQL.IInvoicePaymentTemplate[];
    refetch: () => void;
}> = ({ refetch, templatesList }) => {
    const intl = useIntl();
    const mutator = useGqlMutator();
    const apiRef = useRef<AgGridReact>(null);
    const [formData, setFormData] = useState<GQL.IInvoicePaymentTemplate>();
    const onClose = useCallback(() => {
        setFormData(undefined);
        setTimeout(() => refetch(), 500);
    }, [refetch]);

    const onEdit = useCallback((data: GQL.IInvoicePaymentTemplate) => {
        setFormData(data);
    }, []);
    const onDelete = useCallback(
        (data: GQL.IInvoicePaymentTemplate) => {
            const { id, companyId } = data;
            mutator
                .mutate<"invoicePaymentTemplateDelete", GQL.IInvoiceIdDeleteInput>({
                    mutation: invoicePaymentTemplateDelete,
                    input: { id, companyId },
                })
                .then(() => setTimeout(() => refetch(), 500));
        },
        [mutator, refetch]
    );

    const columns: (ColDef<GQL.IInvoicePaymentTemplate> | ColGroupDef<GQL.IInvoicePaymentTemplate>)[] = useMemo(() => {
        return [
            {
                headerName: intl.formatMessage({ id: InvoiceFormInputTranslate.PAYMENT_TERM }),
                field: "terms",
                width: 580,
                sortable: false,
            },
            {
                headerName: "CreatedAt",
                field: "createdAt",
                width: 200,
                sortable: true,
                comparator: (a, b) => new Date(a).getTime() - new Date(b).getTime(),
            },
            {
                headerComponent: HeaderActions,
                cellRenderer: CellActions,
                cellRendererParams: (params: ICellRendererParams<GQL.IInvoicePaymentTemplate>): CellActionsProps => ({
                    disabled: false,
                    onEdit: () => onEdit(params.data),
                    onDelete: () => onDelete(params.data),
                }),
                field: "actions" as any,
                floatingFilter: false,
                resizable: false,
                suppressMovable: true,
                suppressNavigable: true,
                width: 100,
                pinned: "right",
                sortable: false,
                lockPosition: true,
            },
        ] satisfies ColDef<GQL.IInvoicePaymentTemplate>[];
    }, [onDelete, onEdit]);

    return (
        <>
            <AgGridTable ref={apiRef} columnDefs={columns} gridOptions={gridOptions} rowData={templatesList} />
            <InvoicePaymentTemplateForm onClose={onClose} open={Boolean(formData)} data={formData} />
        </>
    );
};

import { POSITION } from "./constants";
import { CSSProperties } from "react";

export const GetStyles = {
    CONTAINER: {
        position: "relative",
        width: "100%",
        height: "100%",
        margin: 0,
        padding: 0,
    } as CSSProperties,
    CARD: {
        position: "absolute",
        left: "50%",
        top: "50%",
        cursor: "pointer",
    } as CSSProperties,
};

export function getOpacity(position: string): number {
    if (position === POSITION.HIDDEN) {
        return 0;
    }
    return 1;
}

export function getZIndex(position: string) {
    if (position === POSITION.HIDDEN) {
        return 0;
    }
    if (position === POSITION.CURRENT) {
        return 2;
    }
    return 1;
}

function getTranslationDistances(): { prev: string; next: string } {
    return { prev: "-60%", next: "-40%" };
}

export function getTransform(position: string): string {
    const { prev, next } = getTranslationDistances();

    if (position === POSITION.PREV) {
        return `translate(${prev}, -50%) scale(0.9)`;
    }
    if (position === POSITION.NEXT) {
        return `translate(${next}, -50%) scale(0.9)`;
    }

    if (position === POSITION.HIDDEN) {
        return `translate(-50%, -50%) scale(0.5)`;
    }

    return "translate(-50%, -50%)";
}

export function getBoxShadow(position: string): string {
    if (position === POSITION.CURRENT) {
        return "0 0 2px 2px rgb(64, 169, 255)";
    }
    return "unset";
}

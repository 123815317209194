import React, { FC, memo } from "react";
import { InvoiceTableProvider } from "@inv/components/InvoiceFormLineItemsTable/context/InvoiceTableProvider";
import { InvoiceTableBlock } from "@inv/components/InvoiceFormLineItemsTable/InvoiceTableBlock";

const InvoiceTableModule: FC = () => {
    return (
        <InvoiceTableProvider>
            <InvoiceTableBlock />
        </InvoiceTableProvider>
    );
};
export default memo(InvoiceTableModule);

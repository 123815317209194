import React, { FC, useContext, useEffect, useState } from "react";
import { Currency, GQL } from "@binale-tech/shared";
import { Flex, Select } from "antd";
import { InvoiceTableControlContext } from "@inv/components/InvoiceFormLineItemsTable/context/InvoiceTableProvider";
import { FormattedMessage } from "react-intl";
import { InvoiceFormInputTranslate } from "@inv/types";

type TProps = {
    onChange?: (v: string | undefined) => void;
    value?: string;
};

export const InvoiceCurrencySelect: FC<TProps> = ({ value, onChange }) => {
    const [openOptions, setOpenOptions] = useState(false);
    const [selectValue, setSelectValue] = useState<string>(value ?? GQL.ICurrencyCode.Eur);
    const { setCurrency } = useContext(InvoiceTableControlContext);

    useEffect(() => {
        setSelectValue(value ?? GQL.ICurrencyCode.Eur);
    }, [value]);

    const handleChange = (newValue: string) => {
        setSelectValue(newValue);
        setCurrency(newValue);
        onChange?.(newValue);
    };

    return (
        <Flex align={"center"} gap={10} style={{ maxWidth: "200px" }}>
            <div style={{ width: "100%" }}>
                <FormattedMessage id={InvoiceFormInputTranslate.CURRENCY} /> :
            </div>
            <Select
                value={selectValue}
                onChange={handleChange}
                data-testid="select-currency"
                onBlur={() => setOpenOptions(false)}
                onDropdownVisibleChange={setOpenOptions}
                open={openOptions}
                id="currencySelectID"
                options={Currency.CommonCurrencyList.map(code => ({
                    label: code,
                    value: code,
                }))}
            />
        </Flex>
    );
};

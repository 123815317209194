import CompanyQueriesGraphql from "./companyQueries.graphql";
import React from "react";
import { Button, Input, InputRef, Popover, Space } from "antd";
import { EditOutlined, UserOutlined } from "@ant-design/icons";
import { GQL } from "@binale-tech/shared";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";

type Props = {
    currentEmail: string;
    companyId: string;
    onComplete?: () => void;
};
const CompanyUpdateOwner: React.FC<Props> = ({ currentEmail, companyId, onComplete }) => {
    const ref = React.useRef<InputRef>(null);
    const [value, setValue] = React.useState(currentEmail);
    const [loading, setLoading] = React.useState(false);
    const Mutator = useGqlMutator();

    return (
        <div>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setLoading(true);
                    Mutator.mutate<"companySaveMember", GQL.ICompanyUpdateOwnerInput>({
                        throwOnError: true,
                        mutation: CompanyQueriesGraphql.mutationUpdateOwner,
                        input: {
                            email: value,
                            id: companyId,
                        },
                    })
                        .then(() => onComplete())
                        .finally(() => {
                            setLoading(false);
                        });
                }}
            >
                <Space.Compact style={{ width: "100%" }}>
                    <Input
                        ref={ref}
                        prefix={<UserOutlined />}
                        type={"email"}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        onFocus={() => ref.current.select()}
                        disabled={loading}
                    ></Input>
                    <Button
                        type="primary"
                        htmlType={"submit"}
                        disabled={!value || value === currentEmail}
                        loading={loading}
                    >
                        Submit
                    </Button>
                </Space.Compact>
            </form>
        </div>
    );
};

const CompanyUpdateOwnerPopover: React.FC<Props> = props => {
    const [visible, setVisible] = React.useState(false);
    return (
        <Popover
            destroyTooltipOnHide
            title="Update admin"
            trigger="click"
            open={visible}
            onOpenChange={setVisible}
            content={
                <CompanyUpdateOwner
                    {...props}
                    onComplete={() => {
                        setVisible(false);
                        props.onComplete();
                    }}
                />
            }
        >
            <Button shape="circle" icon={<EditOutlined />} />
        </Popover>
    );
};
export default React.memo(CompanyUpdateOwnerPopover);

import { useContext, useMemo } from "react";
import { CompanyContext } from "../../scripts/context/CompanyContext";
import { InvoicesDataContext } from "@inv/context/InvoicesDataProvider";
import { formatDefault } from "@dms/scripts/helpers";
import { transformServicePeriod } from "@inv/scripts/utils/utils";
import { getName } from "i18n-iso-countries";
// eslint-disable-next-line import/no-unresolved
import { ContentColumns, ContentText, TDocumentDefinitions } from "pdfmake/interfaces";
import { createPdf } from "pdfmake/build/pdfmake";
import pdfMakeFonts from "../../scripts/workers/pdf/pdfmake.fonts";
import { getLineItemNetto, InvoiceFormInputTranslate, InvoiceLayout, TGoBLOrgParty } from "@inv/types";
import { useInvoiceCustomer } from "@inv/hooks/useInvoiceCustomer";
import { useInvoiceSupplier } from "@inv/hooks/useInvoiceSupplier";
import { messagesDe } from "../../scripts/intl/translations/de";
import { extractIBAN } from "@banks/scripts/ibantools";
import { bankDataByIBAN } from "bankdata-germany";
import dayjs from "dayjs";

const mmToPoints = (mm: number) => Math.round(mm * 2.83465);

const getOrgPartyContent = (party: TGoBLOrgParty, receiver?: string) => {
    const customerContent: ContentText[] = [];
    if (party.name) {
        customerContent.push({
            text: party.name,
            bold: true,
            marginBottom: 2,
        });
    }
    if (receiver) {
        customerContent.push({
            text: receiver,
            marginBottom: 2,
        });
    }
    if (party.addresses[0].street) {
        customerContent.push({
            text: `${party.addresses[0].street} ${party.addresses[0].num ?? ""}`,
            marginBottom: 2,
        });
    }
    if (party.addresses[0].street_extra) {
        customerContent.push({
            text: party.addresses[0].street_extra ?? "",
            marginBottom: 2,
        });
    }
    if (party.addresses[0].code) {
        customerContent.push({
            text: `${party.addresses[0].code} ${party.addresses[0].locality ?? ""}`,
            marginBottom: 2,
        });
    }
    if (party.addresses[0].country) {
        customerContent.push({ text: getName(party.addresses[0].country, "de"), marginBottom: 2 });
    }
    return customerContent;
};

const invoiceLayoutMm: Record<InvoiceLayout, number> = {
    [InvoiceLayout.TOP]: 10,
    [InvoiceLayout.LEFT]: 20,
    [InvoiceLayout.RIGHT]: 10,
    [InvoiceLayout.BOTTOM]: 10,

    [InvoiceLayout.HEADER_HEIGHT]: 2,
    [InvoiceLayout.FOOTER_HEIGHT]: 10,

    [InvoiceLayout.ADDRESS_TOP]: 35,
    [InvoiceLayout.ADDRESS_LEFT]: 200,
    [InvoiceLayout.ADDRESS_RIGHT]: 40,
    [InvoiceLayout.ADDRESS_HEIGHT]: 45,

    [InvoiceLayout.ADDRESS_WIDTH]: 85,
    [InvoiceLayout.ADDRESS_HEADER_HEIGHT]: 17.7,
    [InvoiceLayout.ADDRESS_CONTENT_HEIGHT]: 27.3,
    [InvoiceLayout.INFO_WIDTH]: 75,

    [InvoiceLayout.INFO_TOP]: 50,
    [InvoiceLayout.INFO_RIGHT]: 10,
    [InvoiceLayout.INFO_LEFT]: 20,

    [InvoiceLayout.CONTENT_SPACING]: 8.4,

    [InvoiceLayout.CONTENT_LEFT]: 20,
    [InvoiceLayout.CONTENT_RIGHT]: 10,

    [InvoiceLayout.CONTENT_WIDTH]: 170,
};

const invoiceLayoutPt: Record<InvoiceLayout, number> = Object.entries(invoiceLayoutMm).reduce(
    (result, [key, value]) => {
        return {
            ...result,
            [key as InvoiceLayout]: mmToPoints(value),
        };
    },
    {} as Record<InvoiceLayout, number>
);

export const useGenerateInvoicePdf = (invoiceId: string): (() => Promise<Buffer>) => {
    const { companyGQL } = useContext(CompanyContext);
    const { invoicesMap } = useContext(InvoicesDataContext);

    const customer = useInvoiceCustomer(invoiceId);
    const supplier = useInvoiceSupplier();

    const { invoiceRegister, IBAN, BIC, bankName, director } = companyGQL;
    const invoiceData = invoicesMap.get(invoiceId);

    const { lineItems, isTaxIncluded } = invoiceData;

    const { subtotal, tax7, tax7base, tax19, tax19base, total } = useMemo(() => {
        let originalAmount = 0;
        let resultTotal = 0;
        let calculateTax7 = 0;
        let calculateTax7base = 0;
        let calculateTax19 = 0;
        let calculateTax19base = 0;

        lineItems.forEach(item => {
            const lineNetto = getLineItemNetto(item);
            const lineTax = lineNetto * (item.tax / 100);
            const lineBrutto = lineNetto + lineTax;

            originalAmount += lineNetto;
            resultTotal += lineBrutto;

            if (item.tax === 7) {
                calculateTax7base += lineNetto;
                calculateTax7 += lineTax;
            } else if (item.tax === 19) {
                calculateTax19base += lineNetto;
                calculateTax19 += lineTax;
            }
        });

        if (!isTaxIncluded) {
            return {
                subtotal: formatDefault(originalAmount),
                tax7: null,
                tax7base: null,
                tax19: null,
                tax19base: null,
                total: null,
            };
        }

        return {
            subtotal: formatDefault(originalAmount),
            tax7: calculateTax7 ? formatDefault(calculateTax7) : null,
            tax7base: calculateTax7base ? formatDefault(calculateTax7base) : null,
            tax19: calculateTax19 ? formatDefault(calculateTax19) : null,
            tax19base: calculateTax19base ? formatDefault(calculateTax19base) : null,
            total: formatDefault(resultTotal),
        };
    }, [isTaxIncluded, lineItems]);

    const serviceDate = useMemo(() => {
        const servicePeriodDaysValue = invoiceData?.servicePeriodDays
            ? transformServicePeriod(invoiceData?.servicePeriodDays)
            : null;
        const servicePeriodMonthsValue = invoiceData?.servicePeriodMonths
            ? transformServicePeriod(invoiceData?.servicePeriodMonths)
            : null;

        return (
            invoiceData?.serviceDate ?? invoiceData?.deliveryDate ?? servicePeriodDaysValue ?? servicePeriodMonthsValue
        );
    }, [invoiceData]);

    const supplierContent = getOrgPartyContent(supplier);
    const customerContent = getOrgPartyContent(customer, invoiceData.customerReceiverName);
    const ibanData = extractIBAN(IBAN);
    const bankData = ibanData.countryCode === "DE" ? bankDataByIBAN(IBAN) : null;

    const tableLineItemsWidths = [10, "*", 50, 70, 70].concat(isTaxIncluded ? [30] : []);
    const tableLineItems = [
        [
            { text: " " },
            { text: messagesDe[InvoiceFormInputTranslate.NAME], alignment: "center" },
            // { text: messagesDe[InvoiceFormInputTranslate.UNIT], alignment: "center" },
            { text: messagesDe[InvoiceFormInputTranslate.QUANTITY], alignment: "center" },
            {
                text: `${messagesDe[InvoiceFormInputTranslate.PRICE]} (${invoiceData.currencyCode})`,
                alignment: "center",
            },
            {
                text: `${messagesDe["app.fields.netto"]} (${invoiceData.currencyCode})`,
                alignment: "center",
            },
            ...(isTaxIncluded
                ? [
                      {
                          text: `${messagesDe[InvoiceFormInputTranslate.TAX]}`,
                          alignment: "center",
                      },
                  ]
                : []),
        ].filter(Boolean),
        ...lineItems
            .map((item, index) => {
                const getUnitName = () => {
                    if (item.unit === "PIECE") {
                        return messagesDe[InvoiceFormInputTranslate.PIECE];
                    }
                    if (item.unit === "HOUR") {
                        return messagesDe[InvoiceFormInputTranslate.HOUR];
                    }
                    if (item.unit === "MONTH") {
                        return messagesDe["app.fields.date.month"];
                    }
                    return "";
                };
                return [
                    { text: String(index + 1) },
                    { text: item.name },
                    { text: [String(item.quantity), getUnitName()].filter(Boolean).join(" "), alignment: "right" },
                    { text: formatDefault(item.price), alignment: "right" },
                    { text: formatDefault(getLineItemNetto(item)), alignment: "right" },
                    ...(isTaxIncluded ? [{ text: item.tax + "%", alignment: "right" }] : []),
                ];
            })
            .filter(Boolean),
    ];

    const tableTotal = [
        subtotal
            ? [
                  {
                      text: `${messagesDe[InvoiceFormInputTranslate.TOTAL_AMOUNT]} (Netto)`,
                  },
                  { text: subtotal ?? "", alignment: "right" },
              ]
            : null,
        tax7 ? [`zzgl. Umsatzsteuer 7% (${tax7base})`, { text: tax7 ?? "", alignment: "right" }] : null,
        tax19 ? [`zzgl. Umsatzsteuer 19% (${tax19base})`, { text: tax19 ?? "", alignment: "right" }] : null,
        total
            ? [
                  {
                      text: `${messagesDe[InvoiceFormInputTranslate.TOTAL_AMOUNT]} (Brutto)`,
                      bold: true,
                  },
                  { text: total ?? "", alignment: "right", bold: true },
              ]
            : null,
    ].filter(Boolean);

    return () => {
        const date = invoiceData.date ? dayjs(invoiceData.date, "DD.MM.YYYY").toDate() : new Date();
        const docDefinition: TDocumentDefinitions = {
            version: "1.4",
            subset: "PDF/A-3",
            pageSize: "A4",
            info: {
                creator: supplier.name,
                producer: "Binale",
                creationDate: date,
                modDate: date,
            },
            pageMargins: [
                invoiceLayoutPt[InvoiceLayout.LEFT],
                invoiceLayoutPt[InvoiceLayout.TOP] +
                    invoiceLayoutPt[InvoiceLayout.HEADER_HEIGHT] +
                    invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                invoiceLayoutPt[InvoiceLayout.RIGHT],
                invoiceLayoutPt[InvoiceLayout.BOTTOM] +
                    invoiceLayoutPt[InvoiceLayout.FOOTER_HEIGHT] +
                    invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
            ],
            header(currentPage, pageCount) {
                if (pageCount === 1) {
                    return null;
                }
                return {
                    text: `Seite: ${currentPage.toString()} / ${pageCount}`,
                    marginTop: invoiceLayoutPt[InvoiceLayout.TOP],
                    marginRight: invoiceLayoutPt[InvoiceLayout.RIGHT],
                    alignment: "right",
                    fontSize: 8,
                };
            },
            footer: [
                {
                    fontSize: 7,
                    opacity: 0.7,
                    columnGap: 10,
                    lineHeight: 1.2,
                    marginTop: invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                    marginLeft: invoiceLayoutPt[InvoiceLayout.LEFT],
                    columns: [
                        {
                            stack: [
                                companyGQL.invoiceHeadquarter
                                    ? `Sitz der Gesellschaft: ${companyGQL.invoiceHeadquarter}`
                                    : null,
                                invoiceRegister
                                    ? `HR: ${[invoiceRegister.court, invoiceRegister.type, invoiceRegister.number].filter(Boolean).join(" ").replace("Amtsgericht", "AG")}`
                                    : null,
                                supplier.tax_id
                                    ? `USt-IDNr (VAT): ${supplier.tax_id.country}${supplier.tax_id.code}`
                                    : null,
                            ].filter(Boolean),
                        },
                        {
                            stack: [
                                (bankName ?? bankData?.bankName)
                                    ? `Kreditinstitut: ${bankName ?? bankData?.bankName}`
                                    : null,
                                IBAN ? `IBAN: ${IBAN}` : null,
                                (BIC ?? bankData?.bic) ? `BIC/SWIFT: ${BIC ?? bankData?.bic}` : null,
                            ].filter(Boolean),
                        },
                        // {
                        //     stack: ["Komplementär:", `${supplier.name}, ${invoiceAddress.city}`],
                        // },
                        {
                            stack: director ? ["Geschäftsführer:", director] : [],
                        },
                    ],
                },
            ],
            content: [
                {
                    absolutePosition: { x: invoiceLayoutPt[InvoiceLayout.LEFT], y: invoiceLayoutPt[InvoiceLayout.TOP] },
                    ...(companyGQL?.logo ? { image: companyGQL.logo, fit: [90, 200] } : { text: "" }),
                },
                {
                    columns: [
                        {
                            marginTop: invoiceLayoutPt[InvoiceLayout.ADDRESS_TOP],
                            width: invoiceLayoutPt[InvoiceLayout.ADDRESS_WIDTH],
                            height: invoiceLayoutPt[InvoiceLayout.ADDRESS_HEIGHT],
                            stack: [
                                {
                                    stack: customerContent,
                                    fontSize: 9,
                                },
                            ],
                        },
                        {
                            marginLeft: invoiceLayoutPt[InvoiceLayout.INFO_LEFT],
                            height: invoiceLayoutPt[InvoiceLayout.ADDRESS_HEIGHT],
                            marginBottom: invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                            stack: [
                                {
                                    opacity: 0.7,
                                    fontSize: 8,
                                    columnGap: 10,
                                    lineHeight: 1.1,
                                    marginBottom: invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                                    columns: [
                                        {
                                            text: "Anschrift",
                                            height: invoiceLayoutPt[InvoiceLayout.ADDRESS_HEADER_HEIGHT],
                                            alignment: "right",
                                        },
                                        {
                                            stack: supplierContent,
                                            height: invoiceLayoutPt[InvoiceLayout.ADDRESS_HEADER_HEIGHT],
                                        },
                                    ],
                                },
                                {
                                    opacity: 0.7,
                                    fontSize: 8,
                                    columnGap: 10,
                                    lineHeight: 1.1,
                                    marginBottom: invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                                    columns: supplier.emails.length
                                        ? [
                                              {
                                                  text: "E-Mail",
                                                  height: invoiceLayoutPt[InvoiceLayout.ADDRESS_HEADER_HEIGHT],
                                                  alignment: "right",
                                              },
                                              {
                                                  text: supplier.emails[0].addr,
                                                  height: invoiceLayoutPt[InvoiceLayout.ADDRESS_HEADER_HEIGHT],
                                              },
                                          ]
                                        : null,
                                },
                                {
                                    columnGap: 10,
                                    fontSize: 8,
                                    columns: [
                                        {
                                            lineHeight: 1.1,
                                            alignment: "right",
                                            stack: [
                                                {
                                                    text: "Rechnungsnummer",
                                                    bold: true,
                                                } satisfies ContentText,
                                                { text: "Rechnungsdatum" },
                                                { text: "Leistungsdatum" },
                                            ],
                                        },
                                        {
                                            lineHeight: 1.1,
                                            stack: [
                                                {
                                                    text: invoiceData.invoiceNumber ?? "ENTWURF",
                                                    bold: true,
                                                },
                                                {
                                                    text: invoiceData.date ?? " ",
                                                },
                                                {
                                                    text: serviceDate ? serviceDate : " ",
                                                },
                                            ] satisfies ContentText[],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                invoiceData.documentTitle
                    ? {
                          fontSize: 9,
                          text: invoiceData.documentTitle,
                          marginTop: invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                      }
                    : null,
                invoiceData.introductionText
                    ? {
                          fontSize: 9,
                          text: invoiceData.introductionText,
                          marginTop: invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                      }
                    : null,
                {
                    marginTop: invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                    width: invoiceLayoutPt[InvoiceLayout.CONTENT_WIDTH],
                    stack: [
                        {
                            fontSize: 9,
                            marginBottom: 10,
                            table: {
                                headerRows: 1,
                                widths: tableLineItemsWidths,
                                body: tableLineItems,
                            },
                            layout: {
                                hLineWidth(i, node) {
                                    if (i === 0) {
                                        return 0;
                                    }
                                    return i === node.table.headerRows || i === node.table.body.length ? 1 : 0.5;
                                },
                                vLineWidth(i) {
                                    return 0;
                                },
                                hLineColor(i, node) {
                                    return i === node.table.headerRows || i === node.table.body.length
                                        ? "#555"
                                        : "#888";
                                },
                                paddingLeft(i) {
                                    return i === 0 ? 0 : 8;
                                },
                                paddingRight(i, node) {
                                    return i === node.table.widths.length - 1 ? 0 : 8;
                                },
                            },
                        },
                        {
                            fontSize: 9,
                            marginTop: 20,
                            // bold: true,
                            marginBottom: invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                            columns: [
                                { text: "", width: "*" },
                                {
                                    table: {
                                        headerRows: 1,
                                        widths: ["*", 70],
                                        body: tableTotal,
                                    },
                                    // layout: "lightHorizontalLines",
                                    layout: {
                                        hLineWidth(i, node) {
                                            return i === 0 || i === node.table.body.length ? 1 : 0;
                                        },
                                        vLineWidth(i, node) {
                                            return i === 0 || i === node.table.widths.length ? 1 : 0;
                                        },
                                        hLineColor(i, node) {
                                            return "#555";
                                        },
                                        paddingLeft(i) {
                                            return 8;
                                        },
                                        paddingRight(i, node) {
                                            return 8;
                                        },
                                    },
                                },
                            ],
                        } satisfies ContentColumns,
                        invoiceData.paymentTerm
                            ? {
                                  fontSize: 9,
                                  text: invoiceData.paymentTerm,
                                  marginBottom: invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                              }
                            : null,
                        invoiceData.description
                            ? {
                                  fontSize: 9,
                                  text: invoiceData.description,
                                  marginBottom: invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                              }
                            : null,
                        // {
                        //     fontSize: 10,
                        //     marginBottom: invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                        //     bold: true,
                        //     alignment: "left",
                        //     columns: [
                        //         {
                        //             marginRight: 10,
                        //             width: "auto",
                        //             alignment: "left",
                        //             stack: [
                        //                 {
                        //                     text: "Empfänger:",
                        //                     marginBottom: 2,
                        //                 },
                        //                 {
                        //                     text: "Kreditinstitut:",
                        //                     marginBottom: 2,
                        //                 },
                        //                 {
                        //                     text: "IBAN:",
                        //                     marginBottom: 2,
                        //                 },
                        //                 {
                        //                     text: "BIC/SWIFT:",
                        //                     marginBottom: 2,
                        //                 },
                        //             ],
                        //         },
                        //         {
                        //             alignment: "left",
                        //             width: "*",
                        //             stack: [
                        //                 {
                        //                     text: "Binale GmbH & Co. KG",
                        //                     marginBottom: 2,
                        //                 },
                        //                 {
                        //                     text: "Olinda",
                        //                     marginBottom: 2,
                        //                 },
                        //                 {
                        //                     text: "DE60 1001 0123 3396 3165 30",
                        //                     marginBottom: 2,
                        //                 },
                        //                 {
                        //                     text: "QNTODEB2XXX",
                        //                     marginBottom: 2,
                        //                 },
                        //             ],
                        //         },
                        //     ],
                        // },
                    ],
                },
            ],
        };
        return new Promise<Buffer>(resolve => {
            createPdf(docDefinition, null, null, pdfMakeFonts).getBuffer(b => {
                resolve(b);
            });
        });
    };
};

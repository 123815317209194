import { GQL } from "@binale-tech/shared";
import { ReactNode } from "react";
import { InvoiceColumns, InvoicesListColumn } from "@inv/types/enums";

export type TInvoicesValues = Omit<GQL.IInvoice, "__typename" | "createdAt" | "updatedAt" | "createdBy" | "companyId">;

export type ContactAddressFields = Pick<
    TInvoicesValues,
    "address" | "street" | "houseNumber" | "countryCode" | "city" | "zipCode"
>;
export type TContactFormFields = ContactAddressFields &
    Pick<
        TInvoicesValues,
        | "customerNumber"
        | "customerReceiverName"
        | "currencyCode"
        | "customerEmail"
        | "customerName"
        | "customerVat"
        | "customerTax"
    >;
export type TInvoiceFormColumnsConfig = Record<InvoiceColumns, boolean>;

export type TInvoicesListColumnsConfig = Record<InvoicesListColumn, boolean>;

export type TInvoiceFormLineItem = Omit<GQL.IInvoiceLineItem, "__typename">;

export const getLineItemNetto = (item: TInvoiceFormLineItem) => {
    if (!item) {
        return 0;
    }
    return item.quantity * item.price - item.discount;
};

export interface TSelectEditorValue {
    value: number | string;
    label: string;
}

export interface TInvoiceRowData extends Omit<TInvoiceFormLineItem, "unit" | "tax"> {
    drag: ReactNode;
    postion: number;
    product: string;
    quantity: number;
    unit: TSelectEditorValue;
    price: number;
    tax: TSelectEditorValue;
    discount: number;
    total: number;
}

export interface TTotalState {
    subtotal: string;
    tax0: string;
    tax7: string;
    tax19: string;
    amount7: string;
    amount19: string;
    total: string;
}

export interface TInvoicesListColumns {
    invoiceDate: string;
    invoiceNumber: string;
    serviceDescription: string;
    contact: string;
    project: string;
    serviceDate: string;
    originalAmount: string;
    currency: string;
    currencyRate: string;
    amountNetto: string;
    vatRate: string;
    vat: string;
    amountBrutto: string;
    isConfirmed: boolean;
}

export interface TInvoiceTableItem
    extends Omit<
        TInvoicesListColumns,
        "originalAmount" | "currencyRate" | "amountNetto" | "vatRate" | "vat" | "amountBrutto"
    > {
    id: string;
    generalTax: number;
    generalTaxRate: string;
    originalAmount: number;
    isTaxIncluded: boolean;
    isExpanded: boolean;
    parentId?: string;
    isChildRow?: boolean;
    currencyRate: number;
    amountNetto?: number;
    vat?: number;
    amountBrutto?: number;
    lineItemsList: TInvoiceFormLineItem[];
}

export type TGoBLOrgParty = {
    name: string;
    tax_id?: {
        country: string;
        code: string;
        type?: string;
    };
    addresses: {
        num: string;
        street: string;
        locality: string;
        code: string;
        country: string;
        street_extra?: string; // line 2 / addresszusatz
    }[];
    emails: { addr: string }[];
    people?: { name?: { given: string }; emails: { addr: string }[] }[];
    telephones: { num: string }[];
};

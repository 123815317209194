import { IDecoder } from "./IDecoder";
import { convertXML } from "simple-xml-to-json";
import { DecoderModern } from "./DecoderModern";
import { DecoderVoid } from "./DecoderVoid";
import { DecoderLegacy } from "./DecoderLegacy";
import { GQL } from "@binale-tech/shared";

export class CIIDecoderFactory {
    private readonly decoder: IDecoder;

    constructor(xmlString: string, pk: GQL.IProductKey) {
        const decodedXml = convertXML(xmlString);
        if (!decodedXml) {
            this.decoder = new DecoderVoid();
        } else if ("rsm:CrossIndustryInvoice" in decodedXml) {
            this.decoder = new DecoderModern(decodedXml["rsm:CrossIndustryInvoice"].children, pk);
        } else if ("rsm:CrossIndustryDocument" in decodedXml) {
            this.decoder = new DecoderLegacy(decodedXml["rsm:CrossIndustryDocument"].children, pk);
        } else {
            this.decoder = new DecoderVoid();
        }
    }

    getDecoder() {
        return this.decoder;
    }
}

import React, { useContext, useEffect } from "react";
import { Checkbox, Form } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import { RechnungInputBlock } from "@ui-components/RechnungInputBlock";

import { Bu } from "@binale-tech/shared";
import { Buchungstext2InputBlock, BuchungstextInputBlock } from "appearance/components/shared/form/FormBlocks";
import { Category, Creditor, Debitor, Tag } from "scripts/models";
import {
    CategoryCombobox,
    CombinedCombobox,
    CreditorCombobox,
    DebitorCombobox,
    Selection13b,
    TagCombobox,
    UstCombobox,
} from "appearance/components/shared/form/baseComponents";
import { CategoryCreditorModes, GenericRecordProperties } from "scripts/core/Product";
import { ContactInputBlock } from "../../form/FormBlocks/ContactInputBlock";
import { IBulkForm } from "./helpers";
import { TableViewContext } from "../../../../../scripts/context/tableViewContext/tableViewContext";
import { useCombinedCCDList } from "../../../../../scripts/infrastructure/hooks";
import { useValidationReactHookForm } from "scripts/infrastructure/helpers/validation";

interface Props {
    itemKey: GenericRecordProperties;
    checked: boolean;
    label: React.ReactNode;
    onToggle: (editingEnabled: boolean) => void;
    disabled?: boolean;
}

const GenericRecordKeysToFieldNames: Record<string, keyof IBulkForm> = {
    [GenericRecordProperties.RecordBelegfeld1]: "recordNum",
    [GenericRecordProperties.ItemBelegfeld2]: "belegfeld2",
    [GenericRecordProperties.ItemBuchungstext]: "itemText",
    [GenericRecordProperties.ItemBuchungstext2]: "itemText2",
    [GenericRecordProperties.ItemKS]: "tag",
    [GenericRecordProperties.RecordContact]: "contact",
    [GenericRecordProperties.RecordCategoryCreditorNum]: "konto",
    [GenericRecordProperties.ItemCategoryCreditorNum]: "itemAccount",
    [GenericRecordProperties.ItemUStPerc]: "itemUSt",
    [GenericRecordProperties.ItemUSt13b]: "itemUSt13b",
};

export const BulkFormItem: React.FC<Props> = ({ checked, itemKey, label, onToggle, disabled }) => {
    const { control, formState, setValue, trigger, watch } = useFormContext<IBulkForm>();
    const validation = useValidationReactHookForm();
    const { product } = useContext(TableViewContext);
    const { combinedCCDList, defaultCategoriesFiltered } = useCombinedCCDList();

    // need for triggerring validation if field was disabled
    useEffect(() => {
        if (!checked) {
            trigger(GenericRecordKeysToFieldNames[itemKey as string]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked]);

    const requiredRules = {
        required: {
            ...validation.required,
            value: checked,
        },
    };

    const isItemAccountIncludesCategory = [CategoryCreditorModes.CAT, CategoryCreditorModes.CCD].includes(
        product.getConfig().itemAccountMode
    );

    const itemAccount = watch("itemAccount") as Category;
    const itemUSt = watch("itemUSt");
    const isCheckboxDisabled = () => {
        if (disabled) {
            return true;
        }
        if (formState.isSubmitSuccessful) {
            return true;
        }
        if (
            itemKey === GenericRecordProperties.RecordCategoryCreditorNum &&
            product.getConfig().recordAccountMode === CategoryCreditorModes.RESOLVER
        ) {
            return true;
        }
        if (
            itemKey === GenericRecordProperties.ItemCategoryCreditorNum &&
            product.getConfig().itemAccountMode === CategoryCreditorModes.RESOLVER
        ) {
            return true;
        }
        if (itemKey === GenericRecordProperties.ItemUSt13b) {
            return true;
        }
        if (GenericRecordProperties.ItemUStPerc === itemKey) {
            if (!isItemAccountIncludesCategory) {
                return true;
            }
            if (!itemAccount) {
                return true;
            }
            if (itemAccount instanceof Category) {
                return itemAccount.isAutoBu();
            } else {
                return true;
            }
        }
        return false;
    };

    return (
        <>
            <div style={{ width: 150 }}>
                <Checkbox
                    checked={checked}
                    style={{ marginRight: 8 }}
                    disabled={isCheckboxDisabled()}
                    onChange={e => {
                        const isChecked = e.target.checked;
                        setValue(GenericRecordKeysToFieldNames[itemKey as string], "");
                        onToggle(isChecked);
                    }}
                >
                    {label}
                </Checkbox>
            </div>

            {itemKey === GenericRecordProperties.RecordCategoryCreditorNum && (
                <Controller
                    name="konto"
                    control={control}
                    rules={requiredRules}
                    render={({ field: { ref, ...rest } }) => {
                        if (product.getConfig().recordAccountMode === CategoryCreditorModes.CRED) {
                            return (
                                <CreditorCombobox
                                    {...rest}
                                    disabled={!checked}
                                    value={rest.value ? Creditor.unserialize(rest.value) : undefined}
                                    onChange={value => rest.onChange(value ?? null)}
                                />
                            );
                        }
                        if (product.getConfig().recordAccountMode === CategoryCreditorModes.DEB) {
                            return (
                                <DebitorCombobox
                                    {...rest}
                                    disabled={!checked}
                                    value={rest.value ? Debitor.unserialize(rest.value) : undefined}
                                    onChange={value => rest.onChange(value ?? null)}
                                />
                            );
                        }
                        return null;
                    }}
                />
            )}

            {itemKey === GenericRecordProperties.ItemCategoryCreditorNum && (
                <Controller
                    name="itemAccount"
                    control={control}
                    rules={requiredRules}
                    render={({ field: { ref, ...rest } }) => {
                        if (product.getConfig().itemAccountMode === CategoryCreditorModes.CAT) {
                            console.log(product.getConfig().itemAccountMode);
                            return (
                                <CategoryCombobox
                                    {...rest}
                                    disabled={!checked}
                                    value={rest.value ? Category.unserialize(rest.value as Category) : undefined}
                                    onChange={value => {
                                        rest.onChange(value ?? null);
                                    }}
                                />
                            );
                        }

                        if (product.getConfig().itemAccountMode === CategoryCreditorModes.CCD) {
                            return (
                                <CombinedCombobox
                                    {...rest}
                                    disabled={!checked}
                                    value={rest.value ? rest.value : undefined}
                                    items={combinedCCDList}
                                    altItems={defaultCategoriesFiltered}
                                    onChange={value => rest.onChange(value ?? null)}
                                />
                            );
                        }
                        return null;
                    }}
                />
            )}
            {itemKey === GenericRecordProperties.ItemUStPerc && isItemAccountIncludesCategory && (
                <Controller
                    name="itemUSt"
                    control={control}
                    rules={requiredRules}
                    render={({ field: { ref, ...rest } }) => {
                        return (
                            <Form.Item style={{ minWidth: 200 }}>
                                <UstCombobox
                                    {...rest}
                                    disabled={!checked}
                                    value={rest.value}
                                    category={itemAccount instanceof Category ? itemAccount : undefined}
                                    onChange={value => {
                                        rest.onChange(value);
                                    }}
                                />
                            </Form.Item>
                        );
                    }}
                />
            )}
            {itemKey === GenericRecordProperties.ItemUSt13b && isItemAccountIncludesCategory && (
                <Controller
                    name="itemUSt13b"
                    control={control}
                    rules={requiredRules}
                    render={({ field: { ref, ...rest } }) => {
                        const getIsDisabled = () => {
                            if (!checked) {
                                return true;
                            }
                            if (!Bu.getUst13bData(itemUSt).options) {
                                return true;
                            }
                            if (itemAccount?.sv13b) {
                                return true;
                            }
                            return false;
                        };
                        return (
                            <Form.Item style={{ minWidth: 200 }}>
                                <Selection13b disabled={getIsDisabled()} bu={itemUSt} {...rest} />
                            </Form.Item>
                        );
                    }}
                />
            )}
            {itemKey === GenericRecordProperties.RecordContact && (
                <Controller
                    name="contact"
                    control={control}
                    rules={requiredRules}
                    render={({ field: { ref, ...rest } }) => (
                        <ContactInputBlock
                            ref={ref}
                            {...rest}
                            disabled={!checked}
                            value={rest.value}
                            onChange={value => rest.onChange(value ?? null)}
                            hideFieldLabel
                        />
                    )}
                />
            )}
            {itemKey === GenericRecordProperties.RecordBelegfeld1 && (
                <Controller
                    name="recordNum"
                    control={control}
                    rules={requiredRules}
                    render={({ field, fieldState: { error } }) => (
                        <RechnungInputBlock
                            {...field}
                            showCount
                            disabled={!checked}
                            labelProps={{
                                validateStatus: error ? "error" : null,
                                help: error?.message,
                                label: null,
                                style: { width: 400 },
                            }}
                        />
                    )}
                />
            )}
            {itemKey === GenericRecordProperties.ItemBelegfeld2 && (
                <Controller
                    name="belegfeld2"
                    control={control}
                    render={({ field }) => (
                        <RechnungInputBlock
                            {...field}
                            labelProps={{ label: null, style: { width: 400 } }}
                            showCount
                            disabled={!checked}
                        />
                    )}
                />
            )}
            {itemKey === GenericRecordProperties.ItemBuchungstext && (
                <Controller
                    control={control}
                    name="itemText"
                    render={({ field }) => (
                        <BuchungstextInputBlock
                            {...field}
                            disabled={!checked}
                            labelProps={{ label: null, style: { width: 600 } }}
                        />
                    )}
                />
            )}
            {itemKey === GenericRecordProperties.ItemBuchungstext2 && (
                <Controller
                    control={control}
                    name="itemText2"
                    render={({ field }) => (
                        <Buchungstext2InputBlock
                            {...field}
                            disabled={!checked}
                            inputProps={{ showCount: true }}
                            labelProps={{ label: null, style: { width: 750 } }}
                        />
                    )}
                />
            )}
            {itemKey === GenericRecordProperties.ItemKS && (
                <Controller
                    control={control}
                    name="tag"
                    render={({ field: { ref, ...restField } }) => (
                        <TagCombobox
                            {...restField}
                            disabled={!checked}
                            value={restField.value ? Tag.unserialize(restField.value) : undefined}
                            onChange={value => restField.onChange(value ?? null)}
                        />
                    )}
                />
            )}
        </>
    );
};

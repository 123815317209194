import { Empty, Layout } from "antd";
import React, { type FC, type PropsWithChildren } from "react";
import { Route, Routes } from "react-router-dom";
import { CreateInvoicePage, InvoicesListPage, EditInvoicePage, InvoiceFormatsListPage } from "./pages";

import "./InvoicesApp.css";
import { InvoicingPaths } from "@inv/types";
import { InvoiceModalModule } from "@inv/modules/InvoiceModalModule";
import { InvoiceLineItemTemplateListPage } from "@inv/pages/InvoiceLineItemTemplateListPage";
import { InvoicePaymentTemplateListPage } from "@inv/pages/InvoicePaymentTemplateListPage";

const AppLayout: FC<PropsWithChildren> = ({ children }) => (
    <Layout
        style={{
            position: "relative",
            height: "100%",
        }}
    >
        <Layout.Content
            style={{
                padding: "15px",
                position: "relative",
                height: "100%",
            }}
        >
            {children}
        </Layout.Content>
    </Layout>
);

const InvoicesApp: FC = () => {
    return (
        <AppLayout>
            <Routes>
                <Route index element={<InvoicesListPage />} />
                <Route path={InvoicingPaths.FORM} element={<CreateInvoicePage />} />
                <Route path={`${InvoicingPaths.EDITING}/:id`} element={<EditInvoicePage />} />
                <Route path={InvoicingPaths.FORMATS} element={<InvoiceFormatsListPage />} />
                <Route path={InvoicingPaths.LINE_ITEMS_TEMPLATES} element={<InvoiceLineItemTemplateListPage />} />
                <Route path={InvoicingPaths.PAYMENT_TEMPLATES} element={<InvoicePaymentTemplateListPage />} />
                <Route path="*" element={<Empty />} />
            </Routes>
            <InvoiceModalModule />
        </AppLayout>
    );
};

export default InvoicesApp;

import React, { useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FieldLabel } from "@ui-components/FieldLabel";
import { Divider, Form, Row, Select, Space, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";

import { BaseInput } from "appearance/components/shared/form/baseComponents";
import { Contacts, GQL } from "@binale-tech/shared";
import { CountriesCombobox } from "../../components/CountriesCombobox";
import { RegisterTypeCombobox } from "../../components/RegisterTypeCombobox";
import { useCustomValidation, useLegalInfoValidation } from "../utils";
import { useValidationReactHookForm } from "scripts/infrastructure/helpers/validation";
import { useEuroCountryArray } from "../../../../../scripts/infrastructure/hooks/useEuroCountryArray";
import { validateInput } from "../../../../../scripts/infrastructure/helpers/validateVatInput";
import { InfoCircleOutlined, LoadingOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { GermanStates } from "@app/components/company/CompanyInvoiceData";
import { RegisterCourtCombobox } from "@app/views/productContacts/components/RegisterCourtCombobox";
import { CompanyContext } from "../../../../../scripts/context/CompanyContext";

interface Props {
    item: Contacts.Contact | null;
}

export const LegalInformation = React.memo<Props>(function LegalInformation({ item }) {
    const { companyGQL } = useContext(CompanyContext);
    const { watch, setValue, setError, clearErrors } = useFormContext<Contacts.Contact>();
    const validation = useValidationReactHookForm();
    const legalInfo = watch("legalInfo");
    const type = watch("type");
    const customValidators = useCustomValidation(item);
    const legalValidators = useLegalInfoValidation(item);
    const euroCountriesArray = useEuroCountryArray();

    return (
        <>
            <Divider orientation="left">
                DE <FormattedMessage id={"app.titles.legalInformation"} />
            </Divider>
            <Row>
                <Space.Compact>
                    <Controller
                        name="legalInfo.tax.bundesland"
                        render={({ field, fieldState }) => {
                            return (
                                <FieldLabel fieldError={fieldState.error} label={"Bundesland"} style={{ width: 100 }}>
                                    <Select
                                        options={GermanStates}
                                        {...field}
                                        onChange={value => {
                                            if (!value) {
                                                setValue("legalInfo.tax.bundesland", "");
                                            }
                                            field.onChange(value);
                                        }}
                                    />
                                </FieldLabel>
                            );
                        }}
                    />
                    <Controller
                        name="legalInfo.tax.stnr"
                        rules={{
                            validate: {
                                unique: customValidators.taxNumberUnique,
                                valid: customValidators.taxNumberValid,
                            },
                            deps: ["legalInfo.tax.bundesland"],
                        }}
                        render={({ field, fieldState }) => {
                            return (
                                <>
                                    <FieldLabel
                                        fieldError={fieldState.error}
                                        label={
                                            <Space>
                                                <FormattedMessage id="app.fields.taxNumber" />
                                                <Tooltip title={<FormattedMessage id="app.global.onlyNumbersHint" />}>
                                                    <InfoCircleOutlined />
                                                </Tooltip>
                                            </Space>
                                        }
                                        style={{ maxWidth: 170 }}
                                    >
                                        <BaseInput
                                            {...field}
                                            showCount
                                            minLength={10}
                                            maxLength={11}
                                            pattern="^[0-9]*$"
                                            data-testid="legalInfo-taxNumber"
                                            disabled={field.disabled}
                                        />
                                    </FieldLabel>
                                    <Form.Item label={" "} style={{ marginLeft: 8, width: 100 }}>
                                        {fieldState.isValidating ? (
                                            <div>
                                                <LoadingOutlined />
                                            </div>
                                        ) : null}
                                    </Form.Item>
                                </>
                            );
                        }}
                    />
                </Space.Compact>
                <Controller
                    name={"legalInfo.IDNr" as never}
                    // todo: there is some problem with reseting validation after type change
                    rules={{
                        validate: customValidators.IDNr,
                        minLength: validation.strict(11),
                        deps: ["type"],
                    }}
                    shouldUnregister
                    render={({ field, fieldState }) => (
                        <FieldLabel
                            label={
                                <Space>
                                    <span>Personal IdNr</span>
                                    <Tooltip title={'nur für den "natürliche Person" Typ verfügbar'}>
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </Space>
                            }
                            style={{ maxWidth: 140 }}
                            fieldError={fieldState.error}
                        >
                            <BaseInput
                                {...field}
                                data-testid="legalInfo-IDNr"
                                disabled={field.disabled || type !== GQL.IContactType.Individual}
                                pattern="^[0-9]*$"
                                maxLength={11}
                                showCount
                            />
                        </FieldLabel>
                    )}
                />
            </Row>
            <Divider orientation="left">DE Handelsregister</Divider>
            <Row>
                <Space align="start">
                    <Controller
                        name="legalInfo.register.type"
                        rules={{
                            validate: legalValidators.register,
                            deps: ["legalInfo.register.court", "legalInfo.register.number"],
                        }}
                        render={({ field, fieldState }) => (
                            <FieldLabel
                                fieldError={fieldState.error}
                                label={<FormattedMessage id="app.fields.typeOfRegister" />}
                                style={{ minWidth: 280 }}
                                data-testid="legalInfo-typeOfRegister"
                            >
                                <RegisterTypeCombobox
                                    {...field}
                                    onChange={value => {
                                        setValue("legalInfo.register.court", "");
                                        setValue("legalInfo.register.number", "");
                                        clearErrors(["legalInfo.vat.vatNumber", "legalInfo.register.number"]);
                                        field.onChange(value);
                                    }}
                                />
                            </FieldLabel>
                        )}
                    />
                    <Controller
                        name="legalInfo.register.court"
                        rules={{
                            validate: legalValidators.register,
                            deps: ["legalInfo.register.type", "legalInfo.register.number"],
                        }}
                        render={({ field, fieldState }) => (
                            <FieldLabel
                                fieldError={fieldState.error}
                                label={<FormattedMessage id="app.fields.registerCourt" />}
                                style={{ marginBottom: 0, maxWidth: 400, minWidth: 300 }}
                            >
                                <RegisterCourtCombobox data-testid="legalInfo-registerCourt" {...field} />
                            </FieldLabel>
                        )}
                    />
                    <Controller
                        name="legalInfo.register.number"
                        rules={{
                            validate: legalValidators.register,
                            deps: ["legalInfo.register.type", "legalInfo.register.court"],
                        }}
                        render={({ field, fieldState }) => (
                            <FieldLabel
                                fieldError={fieldState.error}
                                label={<FormattedMessage id="app.fields.registerNumber" />}
                                style={{ minWidth: 200 }}
                            >
                                <BaseInput data-testid="legalInfo-registerNumber" {...field} maxLength={17} showCount />
                            </FieldLabel>
                        )}
                    />
                </Space>
            </Row>

            <Divider orientation="left">
                <FormattedMessage id={"app.fields.vatNb"} />
            </Divider>
            <Row>
                <Space.Compact>
                    <Controller
                        name="legalInfo.vat.countryCode"
                        render={({ field, fieldState }) => {
                            return (
                                <FieldLabel
                                    label={<FormattedMessage id="app.fields.euroCountryCode" />}
                                    data-testid="legalInfo-landCode"
                                    fieldError={fieldState.error}
                                >
                                    <CountriesCombobox
                                        optionsList={euroCountriesArray}
                                        {...field}
                                        style={{ width: 100 }}
                                        onChange={value => {
                                            setValue(`legalInfo.vat.vatNumber`, "");
                                            clearErrors("legalInfo.vat.vatNumber");
                                            field.onChange(value);
                                        }}
                                    />
                                </FieldLabel>
                            );
                        }}
                    />
                    <Controller
                        name="legalInfo.vat.vatNumber"
                        rules={{
                            validate: {
                                unique: legalValidators.vatUnique,
                                valid: legalValidators.gatVatValidator(companyGQL.id),
                            },
                            deps: ["legalInfo.vat.countryCode"],
                        }}
                        render={({ field, fieldState }) => (
                            <>
                                <FieldLabel
                                    fieldError={fieldState.error}
                                    label={<FormattedMessage id="app.fields.vatNb" />}
                                >
                                    <BaseInput
                                        data-testid="UStIdNr"
                                        {...field}
                                        maxLength={Contacts.ContactConstants.getVatLengthMax(
                                            legalInfo?.vat?.countryCode
                                        )}
                                        showCount
                                        disabled={!legalInfo?.vat?.countryCode}
                                        status={fieldState.error ? "error" : ""}
                                        onChange={val => {
                                            setValue("legalInfo.vat.vatNumber", val);
                                            if (!validateInput(legalInfo?.vat?.countryCode, val)) {
                                                setError("legalInfo.vat.vatNumber", {
                                                    type: "manual",
                                                });
                                            } else {
                                                clearErrors("legalInfo.vat.vatNumber");
                                            }
                                        }}
                                    />
                                </FieldLabel>
                                <Form.Item label={" "} style={{ marginLeft: 8, width: 100 }}>
                                    {fieldState.isValidating ? (
                                        <div>
                                            <LoadingOutlined />
                                        </div>
                                    ) : null}
                                </Form.Item>
                            </>
                        )}
                    />
                </Space.Compact>
                <Form.Item label=" " data-testid="legalInfo-countryName" style={{ marginLeft: 8 }}>
                    {euroCountriesArray.find(option => option.value === legalInfo?.vat?.countryCode)?.name || ""}
                </Form.Item>
            </Row>

            <Divider orientation="left">
                EU EORI{" "}
                <Link
                    target={"_blank"}
                    to={`https://taxation-customs.ec.europa.eu/customs-4/customs-procedures-import-and-export/customs-procedures/economic-operators-registration-and-identification-number-eori_en`}
                >
                    <QuestionCircleOutlined />
                </Link>
            </Divider>
            <Row>
                <Controller
                    name="legalInfo.EORI"
                    rules={{ validate: customValidators.EORI }}
                    render={({ field, fieldState }) => (
                        <FieldLabel fieldError={fieldState.error} label="EORI">
                            <BaseInput data-testid="legalInfo-EORI" {...field} maxLength={17} showCount />
                        </FieldLabel>
                    )}
                />
            </Row>
        </>
    );
});

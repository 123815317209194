import React, { FC, useContext } from "react";
import { Col, Form, Row, Space, Tooltip } from "antd";
import { InvoiceFormBlocksTranslate, InvoiceFormInputTranslate, InvoiceInputs, TInvoicesValues } from "@inv/types";
import { CountryCodeInput } from "@dms/components/DocumentForm/DocumentInputs/CountryCodeInput";
import { StringInput } from "@app/components/shared/form/baseComponents/StringInput/StringInput";
import { InvoiceFormItemWrapper } from "@inv/components/InvoiceForm/components/InvoiceFormItemWrapper";
import { FormattedMessage, useIntl } from "react-intl";
import { TOptions } from "@dms/components/DocumentForm/types";
import { getNames } from "i18n-iso-countries";
import { useEuroCountryArray } from "../../../../../scripts/infrastructure/hooks/useEuroCountryArray";
import { BaseInput } from "@app/components/shared/form/baseComponents";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Contacts } from "@binale-tech/shared";
import { validation } from "../../../../../scripts/infrastructure/helpers/validation";
import { useApolloClient } from "@apollo/client";
import { CompanyContext } from "../../../../../scripts/context/CompanyContext";

export const CustomerInformationBlock: FC = () => {
    const intl = useIntl();
    const { companyGQL } = useContext(CompanyContext);
    const client = useApolloClient();
    const form = Form.useFormInstance<TInvoicesValues>();
    const vat = Form.useWatch([InvoiceInputs.CUSTOMER_VAT], form);
    const euroCountriesArray = useEuroCountryArray();
    const countriesArray: TOptions = React.useMemo(() => {
        return Object.entries(getNames(intl.locale, { select: "official" })).map(([code, countryName]) => ({
            value: code,
            label: countryName,
        }));
    }, [intl.locale]);

    return (
        <InvoiceFormItemWrapper title={intl.formatMessage({ id: InvoiceFormBlocksTranslate.CUSTOMER_DETAIL })}>
            <Row gutter={10}>
                <Col span={24}>
                    <Form.Item
                        name={InvoiceInputs.CUSTOMER_NAME}
                        label={<FormattedMessage id={"app.fields.companyName"} />}
                    >
                        <StringInput placeholder={intl.formatMessage({ id: "app.fields.companyName" })} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={InvoiceInputs.CUSTOMER_RECEIVER_NAME}
                        label={<FormattedMessage id={"app.invoice.customerReceiverName"} />}
                    >
                        <StringInput placeholder={intl.formatMessage({ id: "app.invoice.customerReceiverName" })} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name={InvoiceInputs.ADDRESS}>
                        <StringInput placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.ADDRESS })} />
                    </Form.Item>
                </Col>
                <Col span={17}>
                    <Form.Item name={InvoiceInputs.STREET}>
                        <StringInput placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.STREET })} />
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item name={InvoiceInputs.HOUSE_NUMBER}>
                        <StringInput placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.HOUSE_NUMBER })} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={InvoiceInputs.COUNTRY_CODE}>
                        <CountryCodeInput
                            placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.COUNTRY_CODE })}
                            options={countriesArray}
                        />
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item name={InvoiceInputs.CITY}>
                        <StringInput placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.CITY })} />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item name={InvoiceInputs.ZIP_CODE}>
                        <StringInput placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.ZIP_CODE })} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name={InvoiceInputs.CUSTOMER_EMAIL}>
                        <StringInput placeholder={intl.formatMessage({ id: "app.global.email" })} />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        label={<FormattedMessage id="app.fields.euroCountryCode" />}
                        name={[InvoiceInputs.CUSTOMER_VAT, "countryCode"]}
                        style={{ marginBottom: 0 }}
                    >
                        <CountryCodeInput options={euroCountriesArray} />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item
                        label={<FormattedMessage id="app.fields.vatNb" />}
                        name={[InvoiceInputs.CUSTOMER_VAT, "vatNumber"]}
                        rules={[validation.vat(client, vat?.countryCode, companyGQL.id)]}
                    >
                        <BaseInput
                            maxLength={Contacts.ContactConstants.getVatLengthMax(vat?.countryCode)}
                            showCount
                            disabled={!vat?.countryCode}
                        />
                    </Form.Item>
                </Col>
                <Col span={14}>
                    <Form.Item
                        label={
                            <Space>
                                <FormattedMessage id="app.fields.taxNumber" />
                                <Tooltip title={<FormattedMessage id="app.global.onlyNumbersHint" />}>
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </Space>
                        }
                        name={[InvoiceInputs.CUSTOMER_TAX, "stnr"]}
                    >
                        <BaseInput showCount minLength={10} maxLength={11} pattern="^[0-9]*$" />
                    </Form.Item>
                </Col>
            </Row>
        </InvoiceFormItemWrapper>
    );
};

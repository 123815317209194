import React, { FC, useContext } from "react";
import { PushpinTwoTone } from "@ant-design/icons";
import { GenericRecord } from "../../../scripts/models";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { Flex } from "antd";

type Props = {
    record: GenericRecord;
};
export const ColXRechnung: FC<Props> = ({ record }) => {
    const { documentsKV } = useContext(DmsDataContext);
    const { documents } = record;
    const hasXRechnung = (documents ?? []).map(v => documentsKV[v.id]).some(d => d?.hasXRechnung);
    if (!hasXRechnung) {
        return null;
    }
    return (
        <Flex justify={"center"}>
            <PushpinTwoTone twoToneColor={"#25ba3b"} />
        </Flex>
    );
};

import { getLineItemNetto, InvoiceFormInputTranslate, TInvoiceFormLineItem } from "@inv/types";
import React, { FC, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormKeyHandlers } from "@app/components/shared/form/BinaleForm";
import { Button, Flex, Form, Input, InputNumber, Modal, Select } from "antd";
import { validation } from "../../../../../scripts/infrastructure/helpers/validation";
import { BruttoInputAntForm } from "@ui-components/AmountInput/BruttoInput";
import { currencyFormatter } from "@dms/scripts/helpers";
import { useLineItemOptions } from "@inv/hooks/useLineItemOptions";
import { useInvoiceLineItemTemplates } from "@inv/hooks/useInvoiceLineItemTemplates";

const formClassName = "LineItemForm";
type Props = { onClose: () => void; values: TInvoiceFormLineItem; onSubmit: (v: TInvoiceFormLineItem) => void };
export const InvoiceItemForm: FC<Props> = ({ values, onClose, onSubmit }) => {
    const intl = useIntl();
    const { templatesList } = useInvoiceLineItemTemplates();
    const { handleKeyUp, handleKeyDown } = useFormKeyHandlers({ formClassName });
    const [form] = Form.useForm<TInvoiceFormLineItem>();
    const fv = Form.useWatch([], form);

    useEffect(() => {
        if (values) {
            form.setFieldsValue(values);
            form.focusField(["productsServices"]);
        } else {
            form.resetFields();
        }
    }, [form, values]);

    const { unitOptions, taxOptions } = useLineItemOptions();

    return (
        <Modal title={<>&nbsp;</>} footer={null} onCancel={onClose} open={Boolean(values)} width={900} destroyOnClose>
            <Form
                form={form}
                initialValues={values}
                onFinish={onSubmit}
                layout="vertical"
                className={formClassName}
                // onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                onKeyDown={handleKeyDown}
            >
                {templatesList.length && (
                    <Form.Item label={"Vorlage"}>
                        <Select
                            autoFocus
                            options={templatesList.map(v => ({ label: v.name, value: v }))}
                            onSelect={(id, option) => {
                                const template = option.value;
                                if (template) {
                                    const { name, description, unit, tax, price } = template;
                                    form.setFieldsValue({ name, description, price, unit, tax });
                                }
                            }}
                        />
                    </Form.Item>
                )}
                <Form.Item
                    name={"name"}
                    rules={[validation.required(intl)]}
                    label={<FormattedMessage id={InvoiceFormInputTranslate.NAME} />}
                >
                    <Input autoFocus />
                </Form.Item>
                <Form.Item<TInvoiceFormLineItem>
                    name={"description"}
                    label={<FormattedMessage id="app.fields.description" />}
                >
                    <Input.TextArea autoSize />
                </Form.Item>
                <Flex style={{ width: "100%" }} justify={"space-between"}>
                    <Form.Item<TInvoiceFormLineItem>
                        name={"unit"}
                        style={{ width: 100 }}
                        label={<FormattedMessage id={InvoiceFormInputTranslate.UNIT} />}
                    >
                        <Select options={unitOptions} />
                    </Form.Item>
                    <Form.Item<TInvoiceFormLineItem>
                        name={"quantity"}
                        rules={[validation.required(intl)]}
                        label={<FormattedMessage id={InvoiceFormInputTranslate.QUANTITY} />}
                    >
                        <InputNumber precision={2} min={0} step={1} />
                    </Form.Item>

                    <Form.Item<TInvoiceFormLineItem>
                        name={"price"}
                        label={<FormattedMessage id={InvoiceFormInputTranslate.PRICE} />}
                    >
                        <BruttoInputAntForm />
                    </Form.Item>
                    <Form.Item<TInvoiceFormLineItem>
                        name={"discount"}
                        label={<FormattedMessage id={InvoiceFormInputTranslate.DISCOUNT} />}
                    >
                        <BruttoInputAntForm />
                    </Form.Item>
                    <Form.Item<TInvoiceFormLineItem> label={<FormattedMessage id={"app.fields.netto"} />}>
                        <Input disabled value={currencyFormatter(getLineItemNetto(fv))} />
                    </Form.Item>
                    <Form.Item<TInvoiceFormLineItem>
                        name={"tax"}
                        label={<FormattedMessage id={InvoiceFormInputTranslate.TAX} />}
                        style={{ width: 100 }}
                    >
                        <Select options={taxOptions} />
                    </Form.Item>
                </Flex>
                <Flex justify={"flex-end"}>
                    <Button type="primary" htmlType="submit">
                        <FormattedMessage id={"app.button.save"} />
                    </Button>
                </Flex>
            </Form>
        </Modal>
    );
};

import { Periods } from "@binale-tech/shared";

export const findNodes = (node: Record<string, any>[], key: string): Record<string, any>[] => {
    return node?.filter((node: Record<string, any>) => Object.keys(node ?? {})[0] === key).map(v => v?.[key]) ?? [];
};
export const findNode = (node: Record<string, any>[], key: string): Record<string, any> => {
    return findNodes(node, key)[0];
};
export const getChild = (node: Record<string, any>[], key: string): Record<string, any>[] =>
    findNode(node, key)?.children ?? [];
export const getChilds = (node: Record<string, any>[], key: string): Record<string, any>[][] =>
    findNodes(node, key).map(v => v.children);

export const convertDateToStructured = (
    invoiceDate?: string
): { year: number; period: number; day: number; date: Date } => {
    if (!invoiceDate) {
        return { year: undefined, period: undefined, day: undefined, date: undefined };
    }
    const invoiceDateParsed = {
        year: Number(invoiceDate.substring(0, 4)),
        period: Number(invoiceDate.substring(4, 6)),
        day: Number(invoiceDate.substring(6, 8)),
    };
    const date = Periods.constructDate(invoiceDateParsed.year, invoiceDateParsed.period, invoiceDateParsed.day);
    return { ...invoiceDateParsed, date };
};

export function htmlDecode(input?: string) {
    if (!input) {
        return undefined;
    }
    const doc = new DOMParser().parseFromString("<div>" + input + "</div>", "text/html");
    return doc.documentElement.textContent;
}

export function convert102Date(v: string): string {
    if (!v) {
        return undefined;
    }
    return `${v.substring(6, 8)}.${v.substring(4, 6)}.${v.substring(0, 4)}`;
}

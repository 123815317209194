import "@ant-design/v5-patch-for-react-19";

import App from "./App";
import React from "react";
import * as Sentry from "@sentry/react";
import { ApolloProvider } from "@apollo/client";
import { ContextProvider } from "./scripts/context";
import { NextUIProvider } from "@nextui-org/system";
import { ScrollbarDetection } from "./scripts/infrastructure/helpers/browser";
import { createRoot } from "react-dom/client";
import { getApolloClient } from "./scripts/graphql";
import { getEnvCode } from "./scripts/infrastructure/getEnvCode";
import { AllCommunityModule, ModuleRegistry, provideGlobalGridOptions } from "ag-grid-community";

ModuleRegistry.registerModules([AllCommunityModule]);
provideGlobalGridOptions({ theme: "legacy" });

Sentry.init({
    enabled: document.location.host.indexOf("localhost") === -1,

    dsn: "https://6c15fa1ee2424b8982c2e3834dd0e1c1@o56613.ingest.sentry.io/179638",
    environment: getEnvCode(),
    ignoreErrors: ["Non-Error exception captured", "ResizeObserver loop", "Failed to update a ServiceWorker for scope"],
});
Sentry.setTag("appVersion", import.meta.env.VITE_VERSION);

ScrollbarDetection.runDetector();

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <NextUIProvider>
        <ApolloProvider client={getApolloClient()}>
            <ContextProvider>
                <App />
            </ContextProvider>
        </ApolloProvider>
    </NextUIProvider>
);

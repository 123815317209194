import React, { FC } from "react";
import { Flex } from "antd";

import { useInvoicePaymentTemplates } from "@inv/hooks/useInvoicePaymentTemplates";
import { InvoicePaymentTemplateListHeader } from "@inv/components/InvoicePaymentTemplates/InvoicePaymentTemplateListHeader";
import { InvoicePaymentTemplateList } from "@inv/components/InvoicePaymentTemplates/InvoicePaymentTemplateList";

export const InvoicePaymentTemplateListPage: FC = () => {
    const { templatesList, refetch } = useInvoicePaymentTemplates();
    return (
        <Flex
            vertical
            style={{
                height: "100%",
            }}
        >
            <InvoicePaymentTemplateListHeader refetch={refetch} />
            <InvoicePaymentTemplateList templatesList={templatesList} refetch={refetch} />
        </Flex>
    );
};

import { Button, Drawer, Form, Input, InputNumber } from "antd";
import React, { FC, memo, useCallback, useContext } from "react";
import { GQL, Utils } from "@binale-tech/shared";
import { FormattedMessage, useIntl } from "react-intl";
import { validation } from "../../../scripts/infrastructure/helpers/validation";
import { BaseInput } from "@app/components/shared/form/baseComponents";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import { CompanyContext } from "../../../scripts/context/CompanyContext";
import { gql } from "@apollo/client";

type Props = {
    open: boolean;
    onClose: () => void;
    data?: GQL.IInvoiceNumberFormat;
    disabled?: boolean;
};

const invoiceNumberFormatSave = gql`
    mutation invoiceNumberFormatSave($input: InvoiceNumberFormatSaveInput!) {
        invoiceNumberFormatSave(input: $input) {
            id
        }
    }
`;

const InvoiceFormatForm: FC<Props> = ({ data, open, onClose, disabled }) => {
    const intl = useIntl();
    const [form] = Form.useForm<GQL.IInvoiceNumberFormat>();
    const mutator = useGqlMutator();
    const { companyGQL } = useContext(CompanyContext);
    const companyId = companyGQL?.id;
    const onFinish = useCallback(
        (values: GQL.IInvoiceNumberFormat) => {
            mutator
                .mutate<"invoiceNumberFormatSave", GQL.IInvoiceNumberFormatSaveInput>({
                    mutation: invoiceNumberFormatSave,
                    input: {
                        ...values,
                        companyId,
                    },
                })
                .then(() => onClose());
        },
        [companyId, mutator, onClose]
    );

    const initialValues: Partial<GQL.IInvoiceNumberFormat> = data ?? {
        numberLen: 3,
        number: 1,
        year: new Date().getFullYear(),
    };
    const format = Form.useWatch([], form);
    const numberLen = Form.useWatch(["numberLen"], form);

    return (
        <Drawer open={open} onClose={onClose}>
            <Form<GQL.IInvoiceNumberFormat>
                onFinish={onFinish}
                initialValues={initialValues}
                form={form}
                layout="vertical"
                disabled={disabled}
            >
                <Form.Item<GQL.IInvoiceNumberFormat> name="id" style={{ display: "none" }} />
                <Form.Item<GQL.IInvoiceNumberFormat> name="year" label={"Jahr"} rules={[validation.required(intl)]}>
                    <InputNumber min={2020} max={new Date().getFullYear() + 1} style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item<GQL.IInvoiceNumberFormat>
                    name="prefix"
                    tooltip={
                        <div>
                            <code>aA - zZ</code> <code>0 - 9</code> <code>- / $ & % +</code>
                        </div>
                    }
                    label={"Prefix"}
                >
                    <BaseInput showCount maxLength={16} pattern="^[\w\-\/\$\&\%\+]*$" />
                </Form.Item>
                <Form.Item<GQL.IInvoiceNumberFormat>
                    name="numberLen"
                    label={"Nummerlänge"}
                    rules={[validation.required(intl)]}
                >
                    <InputNumber min={2} max={9} style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item<GQL.IInvoiceNumberFormat>
                    name="number"
                    label={"Startnummer"}
                    rules={[validation.required(intl)]}
                    tooltip={`Max ${10 ** numberLen - 1}`}
                >
                    <InputNumber min={1} max={10 ** numberLen - 1} style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item<GQL.IInvoiceNumberFormat>
                    name="suffix"
                    label={"Suffix"}
                    tooltip={
                        <div>
                            <code>aA - zZ</code> <code>0 - 9</code> <code>- / $ & % +</code>
                        </div>
                    }
                >
                    <BaseInput showCount maxLength={16} pattern="^[\w\-\/\$\&\%\+]*$" />
                </Form.Item>
                <Form.Item label={"Beischpiel"}>
                    <Input disabled value={format ? Utils.Invoicing.getInvoiceNumber(format) : ""} />
                </Form.Item>
                <Form.Item<GQL.IInvoiceNumberFormat>
                    name="description"
                    label={<FormattedMessage id={"app.fields.description"} />}
                >
                    <Input.TextArea maxLength={255} showCount />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                    <FormattedMessage id={"app.button.save"} />
                </Button>
            </Form>
        </Drawer>
    );
};
export default memo(InvoiceFormatForm);

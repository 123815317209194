import { useContext } from "react";
import { InvoicesDataContext } from "@inv/context/InvoicesDataProvider";
import { useInvoiceCustomer } from "@inv/hooks/useInvoiceCustomer";
import { useInvoiceSupplier } from "@inv/hooks/useInvoiceSupplier";
import { useInvoiceLines } from "@inv/hooks/useInvoiceLines";
import { CompanyContext } from "../../scripts/context/CompanyContext";
import { extractIBAN } from "@banks/scripts/ibantools";
import { bankDataByIBAN } from "bankdata-germany";

const getDate = (date: string) => date.split(".").reverse().join("-");

export const useInvoiceGoBL = (invoiceId?: string) => {
    const { invoicesMap } = useContext(InvoicesDataContext);
    const { companyGQL } = useContext(CompanyContext);
    const invoice = invoiceId ? invoicesMap.get(invoiceId) : undefined;
    const customer = useInvoiceCustomer(invoiceId);
    const supplier = useInvoiceSupplier();
    const lines = useInvoiceLines(invoiceId);
    if (!invoice) {
        return null;
    }
    // console.log({ invoice });

    const getNotes = () => {
        const parts: string[] = [];
        const { director, invoiceRegister } = companyGQL;
        if (director) {
            parts.push("Geschäftsführer: " + director);
        }
        if (invoiceRegister) {
            parts.push(
                `HR: ${[invoiceRegister.court, invoiceRegister.type, invoiceRegister.number].filter(Boolean).join(" ").replace("Amtsgericht", "AG")}`
            );
        }
        if (parts.length) {
            return [{ code: "REG", text: parts.join(", ") }];
        }
        return undefined;
    };

    const getPaymentInstructions = () => {
        const { IBAN } = companyGQL;
        if (IBAN) {
            const ibanData = extractIBAN(IBAN);
            const bankData = ibanData.countryCode === "DE" ? bankDataByIBAN(IBAN) : null;
            return {
                credit_transfer: [
                    {
                        iban: IBAN,
                        bic: bankData?.bic,
                        name: supplier.name,
                    },
                ],
                ext: {
                    "untdid-payment-means": "58",
                },
            };
        }
        return {
            ext: {
                "untdid-payment-means": "1",
            },
        };
    };

    const getDelivery = () => {
        if (invoice?.serviceDate) {
            return { date: getDate(invoice.serviceDate) };
        }
        if (invoice?.servicePeriodDays) {
            return { period: { start: getDate("08.03.1992"), end: getDate("08.03.1992") } };
        }
        return undefined;
    };

    return {
        $schema: "https://gobl.org/draft-0/bill/invoice",
        $regime: "DE",
        tax: {
            ext: {
                "untdid-document-type": "380",
            },
        },
        $addons: ["eu-en16931-v2017"],
        uuid: invoice?.id,
        type: "standard",
        code: invoice?.invoiceNumber,
        issue_date: invoice?.date ? getDate(invoice.date) : undefined,
        delivery: getDelivery(),
        currency: invoice?.currencyCode,
        supplier,
        customer,
        lines,
        notes: getNotes(),
        payment: {
            terms: invoice?.paymentTerm
                ? {
                      detail: invoice?.paymentTerm,
                  }
                : undefined,
            instructions: getPaymentInstructions(),
        },
    };
};

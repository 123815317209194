import React, { useEffect } from "react";
import { Button, Col, Input, Row, Space } from "antd";
import { Controller, useForm } from "react-hook-form";
import { FieldLabel } from "@ui-components/FieldLabel";
import { FormattedMessage, useIntl } from "react-intl";

import BinaleForm from "appearance/components/shared/form/BinaleForm";
import Tag from "scripts/models/Tag";
import { BaseInput } from "appearance/components/shared/form/baseComponents";
import { useValidationReactHookForm } from "scripts/infrastructure/helpers/validation";

interface Props {
    item: Tag;
    onSave: (v: Tag) => Promise<void>;
    onClear: () => void;
    disabled: boolean;
    uniqueNums: string[];
}

const TagForm: React.FC<Props> = ({ onSave, uniqueNums, item, disabled, ...props }) => {
    const { handleSubmit, formState, reset, register, control } = useForm<Tag>({ defaultValues: item });

    const isEditing = Boolean(item?.key);
    const intl = useIntl();
    const validation = useValidationReactHookForm();

    const onClear = () => {
        reset({});
        props.onClear();
    };

    const onHandleSubmit = async (values: Tag) => {
        await onSave(Tag.unserialize(values));
        onClear();
    };

    useEffect(() => {
        reset(item);
    }, [item, reset]);

    return (
        <BinaleForm onSubmit={handleSubmit(onHandleSubmit)}>
            <Input type="hidden" {...register("key")} />
            <Controller
                render={({ field, fieldState }) => (
                    <FieldLabel
                        label={<FormattedMessage id="app.fields.konto" />}
                        required
                        fieldError={fieldState.error}
                    >
                        <BaseInput
                            {...field}
                            placeholder={intl.formatMessage({ id: "app.fields.konto" })}
                            disabled={disabled || isEditing}
                            maxLength={8}
                            showCount
                            pattern="^[0-9A-Za-z]*$"
                        />
                    </FieldLabel>
                )}
                control={control}
                name="num"
                rules={{
                    required: validation.required,
                    maxLength: validation.maxLength(8),
                    validate: validation.unique(uniqueNums, item?.num),
                }}
            />
            <Controller
                render={({ field, fieldState }) => (
                    <FieldLabel
                        label={<FormattedMessage id="app.fields.bezeichnung" />}
                        required
                        fieldError={fieldState.error}
                    >
                        <Input
                            {...field}
                            placeholder={intl.formatMessage({ id: "app.fields.bezeichnung" })}
                            disabled={disabled}
                            maxLength={60}
                        />
                    </FieldLabel>
                )}
                control={control}
                name="name"
                rules={{
                    required: validation.required,
                    maxLength: validation.maxLength(60),
                }}
            />
            <Row>
                <Col span={24}>
                    <Space>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={disabled || formState.isSubmitting}
                            loading={formState.isSubmitting}
                        >
                            <FormattedMessage id="app.button.save" tagName="span" />
                        </Button>
                        <Button onClick={onClear} disabled={formState.isSubmitting}>
                            <FormattedMessage id="app.button.cancel" />
                        </Button>
                    </Space>
                </Col>
            </Row>
        </BinaleForm>
    );
};
export default React.memo(TagForm);

import React, { useRef } from "react";
import { Contacts } from "@binale-tech/shared";
import { Space } from "antd";
import { useFieldArray, useFormContext } from "react-hook-form";

import { CommunicationButton, CommunicationItem } from "../components/communication";

export const Communication: React.FC = React.memo(function Communication() {
    const { setFocus } = useFormContext<Contacts.Contact>();
    const { append, remove, fields } = useFieldArray({ name: "communications", shouldUnregister: true });
    const addBtnRef = useRef<HTMLButtonElement>(null);

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {fields.map((field, index) => (
                <CommunicationItem
                    key={field.id}
                    communicationIndex={index}
                    onRemove={async () => {
                        remove(index);
                        await new Promise(r => setTimeout(r, 10));
                        if (fields.length > 1 && fields.length - 1 > index) {
                            setFocus(`communications.${index}.value`);
                        } else {
                            addBtnRef.current?.focus();
                        }
                    }}
                />
            ))}
            <CommunicationButton
                ref={addBtnRef}
                onSelect={type =>
                    append({ type, comment: "", value: "" }, { focusName: `communications.${fields.length}.value` })
                }
            />
        </Space>
    );
});

import React, { FC } from "react";
import { FieldLabel } from "@app/components/shared/form/baseComponents/FieldLabel";
import { Typography } from "antd";

import styles from "./FormFields.module.scss";
import { UppercaseAndNumberInput } from "@app/components/shared/form/baseComponents/UppercaseLatinLettersInput";
import { validation } from "../../../scripts/infrastructure/helpers/validation";
import { useIntl } from "react-intl";

const { Text } = Typography;

export const SwiftBicField: FC = () => {
    const intl = useIntl();
    return (
        <FieldLabel
            className={styles.bankFormField}
            label={
                <Text strong ellipsis>
                    BIC
                </Text>
            }
            name={"BIC"}
            rules={[validation.min(intl, 8)]}
        >
            <UppercaseAndNumberInput maxLength={11} showCount />
        </FieldLabel>
    );
};

import { IDecoder } from "./IDecoder";
import { convert102Date, convertDateToStructured, findNode, getChild, getChilds, htmlDecode } from "./CIITools";
import { Bu, GQL } from "@binale-tech/shared";

export abstract class AbstractDecoder implements IDecoder {
    constructor(
        protected readonly CII: Record<string, any>[],
        protected readonly pk: GQL.IProductKey
    ) {}

    abstract getVersion(): { version: string; isDeprecated: boolean };
    protected abstract getHeader(): Record<string, any>[];
    protected abstract getTransaction(): Record<string, any>[];
    protected abstract getTransactionTradeAgreement(): Record<string, any>[];
    protected abstract getTransactionTradeDelivery(): Record<string, any>[];
    protected abstract getTransactionTradeSettlement(): Record<string, any>[];
    protected abstract tagTaxApplicablePercent(nodes: Record<string, any>[]): number; // BT-119
    protected abstract getLineItem(nodes: Record<string, any>[]): {
        name: string;
        description: string;
        netto: number;
        tax: number;
    };

    /**
     * Header fields
     */

    /**
     * BT-1
     */
    getInvoiceID(): string {
        return findNode(this.getHeader(), "ram:ID")?.content; // BT-1
    }

    /**
     * BT-2
     */
    getInvoiceDate(): { year: number; period: number; day: number; date: Date } {
        const issueDate = getChild(this.getHeader(), "ram:IssueDateTime");
        const invoiceDate: string = findNode(issueDate, "udt:DateTimeString")?.content; // BT-2;

        return convertDateToStructured(invoiceDate);
    }

    /**
     * Delivery fields
     */

    /**
     * BT-72 MM.DD.YYYY
     */
    getDeliveryDate(): string {
        const actualDelivery = getChild(this.getTransactionTradeDelivery(), "ram:ActualDeliverySupplyChainEvent");
        const actualDeliveryDateTime = getChild(actualDelivery, "ram:OccurrenceDateTime");
        const content = findNode(actualDeliveryDateTime, "udt:DateTimeString")?.content;
        return convert102Date(content);
    }

    /**
     * Agreement fields
     */

    /**
     * Supplier
     */
    getSupplier() {
        const seller = getChild(this.getTransactionTradeAgreement(), "ram:SellerTradeParty");
        const supplierName = htmlDecode(findNode(seller, "ram:Name")?.content); // BT-27

        const taxRegistrations = getChilds(seller, "ram:SpecifiedTaxRegistration");
        const taxNodes = taxRegistrations.map(n => findNode(n, "ram:ID"));
        const vatId: string = taxNodes.find(v => v.schemeID === "VA")?.content; // BT-31
        const taxId: string = taxNodes.find(v => v.schemeID === "FC")?.content; // BT-32

        const supplierAddress = getChild(seller, "ram:PostalTradeAddress"); // BG-5
        const supplierAddressLines: string[] = [
            htmlDecode(findNode(supplierAddress, "ram:LineOne")?.content), // BT-35
            htmlDecode(findNode(supplierAddress, "ram:LineTwo")?.content), // BT-36
            [
                htmlDecode(findNode(supplierAddress, "ram:PostcodeCode")?.content), // BT-38
                htmlDecode(findNode(supplierAddress, "ram:CityName")?.content), // BT-37
                htmlDecode(findNode(supplierAddress, "ram:CountryID")?.content), // BT-40
            ]
                .filter(Boolean)
                .join(" "),
        ].filter(Boolean);

        const contactsPointsSet = new Set<string>();
        const tradeContact = getChild(seller, "ram:DefinedTradeContact"); // BG-6
        if (tradeContact) {
            contactsPointsSet.add(htmlDecode(findNode(tradeContact, "ram:PersonName")?.content)); // BT-41
            const contactEmailCommunication = getChild(tradeContact, "ram:EmailURIUniversalCommunication"); // BT-43
            contactsPointsSet.add(htmlDecode(findNode(contactEmailCommunication, "ram:URIID")?.content));
            const contactPhoneCommunication = getChild(tradeContact, "ram:TelephoneUniversalCommunication"); // BT-42
            contactsPointsSet.add(htmlDecode(findNode(contactPhoneCommunication, "ram:CompleteNumber")?.content));
        }

        const supplierCommunication = getChild(seller, "ram:URIUniversalCommunication"); // BT-34
        contactsPointsSet.add(htmlDecode(findNode(supplierCommunication, "ram:URIID")?.content));

        const communications = [...contactsPointsSet].filter(Boolean);
        return {
            name: supplierName,
            vatId,
            taxId,
            addressLines: supplierAddressLines,
            communications,
        };
    }

    getBuyer() {
        const buyer = getChild(this.getTransactionTradeAgreement(), "ram:BuyerTradeParty"); // BG-7

        const buyerName = htmlDecode(findNode(buyer, "ram:Name")?.content); // BT-44

        const taxRegistrations = getChilds(buyer, "ram:SpecifiedTaxRegistration");
        const taxNodes = taxRegistrations.map(n => findNode(n, "ram:ID"));
        const vatId: string = taxNodes.find(v => v.schemeID === "VA")?.content; // BT-48
        const taxId: string = taxNodes.find(v => v.schemeID === "FC")?.content; //

        const buyerAddress = getChild(buyer, "ram:PostalTradeAddress"); // BG-8
        const addressLines: string[] = [
            htmlDecode(findNode(buyerAddress, "ram:LineOne")?.content), // BT-50
            htmlDecode(findNode(buyerAddress, "ram:LineTwo")?.content), // BT-51
            [
                htmlDecode(findNode(buyerAddress, "ram:PostcodeCode")?.content), // BT-53
                htmlDecode(findNode(buyerAddress, "ram:CityName")?.content), // BT-52
                htmlDecode(findNode(buyerAddress, "ram:CountryID")?.content), // BT-55
            ]
                .filter(Boolean)
                .join(" "),
        ].filter(Boolean);
        const buyerCommunication = getChild(buyer, "ram:URIUniversalCommunication");
        const buyerEmail = htmlDecode(findNode(buyerCommunication, "ram:URIID")?.content); // BT-49
        return { name: buyerName, vatId, taxId, addressLines, email: buyerEmail };
    }

    /**
     * Settlement fields
     */

    /**
     * BT-5
     */
    getCurrency(): GQL.ICurrencyCode {
        return (
            (findNode(this.getTransactionTradeSettlement(), "ram:InvoiceCurrencyCode")?.content as GQL.ICurrencyCode) ??
            GQL.ICurrencyCode.Eur
        );
    }

    getPaymentReference(): string {
        return htmlDecode(findNode(this.getTransactionTradeSettlement(), "ram:PaymentReference")?.content);
    }

    getGeneralPaymentDetails() {
        const paymentTerms = getChild(this.getTransactionTradeSettlement(), "ram:SpecifiedTradePaymentTerms");
        const description = htmlDecode(findNode(paymentTerms, "ram:Description")?.content); // BT-20

        const dueDateDateTime = getChild(paymentTerms, "ram:DueDateDateTime");
        const dueDate: string = convert102Date(findNode(dueDateDateTime, "udt:DateTimeString")?.content); // BT-9;
        return { description, dueDate };
    }

    getSupplierPaymentType() {
        const paymentMeans = getChild(this.getTransactionTradeSettlement(), "ram:SpecifiedTradeSettlementPaymentMeans");
        const paymentAccount = getChild(paymentMeans, "ram:PayeePartyCreditorFinancialAccount");
        const paymentInstitution = getChild(paymentMeans, "ram:PayeeSpecifiedCreditorFinancialInstitution");

        const type = findNode(paymentMeans, "ram:TypeCode")?.content; // BT-81
        const IBAN = findNode(paymentAccount, "ram:IBANID")?.content; // BT-84
        const accountName = htmlDecode(findNode(paymentAccount, "ram:AccountName")?.content); // BT-85
        const BIC = findNode(paymentInstitution, "ram:BICID")?.content; // BT-86

        return { type, IBAN, BIC, accountName };
    }

    getTaxes() {
        const taxes = getChilds(this.getTransactionTradeSettlement(), "ram:ApplicableTradeTax"); // BG-23
        return taxes
            .map(t => {
                const tax = this.tagTaxApplicablePercent(t); // BT-119
                const nettoAmount = Number(findNode(t, "ram:BasisAmount")?.content ?? "0") * 100; // BT-116
                const taxAmount = Number(findNode(t, "ram:CalculatedAmount")?.content ?? "0") * 100; // BT-117
                return { tax, nettoAmount, taxAmount, brutto: nettoAmount + taxAmount, bu: this.getBu(tax) };
            })
            .filter(v => v.nettoAmount !== 0);
    }

    getLineItems() {
        const lineItems = getChilds(this.getTransaction(), "ram:IncludedSupplyChainTradeLineItem"); // BG-25

        return lineItems.map(v => {
            const item = this.getLineItem(v);
            return { ...item, bu: this.getBu(item.tax) };
        });
    }

    private getBu(tax: number): Bu.Bu {
        if (tax === 7) {
            if (this.pk === GQL.IProductKey.Deb) {
                return Bu.Bu.BU2;
            }
            return Bu.Bu.BU8;
        }
        if (tax === 19) {
            if (this.pk === GQL.IProductKey.Deb) {
                return Bu.Bu.BU3;
            }
            return Bu.Bu.BU9;
        }
        return Bu.Bu.KU;
    }
}

import { Button, Drawer, Form, Input } from "antd";
import React, { FC, memo, useCallback, useContext } from "react";
import { GQL } from "@binale-tech/shared";
import { FormattedMessage, useIntl } from "react-intl";
import { validation } from "../../../scripts/infrastructure/helpers/validation";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import { CompanyContext } from "../../../scripts/context/CompanyContext";
import { gql } from "@apollo/client";
import { InvoiceFormInputTranslate } from "@inv/types";

type Props = {
    open: boolean;
    onClose: () => void;
    data?: GQL.IInvoicePaymentTemplate;
    disabled?: boolean;
};

const invoicePaymentTemplateSave = gql`
    mutation invoicePaymentTemplateSave($input: InvoicePaymentTemplateSaveInput!) {
        invoicePaymentTemplateSave(input: $input)
    }
`;

const InvoiceTemplateLineItemForm: FC<Props> = ({ data, open, onClose, disabled }) => {
    const intl = useIntl();
    const [form] = Form.useForm<GQL.IInvoicePaymentTemplate>();
    const mutator = useGqlMutator();
    const { companyGQL } = useContext(CompanyContext);
    const companyId = companyGQL?.id;
    const handleClose = useCallback(() => {
        form.resetFields();
        onClose();
    }, [form, onClose]);
    const onFinish = useCallback(
        (values: GQL.IInvoicePaymentTemplate) => {
            mutator
                .mutate<"invoicePaymentTemplateSave", GQL.IInvoicePaymentTemplateSaveInput>({
                    mutation: invoicePaymentTemplateSave,
                    input: {
                        ...values,
                        companyId,
                    },
                })
                .then(() => handleClose());
        },
        [companyId, mutator, handleClose]
    );

    const initialValues: Partial<GQL.IInvoicePaymentTemplate> = data ?? {
        terms: "",
    };

    return (
        <Drawer open={open} onClose={handleClose}>
            <Form<GQL.IInvoicePaymentTemplate>
                onFinish={onFinish}
                initialValues={initialValues}
                form={form}
                layout="vertical"
                disabled={disabled}
            >
                <Form.Item<GQL.IInvoicePaymentTemplate> name="id" style={{ display: "none" }} />
                <Form.Item<GQL.IInvoicePaymentTemplate>
                    name="terms"
                    label={<FormattedMessage id={InvoiceFormInputTranslate.PAYMENT_TERM} />}
                    rules={[validation.required(intl)]}
                >
                    <Input.TextArea style={{ width: "100%" }} />
                </Form.Item>

                <Button type="primary" htmlType="submit">
                    <FormattedMessage id={"app.button.save"} />
                </Button>
            </Form>
        </Drawer>
    );
};
export default memo(InvoiceTemplateLineItemForm);

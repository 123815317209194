import { useContext, useMemo } from "react";
import { InvoiceTableContext } from "@inv/components/InvoiceFormLineItemsTable/context/InvoiceTableProvider";
import { InvoiceFormTableColumns } from "./InvoiceFormTableColumns";
import { TInvoiceFormColumnsConfig } from "@inv/types";

export const useColumnConfig = () => {
    const { columnsConfig } = useContext(InvoiceTableContext);
    const activeKeys = useMemo(
        () => Object.keys(columnsConfig).filter((key: keyof TInvoiceFormColumnsConfig) => columnsConfig[key]),
        [columnsConfig]
    );

    return useMemo(() => InvoiceFormTableColumns.getColumns(activeKeys), [activeKeys]);
};

import React, { FC } from "react";
import { InvoiceFormItemWrapper } from "@inv/components/InvoiceForm/components/InvoiceFormItemWrapper";
import { InvoiceFormBlocksTranslate, InvoiceFormInputTranslate, InvoiceInputs } from "@inv/types";
import { useIntl } from "react-intl";
import { Flex, Form, Segmented } from "antd";
import { InvoiceCurrencySelect } from "@inv/components/InvoiceFormLineItemsTable/components/InvoiceCurrencySelect";
import InvoiceLineItemsTable from "invoicing/components/InvoiceFormLineItemsTable";

export const LineItemsBlock: FC = () => {
    const intl = useIntl();
    return (
        <InvoiceFormItemWrapper title={intl.formatMessage({ id: InvoiceFormBlocksTranslate.LINE_ITEMS })}>
            <Flex style={{ width: "100%", marginBottom: 20 }} align={"center"} justify="space-between">
                <Form.Item name={InvoiceInputs.CURRENCY_CODE} style={{ marginBottom: "0" }}>
                    <InvoiceCurrencySelect />
                </Form.Item>
                <Form.Item name={InvoiceInputs.IS_TAX_INCLUDED} style={{ marginBottom: "0" }}>
                    <Segmented
                        options={[
                            {
                                value: true,
                                label: intl.formatMessage({ id: InvoiceFormInputTranslate.MODE_BRUTTO }),
                            },
                            {
                                value: false,
                                label: intl.formatMessage({ id: InvoiceFormInputTranslate.MODE_NETTO }),
                            },
                        ]}
                    />
                </Form.Item>
            </Flex>
            <Form.Item name={InvoiceInputs.LINE_ITEMS}>
                <InvoiceLineItemsTable />
            </Form.Item>
        </InvoiceFormItemWrapper>
    );
};

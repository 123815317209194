import React, { FC, useContext, useEffect, useState, useTransition } from "react";
import { Button, Flex, Typography } from "antd";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../../scripts/routing/routeConstants";
import { InvoicingPaths } from "@inv/types";
import { PlusOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import Search from "antd/es/input/Search";
import { InvoicesDataContext, InvoicesListControlContext } from "@inv/context/InvoicesDataProvider";
import { useDebounce } from "../../../scripts/infrastructure/hooks/useDebounce";

export const InvoicesListHeader: FC = () => {
    const { searchValue } = useContext(InvoicesDataContext);
    const { setSearchValue } = useContext(InvoicesListControlContext);
    const [inputValue, setInputValue] = useState(searchValue ?? "");
    const [isPending, startTransition] = useTransition();

    const debouncedInput = useDebounce(inputValue, 500);

    useEffect(() => {
        startTransition(() => {
            setSearchValue(debouncedInput);
        });
    }, [setSearchValue, debouncedInput]);

    return (
        <Flex align="center" justify="space-between" style={{ width: "100%", marginBottom: "10px" }}>
            <Typography.Title level={3}>
                <FormattedMessage id={"app.invoice.invoicesList"} />
            </Typography.Title>
            <Flex align="center" justify="flex-end" gap={10}>
                <Search
                    style={{ width: "230px" }}
                    onChange={e => setInputValue(e.target.value)}
                    value={inputValue}
                    loading={isPending}
                />
                <Link to={AppRoutes.invoicing + `/${InvoicingPaths.FORM}`}>
                    <Button type={"primary"} icon={<PlusOutlined />}>
                        <FormattedMessage id={"app.invoice.createInvoice"} />
                    </Button>
                </Link>
            </Flex>
        </Flex>
    );
};

import React, { ContextType, useEffect, useMemo, useRef } from "react";
import RecordFormState from "../types/RecordFormState";
import { PaymentsContext } from "scripts/context/PaymentsProvider";
import { ContactsContext } from "scripts/context/ContactsContext";
import { RecordsContext } from "scripts/context/recordsContext/RecordsCtx";

export type SavedItems = { brutto?: number; itemCreditorNum?: string; num?: string; recordCreditorNum?: string };

export const useFormRefsData = (formState: RecordFormState) => {
    const dateTouchedRef = useRef<boolean>(false);
    const savedItemsRef = useRef<SavedItems>({});
    const formStateRef = useRef<RecordFormState>(null);
    const paymentsRecordRelationRef = useRef<ContextType<typeof PaymentsContext>["recordRelation"]>(null);
    const allRecordsRef = useRef<ContextType<typeof RecordsContext>["allRecords"]>(null);
    const contactsRef = useRef<ContextType<typeof ContactsContext>["contacts"]>([]);

    const payments = React.useContext(PaymentsContext);
    const { allRecords } = React.useContext(RecordsContext);
    const { contacts } = React.useContext(ContactsContext);

    useEffect(() => {
        formStateRef.current = formState;
    }, [formState]);
    useEffect(() => {
        paymentsRecordRelationRef.current = payments.recordRelation;
    }, [payments.recordRelation]);
    useEffect(() => {
        allRecordsRef.current = allRecords;
    }, [allRecords]);
    useEffect(() => {
        contactsRef.current = contacts;
    }, [contacts]);

    return useMemo(
        () => ({
            dateTouchedRef,
            savedItemsRef,
            formStateRef,
            paymentsRecordRelationRef,
            allRecordsRef,
            contactsRef,
        }),
        []
    );
};

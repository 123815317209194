import React, { FC, useState } from "react";
import { useInvoiceFormats } from "@inv/hooks/useInvoiceFormats";
import { Flex } from "antd";
import { InvoiceFormatsListHeader } from "@inv/components/InvoiceFormats/InvoiceFormatsListHeader";
import { InvoiceFormatsList } from "@inv/components/InvoiceFormats/InvoiceFormatsList";

export const InvoiceFormatsListPage: FC = () => {
    const [year, setYear] = useState(new Date().getFullYear());
    const { formatsList, refetch } = useInvoiceFormats(year);
    return (
        <Flex
            vertical
            style={{
                height: "100%",
            }}
        >
            <InvoiceFormatsListHeader year={year} setYear={setYear} refetch={refetch} />
            <InvoiceFormatsList formatsList={formatsList} refetch={refetch} />
        </Flex>
    );
};

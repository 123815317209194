import { Button, Card, Col, Flex, Row, Typography } from "antd";
import React, { FC, useContext, useEffect, useState } from "react";
import { CloudUploadOutlined, SettingOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import { BanksApi } from "@banks/scripts/api";
import { DropdownActions } from "@banks/components/DropdownActions";
import { gold } from "@ant-design/colors";
import { cardBackground } from "@banks/components/BankCard/styles";
import { GQL, Utils } from "@binale-tech/shared";
import { AppRoutes } from "../../../scripts/routing/routeConstants";
import { useNavigate } from "react-router-dom";
import { BanksAppControlContext } from "@banks/scripts/context";
import { FormattedMessage } from "react-intl";
import { BankingPaths } from "@banks/types/enums";

const { Text } = Typography;

type TProps = {
    bank: GQL.IBank;
    isActive: boolean;
    onClick: () => void;
};

export const BankCard: FC<TProps> = ({ bank, onClick }) => {
    const [isFavorite, setFavorite] = useState(bank.isFavorite);
    const [isLoadingFavoriteBtn, setIsLoadingFavoriteBtn] = useState(false);
    const [isActionLoading, setIsActionLoading] = useState(false);

    const { openTransactionUploader } = useContext(BanksAppControlContext);

    const navigate = useNavigate();

    useEffect(() => {
        setFavorite(bank.isFavorite);
    }, [bank.isFavorite]);

    const handleUpdateBank = () => {
        openTransactionUploader(bank);
    };

    const handleFavorite = async (e: React.MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
        e.stopPropagation();
        setIsLoadingFavoriteBtn(true);
        setFavorite(prev => !prev);
        await BanksApi.markFavoriteBank({
            ...bank,
        });
        setIsLoadingFavoriteBtn(false);
    };

    const handleGoToBankTransactions = () => {
        onClick();
        return setTimeout(() => {
            navigate(AppRoutes.banks + `/${BankingPaths.TRANSACTIONS}/${bank.id}`);
        }, 300);
    };

    return (
        <Card
            data-parent={"banks"}
            title={<Text ellipsis>{bank.bankTitle ?? bank.bankName}</Text>}
            bordered={false}
            style={{ width: "100%", backgroundColor: cardBackground, overflow: "hidden" }}
            styles={{
                body: {
                    justifyContent: "space-between",
                },
                actions: { backgroundColor: bank?.color ?? cardBackground },
            }}
            actions={[""]}
            extra={
                <Button
                    type={"text"}
                    icon={
                        isFavorite ? (
                            <StarFilled style={{ color: gold[5] }} />
                        ) : (
                            <StarOutlined style={{ color: gold[5] }} />
                        )
                    }
                    onClick={e => handleFavorite(e)}
                    loading={isLoadingFavoriteBtn}
                />
            }
            onClick={handleGoToBankTransactions}
            hoverable
        >
            <Row>
                <Col span={20}>
                    <Flex vertical gap={5}>
                        {bank.IBAN ? (
                            <Flex gap={5}>
                                <Text strong ellipsis>
                                    IBAN:
                                </Text>
                                <Text ellipsis>{bank.IBAN}</Text>
                            </Flex>
                        ) : (
                            <Flex gap={5}>
                                <Text strong ellipsis>
                                    <FormattedMessage id={"app.fields.bankAccountNumber"} />
                                </Text>
                                <Text ellipsis>{bank.accountNumber}</Text>
                            </Flex>
                        )}
                        <Flex gap={5}>
                            <Text strong ellipsis>
                                <FormattedMessage id={"app.fields.bankName"} />:
                            </Text>
                            <Text ellipsis>{bank.bankName}</Text>
                        </Flex>
                        <Text
                            ellipsis
                        >{`${bank.openingBalanceDate ?? "__.__.____"} : ${bank.openingBalance ? Utils.CurrencyUtils.currencyFormat(bank.openingBalance) : "0,00"} ${bank.bankCurrency || "EUR"}`}</Text>
                    </Flex>
                </Col>
                <Col span={4}>
                    <Flex
                        vertical
                        gap={5}
                        justify={"flex-end"}
                        align={"flex-end"}
                        style={{ height: "100%" }}
                        onClick={event => event.stopPropagation()}
                    >
                        <Button icon={<CloudUploadOutlined />} onClick={handleUpdateBank}></Button>
                        <DropdownActions bank={bank} handleActionLoading={isLoading => setIsActionLoading(isLoading)}>
                            <Button icon={<SettingOutlined />} loading={isActionLoading}></Button>
                        </DropdownActions>
                    </Flex>
                </Col>
            </Row>
        </Card>
    );
};
